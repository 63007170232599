import React, { useState } from 'react';

export default function SlidingButtonUI({
    options,
    action = () => {},
    currentTab = '',
}) {
    const handleTabChnage = (option) => {
        action(option.toLowerCase());
    };
    return (
        <div className="tw-flex tw-flex-wrap tw-rounded-lg tw-bg-[#F9FAFB] tw-shadow-sm tw-p-1 tw-w-full tw-text-sm">
            {options.map((option) => (
                <label key={option} className="tw-flex-1 tw-text-center tw-m-0">
                    <input
                        type="radio"
                        name="radioOption"
                        value={option}
                        checked={
                            currentTab?.toLowerCase() === option?.toLowerCase()
                        }
                        onChange={() => handleTabChnage(option)}
                        className="tw-hidden"
                    />
                    <span
                        className={`tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-border-none tw-py-2 tw-text-slate-700 tw-transition-all tw-duration-150 tw-ease-in-out tw-cursor-pointer tw-text-sm
                ${
                    currentTab?.toLowerCase() === option?.toLowerCase()
                        ? 'tw-bg-white tw-text-[#182230] tw-font-semibold tw-shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A] '
                        : 'tw-text-[#667085] '
                }`}
                    >
                        {option}
                    </span>
                </label>
            ))}
        </div>
    );
}
