import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import debounce from 'lodash.debounce';
import sanitizeContent from 'utils/InputSanitization';

const RichTextEditor = (props) => {
    const {
        id,
        tag,
        model,
        className,
        heightMin,
        moreTextButtons,
        moreParagraphButtons,
        onModelChange,
        placeholderText,
        allowOnlyLineBreaks,
        buttonsVisible,
        charCounterMax,
        charCounterCount,
        htmlAllowedTags,
        textType = '',
    } = props;

    const debouncedChangeHandler = useCallback(
        debounce(onModelChange, 300),
        []
    );

    return (
        <FroalaEditor
            id={id}
            tag={tag}
            config={{
                pastePlain: true,
                key: process.env.REACT_APP_FROALA_LICENSE_KEY,
                attribution: false,
                placeholderText,
                htmlRemoveTags: ['script', 'base'],
                heightMin,
                charCounterMax,
                quickInsertTags: [''],
                toolbarButtons: {
                    moreText: {
                        buttons: moreTextButtons,
                        buttonsVisible: buttonsVisible,
                    },
                    moreParagraph: {
                        buttons: moreParagraphButtons,
                        buttonsVisible: buttonsVisible,
                    },
                },
                charCounterCount,
                ...(allowOnlyLineBreaks && {
                    htmlAllowedTags: htmlAllowedTags,
                    shortcutsEnabled: [],
                    htmlAllowedStyleProps: [],
                }),
                linkAutoPrefix: 'https://',
                linkAlwaysBlank: true,
                events: {
                    'paste.beforeCleanup': function (clipboard_html) {
                        return sanitizeContent({
                            str: clipboard_html,
                            type: textType,
                        });
                    },
                },
            }}
            model={model}
            onModelChange={debouncedChangeHandler}
            className={className}
            htmlExecuteScripts={false}
        />
    );
};

RichTextEditor.propTypes = {
    id: PropTypes.string,
    tag: PropTypes.string,
    className: PropTypes.string,
    model: PropTypes.string.isRequired,
    moreTextButtons: PropTypes.array,
    moreParagraphButtons: PropTypes.array,
    onModelChange: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
    heightMin: PropTypes.number,
    allowOnlyLineBreaks: PropTypes.bool,
    buttonsVisible: PropTypes.number,
    charCounterMax: PropTypes.number,
    htmlAllowedTags: PropTypes.array,
    charCounterCount: PropTypes.bool,
    iframe: PropTypes.bool,
};

RichTextEditor.defaultProps = {
    id: 'froala-editor',
    tag: 'textarea',
    placeholderText: 'Edit your content here',
    className: 'rich-text-editor',
    moreTextButtons: [],
    moreParagraphButtons: [],
    heightMin: 300,
    buttonsVisible: 0,
    charCounterCount: false,
    htmlAllowedTags: [],
};

export default RichTextEditor;
