import React, { useEffect, useState } from 'react';
import { Input, Modal } from 'antd';

const CancelScheduleModal = ({
    isVisible,
    onCancel,
    onConfirm,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    heading = 'Heading',
    description = '',
    withInput = false,
    initialInput = '',
    dataID,
    inputFieldLabel = 'Input',
}) => {
    const [notificationName, setNotificationName] = useState(initialInput);

    const handleConfirmWithInput = () => {
        onConfirm(dataID, notificationName);
    };

    useEffect(() => {
        setNotificationName(initialInput);
    }, [initialInput]);

    return (
        <Modal
            visible={isVisible}
            onCancel={onCancel}
            footer={null}
            closable={true}
            width={396}
            centered
            bodyStyle={{ padding: '24px' }}
            className="tw-rounded-2xl tw-shadow-lg tw-overflow-hidden"
            modalRender={(modal) => (
                <div className="tw-rounded-2xl tw-overflow-hidden">{modal}</div>
            )}
        >
            <div className="tw-flex tw-flex-col tw-items-center">
                <span className="tw-text-[18px] tw-font-semibold tw-text-mobileTextBlack tw-mb-1">
                    {heading}
                </span>
                {description && (
                    <p className="tw-text-sm tw-text-mobileTextGray tw-font-normal tw-text-center tw-mb-4">
                        {description}
                    </p>
                )}

                {withInput && (
                    <div className="tw-w-full tw-flex tw-flex-col">
                        <span className="tw-text-sm tw-text-mobileTextGray tw-font-normal tw-mb-1">
                            {inputFieldLabel}
                        </span>
                        <Input
                            value={notificationName}
                            onChange={(e) =>
                                setNotificationName(e.target.value)
                            }
                            className="tw-mb-4 tw-p-2 tw-min-h-[44px] tw-text-base tw-text-[#747578] tw-border tw-rounded"
                            placeholder="Enter notification name"
                        />
                    </div>
                )}
                <button
                    className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer tw-mb-2"
                    onClick={withInput ? handleConfirmWithInput : onConfirm}
                >
                    {confirmButtonText}
                </button>
                <button
                    className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-border-gray-light tw-rounded-md tw-bg-white tw-text-blue-gray  tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                    onClick={onCancel}
                >
                    {cancelButtonText}{' '}
                </button>
            </div>
        </Modal>
    );
};

export default CancelScheduleModal;
