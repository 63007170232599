/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { List, Tabs, Radio, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';

import { GetChannels } from 'apis/rest/channels/GetChannels';

import GlobalLoading from 'components/app/components/common/globalLoading';
import './livestreamChannel.scss';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { SearchOutlined } from '@ant-design/icons';
import NoChannelView from './NoChannelView';
import MobileInput from '../../../../../ComponentsV2/CommonComponents/MobileView/MobileInput/MobileInput';
import RadioGroupButtons from '../../../../../ComponentsV2/CommonComponents/MobileView/radioGroup/RadioGroupButtons';

const options = [
    {
        label: 'Active Channels',
        value: 'active-channels',
    },
    {
        label: 'Expired Channels',
        value: 'expired-channels',
    },
];

const { TabPane } = Tabs;
function LivebarChannel() {
    const history = useHistory();
    const { projectId, companyId } = useParams();

    const [channelsData, setChannelsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [currentTab, setCurrentTab] = useState('active-channels');
    const [tableSearchText, setTableSearchText] = useState('');

    useEffect(() => {
        handleGetChannels(1, tableSearchText);
    }, []);

    const handleGetChannels = async (
        currentPage = 1,
        search = '',
        activeTab = currentTab
    ) => {
        try {
            const status = activeTab === 'active-channels' ? 'live' : 'expired';
            const response = await GetChannels(
                projectId,
                currentPage,
                search,
                status
            );
            if (response.status) {
                setChannelsData(response);
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const renderPagination = (current, type, originalElement) => {
        return (
            <a
                onClick={() => {
                    if (!current) return null;
                    // setCurrentPage(current)
                    handleGetChannels(current, '');
                }}
            >
                {originalElement}
            </a>
        );
    };

    const mobileListView = () => {
        return (
            <div className="tw-w-full tw-flex tw-px-4 tw-flex-col tw-gap-4 md:tw-hidden">
                {channelsData?.channels?.map((item) => (
                    <div
                        key={item.id}
                        className="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-p-4 tw-rounded-md tw-shadow-md tw-border tw-border-solid tw-border-[#EAECF0]"
                        onClick={() =>
                            history.push(
                                `/company/${companyId}/project/${projectId}/channels/${item.id}`
                            )
                        }
                    >
                        {/* title  */}
                        <div className="tw-w-full tw-flex tw-justify-between tw-gap-5">
                            <span className="tw-text-base tw-font-bold">
                                {' '}
                                {item.name}
                            </span>
                            <button className="btn btn-secondary mr-2">
                                <FontAwesomeIcon icon="chevron-right" />
                            </button>
                        </div>

                        {/* description  */}
                        <p
                            className="tw-mb-3 text-dark"
                            style={{
                                fontSize: 14,
                            }}
                        >
                            {item.description}
                        </p>
                        <p className=" tw-text-[13px] font-weight-light channel-expires-text">
                            This channel expire
                            {currentTab === 'active-channels'
                                ? 's'
                                : 'd'} on{' '}
                            <span className="font-weight-bold text-dark">
                                {moment(item.createdAt)
                                    .add(1, 'M')
                                    .format('D MMM YYYY')}
                            </span>
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    const Channels = () => (
        <>
            {isLoading && <GlobalLoading />}

            {!isLoading && channelsData?.channels?.length === 0 && (
                <NoChannelView
                    tab={currentTab}
                    companyId={companyId}
                    projectId={projectId}
                />
            )}

            {!isLoading && channelsData?.channels?.length !== 0 && (
                <>
                    {mobileListView()}
                    <div className="col-12 p-0 !tw-p-0 !md:tw-px-4 tw-hidden md:tw-block">
                        <List
                            className="bg-white pb-3 channels-list-table"
                            itemLayout="horizontal"
                            dataSource={channelsData?.channels}
                            pagination={{
                                itemRender: renderPagination,
                                pageSize: 10,
                                total: channelsData?.pagination?.total,
                                current: channelsData?.pagination?.currentPage,
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() =>
                                        history.push(
                                            `/company/${companyId}/project/${projectId}/channels/${item.id}`
                                        )
                                    }
                                    role="button"
                                    className="p-3 ant-list-item"
                                >
                                    <List.Item.Meta
                                        style={{ border: 'none' }}
                                        title={
                                            <p
                                                className="mb-0 channel-title"
                                                style={{ fontSize: 14 }}
                                            >
                                                {item.name}
                                            </p>
                                        }
                                        description={
                                            <>
                                                <p
                                                    className="mb-2 text-dark"
                                                    style={{
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {item.description}
                                                </p>
                                                <p className="mb-1 font-weight-light channel-expires-text">
                                                    This channel expire
                                                    {currentTab ===
                                                    'active-channels'
                                                        ? 's'
                                                        : 'd'}{' '}
                                                    on{' '}
                                                    <span className="font-weight-bold text-dark">
                                                        {moment(item.createdAt)
                                                            .add(1, 'M')
                                                            .format(
                                                                'D MMM YYYY'
                                                            )}
                                                    </span>
                                                </p>
                                            </>
                                        }
                                    />

                                    <button className="btn btn-secondary mr-2">
                                        <FontAwesomeIcon icon="chevron-right" />
                                    </button>
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            )}
        </>
    );

    const renderMobileView = () => {
        const onChange3 = (e) => {
            setCurrentTab(e.target.value);
            setIsLoading(true);
            setTableSearchText('');
            handleGetChannels(1, '', e.target.value);
        };
        return (
            <div className="md:tw-hidden ">
                {!isLoading && channelsData?.pagination?.totalChannels !== 0 && (
                    <div className="tw-flex tw-flex-col tw-gap-7 tw-w-full tw-mb-3 tw-items-start tw-py-3 tw-px-5">
                        <div className="tw-flex tw-w-full tw-justify-start align-items-center">
                            <button
                                className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/channels/create`
                                    )
                                }
                            >
                                Create live stream channel
                            </button>
                        </div>
                    </div>
                )}
                <div
                    className={`tw-bg-white tw-flex tw-flex-1 ${
                        channelsData?.channels?.length ? '' : 'tw-mt-8'
                    } tw-flex-col tw-gap-4 tw-w-full tw-h-[85vh]`}
                >
                    <div className="tw-w-full tw-p-4 tw-pb-0">
                        {/* Radio  */}
                        <RadioGroupButtons
                            changeHandler={onChange3}
                            options={options}
                            currentValue={currentTab}
                        />
                    </div>
                    {/* {channelsData?.channels?.length !== 0 && ( */}
                    <div className="agenda-search-container tw-px-4 tw-w-full">
                        <MobileInput
                            value={tableSearchText}
                            setValue={setTableSearchText}
                        />
                    </div>
                    {/* )} */}
                    <Channels />
                </div>
            </div>
        );
    };

    return (
        <>
            {renderMobileView()}

            <div className="mb-4 tw-justify-end tw-hidden md:tw-flex align-items-center">
                <button
                    className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer"
                    onClick={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/channels/create`
                        )
                    }
                >
                    Create live stream channel
                </button>
            </div>

            <Tabs
                className="bg-white py-3 border border-radius sponsors-tabs channels-list tw-hidden md:tw-flex"
                defaultActiveKey={currentTab}
                activeKey={currentTab}
                onChange={(activeTab) => {
                    setCurrentTab(activeTab);
                    setIsLoading(true);
                    setTableSearchText('');
                    handleGetChannels(1, '', activeTab);
                }}
                tabBarExtraContent={
                    <div className="form-group has-search mr-3 tw-mb-0">
                        <input
                            value={tableSearchText}
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setTableSearchText(e.target.value)}
                        />
                    </div>
                }
            >
                <TabPane tab="Active Channels" key="active-channels">
                    <Channels />
                </TabPane>
                <TabPane tab="Expired Channels" key="expired-channels">
                    <Channels />
                </TabPane>
            </Tabs>
        </>
    );
}

LivebarChannel.propTypes = {};

export default LivebarChannel;
