/* eslint-disable max-len */
import React from 'react';
import { Radio } from 'antd';

export default function RadioGroupButtons({
    changeHandler = () => {},
    options,
    currentValue,
    buttonFullWidth = false,
}) {
    return (
        <>
            {/* Radio  */}
            <Radio.Group
                className="customeRadioGroup tw-flex tw-w-full tw-overflow-auto"
                options={options}
                onChange={changeHandler}
                value={currentValue}
                optionType="button"
                size="large"
            />
            <style>{`
            .customeRadioGroup .ant-radio-button-wrapper:first-child{
                border-radius: 9px 0 0 9px !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper:last-child{
                border-radius: 0 9px 9px 0 !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper{
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                width: ${buttonFullWidth ? '100%' : 'auto'};
            }

            .customeRadioGroup .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
                border-color: #d9d9d9 !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
                border-color: #d9d9d9 !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                border-color: #d9d9d9 !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
                background: #d9d9d9 !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper{
                color: #344054;
            }
            .customeRadioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
                color: #182230 !important;
            }
            .customeRadioGroup .ant-radio-group{
               overflow: auto !important;
            }
            .customeRadioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
               background: #F9FAFB !important;
            }
            `}</style>{' '}
        </>
    );
}
