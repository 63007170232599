import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Input, notification, DatePicker, TimePicker, List } from 'antd';

import { CreateNewPoll } from 'apis/rest/polls/CreateNewPoll';
import { UpdatePoll } from 'apis/rest/polls/UpdatePoll';
import { UpdatePollStatus } from 'apis/rest/polls/UpdatePollStatus';
import { debounce, isObject } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

const ScheduleModalMobile = ({
    visible,
    onCancel,
    onConfirm,
    setVisible,
    notificationSendNow,
    notificatonData,
    onRefreshData,
    notificationEditMode,
    sendDirect = false,
    scheduleDate,
    setScheduleDate,
    sendMode,
    selectedTrigger,
    triggerTime,
    timeUnit,
}) => {
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const { projectId, companyId } = useParams();
    const history = useHistory();

    const handleCreateNotification = async () => {
        const {
            question,
            options,
            showResultAfterVoting,
            position,
            targetType,
            target,
            ctaText,
            ctaColor,
            ctaTextColor,
        } = notificatonData;

        const inputs = {
            ...(targetType === 'project' && {
                targetType: 'project',
            }),
            ...(targetType === 'livepage' && {
                targetType: 'livepage',
                target: target.map((value) => value.key),
            }),
            question,
            options,
            position,
            ctaText,
            ctaColor,
            ctaTextColor,
            showResultAfterVoting,
            projectId,
        };
        try {
            const response = await CreateNewPoll(inputs);
            if (response.status) {
                return response.id;
            }
            throw new Error(response.message);
        } catch (error) {
            openNotification('error', {
                message: error?.message || 'Something went wrong',
            });
        }
    };

    const handleSendNotification = debounce(async (notificationStatus) => {
        if (notificationStatus === 'sendNow') {
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();

                const response = await UpdatePollStatus(
                    newNotificationId,
                    'publish',
                    projectId
                );
                if (response.status) {
                    openNotification('success', { message: 'Poll published!' });
                    setVisible(false);
                    onRefreshData();
                }
                if (!response.status) {
                    openNotification('error', { message: response.message });
                }
            }
            //Publish exist notification
            if (notificationEditMode) {
                const {
                    question,
                    options,
                    showResultAfterVoting,
                    position,
                    targetType,
                    target,
                    ctaText,
                    ctaColor,
                    ctaTextColor,
                } = notificatonData;

                const inputs = {
                    ...(targetType === 'project' && {
                        targetType: 'project',
                    }),
                    ...(targetType === 'livepage' && {
                        targetType: 'livepage',
                        target: target.map((value) => value.key),
                    }),
                    question,
                    options,
                    position,
                    ctaText,
                    ctaColor,
                    ctaTextColor,
                    showResultAfterVoting,
                    projectId,
                };

                if (sendDirect) {
                    const response = await UpdatePollStatus(
                        notificatonData.id,
                        'publish',
                        projectId
                    );

                    if (response.status) {
                        openNotification('success', {
                            message: 'Poll published!',
                        });
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification('success', {
                            message: response.message,
                        });
                    }
                }

                if (!sendDirect) {
                    const response = await UpdatePoll(
                        notificatonData.id,
                        inputs,
                        projectId
                    );

                    if (response.status) {
                        const publishResponse = await UpdatePollStatus(
                            notificatonData.id,
                            'publish',
                            projectId
                        );

                        if (publishResponse.status) {
                            openNotification('success', {
                                message: 'Poll published!',
                            });
                            setVisible(false);
                            onRefreshData();
                        }
                    }
                    if (!response.status) {
                        openNotification('error', {
                            message: response.message,
                        });
                    }
                }
            }
        } else if (notificationStatus === 'schedule') {
            const {
                question,
                options,
                showResultAfterVoting,
                position,
                targetType,
                target,
                ctaText,
                ctaColor,
                ctaTextColor,
            } = notificatonData;

            const notificationSchedule = scheduleDate.utc().format();

            if (
                moment()
                    .add(9, 'minutes')
                    .diff(moment(notificationSchedule), 'second') > 0
            ) {
                const userResponse = window.confirm(
                    'Scheduled Time should be atleast 10 minutes from now.Click on "OK" to reset the time.'
                );
                if (userResponse)
                    setScheduleDate(moment().tz(timezone).add(10, 'minutes'));
                return;
            }

            const inputs = {
                ...(targetType === 'project' && {
                    targetType: 'project',
                }),
                ...(targetType === 'livepage' && {
                    targetType: 'livepage',
                    target: target.map((value) => value?.key || value?.id),
                }),
                question,
                options: options.map((value) =>
                    isObject(value) ? value.label : value
                ),
                position,
                ctaText,
                ctaColor,
                ctaTextColor,
                showResultAfterVoting,
                projectId,
                scheduledTime: notificationSchedule,
            };
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();

                const response = await UpdatePoll(
                    newNotificationId,
                    inputs,
                    projectId
                );

                if (response.status) {
                    const publishResponse = await UpdatePollStatus(
                        newNotificationId,
                        'publish',
                        projectId
                    );
                    if (publishResponse.status) {
                        openNotification('success', {
                            message: 'Poll scheduled!',
                        });
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification('error', {
                            message: response.message,
                        });
                    }
                } else if (newNotificationId) {
                    openNotification('error', {
                        message:
                            response?.message ||
                            'Notification created, but an error was encountered while scheduled.',
                    });
                    onRefreshData();
                } else {
                    openNotification('error', {
                        message: response.message,
                    });
                }
            }
            //Schedule exist notification
            if (notificationEditMode) {
                const response = await UpdatePoll(
                    notificatonData.id,
                    inputs,
                    projectId
                );

                if (response.status) {
                    const publishResponse = await UpdatePollStatus(
                        notificatonData.id,
                        'publish',
                        projectId
                    );

                    if (publishResponse.status) {
                        openNotification('success', {
                            message: 'Poll scheduled!',
                        });
                        setVisible(false);
                        onRefreshData();
                    }
                }
                if (!response.status) {
                    openNotification('error', { message: response.message });
                }
            }
        }
    }, 500);
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            footer={null}
            closable
            width={396}
            centered
            bodyStyle={{ padding: '24px' }}
            className="tw-rounded-2xl tw-shadow-lg tw-overflow-hidden"
            modalRender={(modal) => (
                <div className="tw-rounded-2xl tw-overflow-hidden">{modal}</div>
            )}
        >
            <div className="tw-flex tw-flex-col">
                <div className="tw-bg-white tw-rounded-lg">
                    <div className="tw-flex tw-flex-col tw-items-center">
                        <span className="tw-text-[18px] tw-font-semibold tw-text-mobileTextBlack tw-mb-1">
                            Review your settings
                        </span>
                        <p className="tw-text-sm tw-text-mobileTextGray tw-font-normal tw-text-center tw-mb-4">
                            The notification will be sent based on your
                            settings.
                        </p>
                    </div>

                    <p className="tw-text-sm tw-text-mobileTextGray tw-mb-2 ">
                        It will be sent to the following{' '}
                        <span className="tw-font-semibold">
                            {
                                notificatonData?.target?.filter(function (e) {
                                    return e;
                                }).length
                            }{' '}
                            livepage(s):
                        </span>
                    </p>
                    <List
                        className="live-pages-list"
                        size="small"
                        bordered
                        dataSource={notificatonData?.target?.filter(function (
                            e
                        ) {
                            return e;
                        })}
                        renderItem={(item) => (
                            <List.Item key={item?.id}>
                                {item?.value || item?.title}
                            </List.Item>
                        )}
                    />

                    <p className="tw-font-normal tw-text-sm tw-mb-2 tw-mt-4">
                        When
                    </p>
                    {sendMode !== 'sendNow' ? (
                        <>
                            <p className="tw-text-sm tw-mb-1 tw-textfont-semibold">
                                Date
                            </p>
                            <DatePicker
                                style={{
                                    width: '100%',
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    height: '44px',
                                    borderRadius: '4px',
                                }}
                                className="tw-mb-3"
                                disabledDate={(current) =>
                                    current <
                                    moment().tz(timezone).startOf('day')
                                }
                                allowClear={false}
                                value={scheduleDate}
                                onChange={setScheduleDate}
                                showToday={false}
                                format="D MMM YYYY"
                            />

                            <p className="tw-text-sm tw-mb-1 tw-textfont-semibold">
                                Time
                            </p>

                            <TimePicker
                                allowClear={false}
                                style={{
                                    width: '100%',
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    height: '44px',
                                    borderRadius: '4px',
                                }}
                                className="tw-mb-3"
                                disabledDate={(current) =>
                                    current <
                                    moment().tz(timezone).add(9, 'minutes')
                                }
                                value={scheduleDate}
                                onChange={setScheduleDate}
                                format="hh:mm a"
                                showNow={false}
                            />

                            <p className="tw-text-sm tw-mb-1 tw-textfont-semibold">
                                Time Zone
                            </p>
                            <TimezoneSelector
                                selectStyle={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#101828',
                                    fontFamily: 'Open Sans',
                                    width: '100%',
                                }}
                                selectClassnames="selectMobileView"
                                wrapperClass="wrapperMobileView"
                            />
                        </>
                    ) : (
                        <Input
                            value="now"
                            readOnly
                            className=" tw-p-2 tw-min-h-[44px] tw-text-base tw-text-[#747578] tw-border tw-rounded"
                        />
                    )}

                    <button
                        className=" tw-mt-4 tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer tw-mb-2"
                        onClick={() => handleSendNotification(sendMode)}
                    >
                        Send now
                    </button>
                    <button
                        className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-border-gray-light tw-rounded-md tw-bg-white tw-text-blue-gray  tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                        onClick={() => setVisible(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ScheduleModalMobile;
