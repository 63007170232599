import { legacy_createStore as createStore, combineReducers } from 'redux';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

/** Common */
import user from './reducers/common/userReducers';
import ui from './reducers/uiReducers';

/** Manager */
import dashboardManager from './reducers/manager/dashboardReducers';
import alert from './reducers/manager/alertReducers';
import loading from './reducers/manager/loadingReducers';

/** XP Live */
import menus from './reducers/live/menuReducers';
import header from './reducers/live/topNavReducers';
import layout from './reducers/live/layoutReducers';
import settings from './reducers/live/settingsReducers';

/** XP People */
import people from './reducers/people/peopleReducers';

/** XP LivePages */
import pages from './reducers/pages/pagesReducers';
import deployments from './reducers/deployments/deploymentsReducers';
import projects from './reducers/projects/projectReducers';

import venues from './reducers/venue/venueReducers';
import venuesettings from './reducers/venuesettings/venuesettingsReducer';
import venueMenus from './reducers/venue/menuReducer';

import venueAnalytics from './reducers/analytics/analyticsReducer';
import meetings from './reducers/meetings/meetingsReducer';
import sponsorMenu from './reducers/live/sponsorMenuReducer';
import badges from './reducers/badges/badgeReducers';

import onsiteReports from '../ComponentsV2/ProjectDashboard/Components/OnSite/Analytics/Reports/reportsSlice';

const app = combineReducers({
    user,
    ui,

    dashboardManager,
    alert,
    loading,

    menus,
    header,
    layout,
    settings,

    people,
    pages,
    deployments,
    projects,
    venues,
    venuesettings,
    venueMenus,
    venueAnalytics,
    meetings,
    sponsorMenu,
    badges,
    onsiteReports,
});

// This is used if we want to update redux store in production
// But this will be changed depending on what was changed in the reducers
const migrations = {
    1: (state) => {
        return {
            ...state,
            menus: {
                ...state.menus,
                mainMenuData: {
                    mainLinks: ['People', 'Live', 'Analytics', 'Sponsorship'],
                    allPeopleSub: ['Fields', 'Data Integration'],
                    allLiveSub: ['LivePages', 'LiveSite'],
                    allSponsorshipSub: ['Packages', 'Sponsors'],
                },
            },
        };
    },
};

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    stateReconciler: autoMergeLevel2,
    blacklist: ['alert', 'menus', 'venueMenus'],
    migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, app);

let store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
let persistor = persistStore(store);

export { store, persistor };
