import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import MobileInput from '../MobileInput/MobileInput';

export default function ListingCards({
    companyList = [],
    gotToDashboard = () => {},
    paginationData,
    searchValue = '',
    handleSearch = () => {},
}) {
    const { currentPage, total, pageSize, onChange } = paginationData || {};

    return (
        <div className="tw-flex tw-flex-col tw-gap-2 !tw-text-mobileTextBlack md:tw-hidden tw-items-start  tw-px-3 tw-py-7">
            {/* Search box  */}
            <div className="tw-w-full tw-flex">
                <MobileInput
                    value={searchValue}
                    setValue={handleSearch}
                    placeholder="Search By Company/Project Name"
                />
            </div>
            {/* Projectlistsing */}
            {companyList && companyList?.length ? (
                <>
                    <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
                        <span className="tw-text-base tw-font-semibold">
                            Choose a project
                        </span>
                    </div>
                    {/* cards */}
                    {companyList?.map((company) => (
                        <div
                            key={company.key}
                            className="tw-w-full tw-mb-4 tw-bg-white tw-text-mobileTextBlack tw-rounded-lg tw-shadow-md tw-flex tw-flex-col tw-pt-4 tw-pb-3 tw-gap-3 "
                        >
                            <div className="tw-flex tw-flex-col tw-item-start tw-gap-1 tw-px-3 ">
                                <span className="tw-text-gray-800 tw-font-sans tw-text-[22px] tw-font-semibold ">
                                    {company.companyName}
                                </span>
                                <span className="tw-text-sm tw-font-normal tw-text-gray-600">
                                    {company.projectName}
                                </span>
                            </div>
                            <hr className="tw-w-full tw-bg-divider-gray tw-m-0" />
                            <div className="tw-w-full tw-flex tw-justify-end tw-px-3">
                                <button
                                    className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer tw-min-h-[42px] tw-w-full"
                                    onClick={() => {
                                        gotToDashboard(
                                            company.key,
                                            company.project
                                        );
                                    }}
                                    style={{ fontSize: '16px' }}
                                >
                                    Go to Dashboard
                                </button>
                            </div>
                        </div>
                    ))}
                    {/* pagination  */}
                    <div className="tw-flex tw-justify-center tw-w-full">
                        <Pagination
                            position={['none', 'bottomCenter']}
                            showSizeChanger={false}
                            current={Number(currentPage)}
                            total={total}
                            onChange={onChange}
                            pageSize={pageSize}
                        />
                    </div>
                </>
            ) : (
                <div className="no-record tw-flex tw-justify-center tw-items-center tw-w-full tw-min-h-[350px]">
                    <h2 className="tw-text-blue-gray-light">
                        No result for "{searchValue}"
                    </h2>
                </div>
            )}
        </div>
    );
}
