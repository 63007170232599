import React from 'react';

export default function CloneIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_21337_177806)">
                <path
                    d="M12.6562 5.34375H2.8125V15.1875H12.6562V5.34375Z"
                    stroke="black"
                    stroke-width="1.6875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.34375 2.8125H15.1875V12.6562"
                    stroke="black"
                    stroke-width="1.6875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_21337_177806">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
