// import { produce } from 'immer';
// import moment from 'moment';

// export const initialState = {
//     settings: {}
// }

function venueSettingsReducers(state = {}, action) {
    switch (action.type) {
        case 'SAVE_VENUE_SETTINGS':
            return {
                ...state,
                ...action.payload,
            };
        case 'SET_VENUE_ID':
            return {
                ...state,
                venueId: action.payload,
            };
        case 'SET_COMPANY_VENUE':
            return {
                ...state,
                venueDetails: action.payload,
            };
        default:
            return state;
    }
}

export default venueSettingsReducers;
