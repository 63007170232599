import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import GeneralPreloader from 'components/app/common/preloader/generalPreloader';
import './userList.scss';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tag, Pagination } from 'antd';
import { updateActivation } from 'apis/rest/LeadsUsers';
import RadioGroupButtons from 'ComponentsV2/CommonComponents/MobileView/radioGroup/RadioGroupButtons';
import isEmpty from 'lodash/isEmpty';

const selectOptions = [
    {
        label: 'All users',
        value: 'all',
    },
    {
        label: 'Active users',
        value: 'activated',
    },
    {
        label: 'Deactivated users',
        value: 'deactivated',
    },
];
function UserList({
    showAlert,
    users,
    handleGetLeadsUsers,
    isBusy,
    projectId,
    disableActivate,
    pagination = {},
}) {
    const [filter, setFilter] = useState('');
    const [tabOptions, setTabOptions] = useState(selectOptions);

    const handlePageChange = (page) => {
        const type =
            filter === 'activated'
                ? 'active'
                : filter === 'deactivated'
                ? 'suspended'
                : 'all';
        handleGetLeadsUsers({ page, type });
    };

    const userMenu = ({ isActive, id }) => (
        <Menu>
            <Menu.Item
                onClick={() =>
                    handleActivation({
                        id,
                        status: isActive ? 'suspend' : 'activate',
                    })
                }
                disabled={!isActive && disableActivate}
            >
                {isActive ? 'Deactivate' : 'Activate'}
            </Menu.Item>
        </Menu>
    );
    /**
     *
     * @param {status} activate/suspend
     * @param {id} user id
     */
    const handleActivation = async ({ status, id }) => {
        try {
            const resp = await updateActivation({ projectId, id, status });
            if (resp?.status) {
                showAlert(resp?.message, 'success');
                handleGetLeadsUsers({});
            } else showAlert(resp?.message, 'error');
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * renders the table rows
     * @param {object} user user object to be rendered per row
     * @param {number} index user row index
     */
    const renderRows = (user, index) => {
        return (
            <Fragment key={index}>
                <tr key={user.id}>
                    <td>
                        {user.firstname || '-'} {user.lastname}
                    </td>
                    <td>{user.email || '-'}</td>
                    <td>{user.phoneNo || '-'}</td>
                    <td
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <p>
                            {user.isSuspended ? (
                                <Tag
                                    icon={
                                        <div
                                            className="tw-w-[6px] tw-h-[6px] tw-rounded-full"
                                            style={{ background: '#344054' }}
                                        />
                                    }
                                    className="tw-flex tw-items-center tw-w-fit tw-gap-2 tw-px-2 tw-rounded-full"
                                >
                                    <span
                                        className="tw-font-bold"
                                        style={{
                                            color: ' #344054',
                                        }}
                                    >
                                        Deactivated
                                    </span>
                                </Tag>
                            ) : (
                                <Tag
                                    icon={
                                        <div
                                            className="tw-w-[6px] tw-h-[6px] tw-rounded-full"
                                            style={{ background: '#17B26A' }}
                                        />
                                    }
                                    className="tw-flex tw-items-center tw-w-fit !tw-border-#ABEFC6 tw-gap-2 tw-px-2 tw-rounded-full"
                                    color="success"
                                    style={{ background: '#ECFDF3' }}
                                >
                                    <span
                                        className="!tw-text-#067647 tw-font-bold"
                                        style={{
                                            color: ' #067647',
                                        }}
                                    >
                                        Activated
                                    </span>
                                </Tag>
                            )}
                            <br />
                            <span className="text-subtle text-small">
                                Invited by {user.invitationType} on{' '}
                                {moment(user?.invitedAt).format('DD MMM YYYY')}
                            </span>
                        </p>
                        <span className="dotContainer">
                            <Dropdown
                                overlay={userMenu({
                                    isActive: !user.isSuspended,
                                    id: user.id,
                                })}
                            >
                                <EllipsisOutlined
                                    style={{ fontSize: '20px' }}
                                />
                            </Dropdown>
                        </span>
                    </td>
                </tr>
            </Fragment>
        );
    };
    /**
     * filters `users` array based from status
     */
    const filteredElements = () => {
        if (filter !== '') {
            return users
                .filter((user) => {
                    switch (filter) {
                        case 'activated':
                            return !!user.isSuspended === false;
                        case 'deactivated':
                            return !!user.isSuspended === true;
                        default:
                            break;
                    }
                })
                .map((filteredUser, index) => renderRows(filteredUser, index));
        }
        return users.map((filteredUser, index) =>
            renderRows(filteredUser, index)
        );
    };

    /**
     * counts the number of users depending on filter
     * @param {string} filter type of filter to use for counting
     */
    const countFilter = (filter) => {
        if (filter !== '') {
            switch (filter) {
                case 'activated':
                    return users.filter((user) => !!user.isSuspended === false)
                        .length;
                case 'deactivated':
                    return users.filter((user) => !!user.isSuspended === true)
                        .length;
                default:
                    break;
            }
        } else {
            return users.length;
        }
    };

    const radioButtonChangeHandler = (value) => {
        switch (value) {
            case 'all':
                setFilter('');
                break;
            case 'activated':
                setFilter('activated');
                break;
            case 'deactivated':
                setFilter('deactivated');
                break;
            default:
                break;
        }
        const type =
            value === 'activated'
                ? 'active'
                : value === 'deactivated'
                ? 'suspended'
                : 'all';
        handleGetLeadsUsers({ type });
    };

    useEffect(() => {
        if (!users) return;
        const temp = [
            {
                label: <span className="tw-w-max tw-flex">All users</span>,
                value: 'all',
            },
            {
                label: <span className="tw-w-max tw-flex">Activated</span>,
                value: 'activated',
            },
            {
                label: <span className="tw-w-max tw-flex">Deactivated</span>,
                value: 'deactivated',
            },
        ];
        setTabOptions(temp);
    }, [users]);

    return (
        <>
            <div className="tw-w-full tw-mb-5 tw-p-7 tw-px-5 tw-pb-0 tw-block md:tw-hidden ">
                <RadioGroupButtons
                    changeHandler={(e) =>
                        radioButtonChangeHandler(e.target.value)
                    }
                    options={tabOptions || selectOptions}
                    currentValue={filter || 'all'}
                    buttonFullWidth
                />
            </div>
            {isBusy && <p className="tw-p-4 md:tw-hidden">Loading...</p>}
            <div
                className={`user-list ${
                    users?.length ? 'tw-h-auto' : 'tw-h-full'
                } tw-flex sm:tw-h-auto sm:tw-block tw-overflow-auto tw-flex-col`}
            >
                <ul className="user-list__filters tw-hidden md:tw-flex">
                    <li
                        className={
                            filter === '' ? 'user-list__filter--active' : ''
                        }
                    >
                        <button
                            className="border-0 tw-shadow-none tw-bg-transparent tw-text-black tw-cursor-pointer"
                            onClick={() => radioButtonChangeHandler('all')}
                        >
                            All Users
                        </button>
                    </li>{' '}
                    <li
                        className={
                            filter === 'activated'
                                ? 'user-list__filter--active'
                                : ''
                        }
                    >
                        <button
                            className="border-0 tw-shadow-none tw-bg-transparent tw-text-black tw-cursor-pointer"
                            onClick={() =>
                                radioButtonChangeHandler('activated')
                            }
                        >
                            Activated Users
                        </button>
                    </li>
                    <li
                        className={
                            filter === 'deactivated'
                                ? 'user-list__filter--active'
                                : ''
                        }
                    >
                        <button
                            className="border-0 tw-shadow-none tw-bg-transparent tw-text-black tw-cursor-pointer"
                            onClick={() =>
                                radioButtonChangeHandler('deactivated')
                            }
                        >
                            Deactivated
                        </button>
                    </li>
                </ul>
                {isBusy && <GeneralPreloader />}
                {!isBusy && users.length > 0 && (
                    <>
                        <div className="user-list__table-wrap tw-p-4">
                            <table className="user-list__table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone No.</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>

                                <tbody>{filteredElements()}</tbody>
                            </table>
                        </div>
                    </>
                )}

                {users.length !== 0 && !isBusy && !isEmpty(pagination) && (
                    <div className="tw-flex p-4 tw-justify-center tw-items-center">
                        <div className="tw-flex md:tw-hidden">
                            <Pagination
                                current={pagination?.currentPage}
                                total={pagination?.total}
                                onChange={handlePageChange}
                                simple
                            />
                        </div>
                        <div className="tw-hidden md:tw-flex">
                            <Pagination
                                current={pagination?.currentPage}
                                total={pagination?.total}
                                onChange={handlePageChange}
                                showTotal={(total, range) =>
                                    `${range[0]}-${range[1]} of ${total} items`
                                }
                            />
                        </div>
                    </div>
                )}

                {!isBusy && users.length === 0 && (
                    <>
                        <div className="panel-empty tw-hidden md:tw-block">
                            <h2>No invited or active users</h2>

                            <p>Get started by inviting your users.</p>
                        </div>
                        <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-hidden tw-py-8 ">
                            <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
                                <span className="tw-text-[22px] tw-font-semibold tw-text-mobileTextBlack">
                                    No invited or active users
                                </span>
                                <p className="tw-text-sm tw-font-normal tw-text-mobileTextGray md:tw-text-black">
                                    Get started by inviting your users.
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

UserList.propTypes = {
    showAlert: PropTypes.func.isRequired,
    handleGetLeadsUsers: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    isBusy: PropTypes.bool.isRequired,
    disableActivate: PropTypes.bool.isRequired,
};

export default withRouter(UserList);
