import * as ls from 'local-storage';
import { isString, isArray } from 'lodash';
import { store } from 'redux/store';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * gets sessions dates
 */
export async function getAgendaSessionDates(
    projectId,
    showcaseId,
    search = '',
    limit = 10,
    page = 1
) {
    const timezone = store.getState()?.settings?.activeTimezone;
    setAuthBearer();
    const endpoint = `${
        process.env.REACT_APP_MYXP_API
    }/manager/livestream/sessions?projectId=${projectId}&limit=${limit}&page=${page}&dateView=1${
        showcaseId ? `&showcaseId=${showcaseId}` : ''
    }&timezone=${timezone}${search ? `&search=${search}` : ''}`;
    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response = await fetch(endpoint, {
            method: 'GET',
            headers,
        });

        return response.json();
    }
}

/**
 * gets sessions
 */
export async function getAgendaSessions(
    projectId,
    showcaseId,
    date,
    limit = 10,
    page = 1,
    search = null
) {
    setAuthBearer();
    const timezone = store.getState()?.settings?.activeTimezone;
    const endpoint = `${
        process.env.REACT_APP_MYXP_API
    }/manager/livestream/sessions?projectId=${projectId}&limit=${limit}&page=${page}${
        !search ? `&dateView=0` : ''
    }${showcaseId ? `&showcaseId=${showcaseId}` : ''}${
         date ? `&date=${date}` : ''
    }${search ? `&search=${search}` : ''}&timezone=${timezone}`;
    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response = await fetch(endpoint, {
            method: 'GET',
            headers,
        });

        return await response.json();
    }
}

async function generateBlobFromImageURL(imageUrl) {
    if (!imageUrl.startsWith('blob:')) {
        return null;
    }
    try {
        const response = await fetch(imageUrl);
        return response.blob();
    } catch (error) {
        console.error(error);
        return null;
    }
}

async function generateFileObjects(files) {
    const generatedFiles = [];
    for (const file of files) {
        if (file instanceof File) {
            generatedFiles.push(file);
        } else if (
            typeof file === 'object' &&
            !file?.pintura &&
            file?.preview?.startsWith('blob:')
        ) {
            try {
                const blob = await generateBlobFromImageURL(file.preview);
                const generatedFile = new File([blob], file.name, {
                    type: '' + blob.type,
                });
                generatedFiles.push(generatedFile);
            } catch (e) {
                console.error(e);
            }
        }
    }
    return generatedFiles;
}

/**
 * create sessions
 */
export async function createAgendaSessions(inputs) {
    setAuthBearer();
    const user = store?.getState()?.user;

    if (authBearer && user) {
        let formData = new FormData();
        for (const property in inputs) {
            if (property === 'logos') {
                if (isArray(inputs[property])) {
                    const generatedFiles = await generateFileObjects(
                        inputs[property]
                    );
                    generatedFiles.forEach((file) => {
                        formData.append('logos', file);
                    });
                }
            } else if (property === 'previousLogos') {
                if (isArray(inputs[property])) {
                    let listPreviousLogos = [];
                    inputs[property].map((fileUrl) => {
                        if (isString(fileUrl) && fileUrl !== 'null') {
                            listPreviousLogos.push(fileUrl);
                        }
                    });
                    formData.append(
                        'previousLogos',
                        JSON.stringify(listPreviousLogos)
                    );
                }
            } else {
                formData.append(property, inputs[property]);
            }
        }

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: formData,
            }
        );
        return await response.json();
    }
}

/**
 * update sessions
 */
export async function updateAgendaSession(id, inputs) {
    setAuthBearer();
    const user = store?.getState()?.user;

    if (authBearer && user) {
        let formData = new FormData();
        for (const property in inputs) {
            if (property === 'logos') {
                if (isArray(inputs[property])) {
                    const generatedFiles = await generateFileObjects(
                        inputs[property]
                    );
                    generatedFiles.forEach((file) => {
                        formData.append('logos', file);
                    });
                }
            } else {
                formData.append(property, inputs[property]);
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions/${id}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: formData,
            }
        );
        return await response.json();
    }
}

/**
 * Delete session
 */
export async function deleteSession(data) {
    setAuthBearer();
    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions/${data.id}?projectId=${data.projectId}`,
            {
                method: 'DELETE',
                headers,
                body: JSON.stringify({ projectId: data.projectId }),
            }
        );

        return await response.json();
    }
}

/**
 * duplicate session
 */
export async function duplicateSession(data) {
    setAuthBearer();
    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions/duplicate`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    projectId: data.projectId,
                    sessionId: data.id,
                }),
            }
        );

        return await response.json();
    }
}

/**
 * get speakers
 */
export async function getAgendaSpeakers(projectId) {
    setAuthBearer();

    let endpoint = `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers?projectId=${projectId}&limit=0`;

    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response = await fetch(endpoint, {
            method: 'GET',
            headers,
        });

        return await response.json();
    }
}

/**
 * get tags
 */
export async function getAgendaSessionTags(
    projectId,
    search = '',
    group = false,
    page = 1
) {
    setAuthBearer();
    let endpoint = `${process.env.REACT_APP_MYXP_API}/manager/livestream/topics?projectId=${projectId}&limit=0&search=${search}&page=${page}`;

    if (group) {
        endpoint = `${process.env.REACT_APP_MYXP_API}/manager/livestream/topics?projectId=${projectId}&limit=0&search=${search}&group=0&page=${page}`;
    }

    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response = await fetch(endpoint, {
            method: 'GET',
            headers,
        });

        return await response.json();
    }
}

export async function bulkDeleteSession(data) {
    setAuthBearer();
    let headers = '';
    if (authBearer) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const response  = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions/bulk-delete`,
            {
                method: 'DELETE',
                headers,
                body: JSON.stringify({ projectId: data.projectId, sessionIds: data.sessionIds }),
            }
        );

        return await response.json();
    }
}