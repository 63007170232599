import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import './sideModal.scss';

const SideModal = ({
    visibility,
    setVisibility,
    title,
    subTitle,
    size,
    children,
}) => {
    return (
        <Modal
            dialogClassName="myxp-edit-prompt myxp-edit-prompt-modal"
            show={visibility}
            size={size}
            centered
            onHide={() => setVisibility(false)}
        >
            <div className="p-4">
                <Modal.Header className="border-bottom-0">
                    <div className="d-flex justify-content-between w-100 side-modal-header">
                        <div className="tw-w-full tw-flex tw-justify-center sm:tw-inline">
                            {subTitle && (
                                <p className="text-secondary sm:tw-mb-1 tw-mb-0">
                                    {subTitle}
                                </p>
                            )}
                            {title && <h2>{title}</h2>}
                        </div>
                        <div
                            className="close-button"
                            onClick={() => setVisibility(false)}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
            </div>
        </Modal>
    );
};

SideModal.propTypes = {
    visibility: PropTypes.bool,
    setVisibility: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    size: PropTypes.string,
};

SideModal.defaultProps = {
    visibility: false,
    title: null,
    subTitle: null,
    size: 'sm',
};

export default SideModal;
