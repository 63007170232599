import React, { useState } from 'react';
import ls from 'local-storage';
import { Tooltip } from 'antd';

import { TwoFASecurityModal } from 'ComponentsV2/SignIn/2FA';
import { getFullname, getNameInitials } from '../../../utils/functions/common';

import Prompt from '../../prompt';
import {
    UserProfileEditIcon,
    LogoutIcon,
    OfficeIcon,
    SecurityIcon,
} from './icons';
import './UserProfile.scss';
import { LogoutIconMobile } from './icons/LogoutIcon';

export default function UserProfile({
    user,
    setShowUserProfileEdit,
    handleLogout,
}) {
    const [showUserProfileSecurity, setShowUserProfileSecurity] =
        useState(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const isSponsorDashboard = !!ls.get('sponsorAuth');
    const fullName = getFullname(user);
    const maxNameLength = 30;

    return (
        <>
            <div className="tw-font-montserrat tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-72 tw-h-auto tw-p-4 tw-pt-6 tw-pb-2 tw-bg-white tw-rounded-md tw-cursor-default">
                <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[40px] tw-h-[40px] tw-rounded-full tw-bg-[#E1E8F0] tw-cursor-default">
                    <span className="tw-m-0 tw-p-0 sm:tw-text-2xl sm:tw-font-semibold tw-text-[#020202] tw-text-sm tw-font-semibold tw-cursor-default tw-uppercase">
                        {getNameInitials(user)}
                    </span>
                </div>
                <div className="tooltip-fullname tw-m-0 tw-p-0 sm:tw-text-sm tw-text-[18px] tw-mt-2 tw-text-mobileTextBlack tw-font-semibold sm:tw-text-black tw-break-words tw-text-center">
                    {fullName?.length > maxNameLength ? (
                        <Tooltip
                            placement="top"
                            title={fullName}
                            zIndex={9999}
                            getPopupContainer={(triggerNode) => triggerNode}
                        >
                            <span className="tw-p-0 tw-m-0 tw-break-words">
                                {`${fullName.slice(0, maxNameLength - 1)} ...`}
                            </span>
                        </Tooltip>
                    ) : (
                        fullName
                    )}
                </div>
                <div className="tw-m-0 tw-p-0 sm:tw-text-xs tw-text-sm tw-mt-1 tw-text-mobileTextGray sm:tw-font-extralight tw-font-normal tw-mb-5">
                    {user?.email}
                </div>
                {user?.company?.name && (
                    <div className="tw-m-0 tw-p-0 tw-flex tw-flex-row tw-text-sm tw-mt-3 tw-font-medium">
                        <span className="tw-m-0 tw-p-0 tw-cursor-default">
                            <OfficeIcon />
                        </span>
                        <span className="tw-m-0 tw-p-0 tw-ml-1 tw-cursor-default">
                            {user?.company?.name}
                        </span>
                    </div>
                )}
                {!isSponsorDashboard && (
                    <div
                        className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-border-solid tw-border-0 tw-border-t tw-border-slate-200 tw-mt-6 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                        onClick={() => setShowUserProfileEdit(true)}
                    >
                        <span className="tw-m-0 tw-p-0 tw-pl-4 tw-pr-2">
                            <UserProfileEditIcon />
                        </span>
                        <span className="tw-m-0 tw-p-0 tw-text-sm tw-font-light">
                            Edit your profile
                        </span>
                    </div>
                )}

                {user?.enable2FA ? (
                    <div
                        className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-mt-0 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                        onClick={() => setShowUserProfileSecurity(true)}
                    >
                        <span className="tw-m-0 tw-p-0 tw-pl-4 tw-pr-2">
                            <SecurityIcon />
                        </span>
                        <span className="tw-m-0 tw-p-0 tw-text-sm tw-font-light">
                            Security
                        </span>
                    </div>
                ) : null}

                <div
                    className="tw-m-0 tw-p-0 tw-w-full tw-mt-4 hover:tw-bg-gray-100 tw-mt-0 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                    onClick={() => setShowLogoutConfirm(true)}
                >
                    <span className="tw-m-0 tw-p-0 sm:tw-pl-4 tw-pl-0 tw-pr-2">
                        <LogoutIconMobile />
                    </span>
                    <span className="tw-m-0 tw-p-0 sm:tw-text-sm tw-text-black tw-text-xl sm:tw-font-light tw-font-semibold">
                        Logout
                    </span>
                </div>
            </div>
            <Prompt
                show={showLogoutConfirm}
                message="Are you sure you want to Logout?"
                buttons={['Cancel', 'Proceed to Logout']}
                onHide={() => setShowLogoutConfirm(false)}
                callback={() => handleLogout()}
            />
            {showUserProfileSecurity ? (
                <TwoFASecurityModal
                    visible={showUserProfileSecurity}
                    setVisible={setShowUserProfileSecurity}
                />
            ) : null}
        </>
    );
}
