
import { getAuth } from '../helpers';

export async function getAllMediaAssets({
    projectId,
    type = 'audio',
    search = '',
    userIds = [],
    startDate = '',
    endDate = '',
    sortBy = 'name',
    sortOrder = 'desc',
    page = 1,
}) {
    const auth = getAuth();

    const options = {
        projectId,
        type,
        search,
        userIds,
        startDate,
        endDate,
        sortBy,
        sortOrder,
    };

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/list?page=${page}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(options),
        }
    );
    return await response.json();
}

export async function getAllMediaAssetsUsers({
    projectId,
    dropDownSearch = '',
    type = 'audio',
    globalSearch = '',
    startDate = '',
    endDate = '',
}) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth.access_token}`;
    let url = `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/users?projectId=${projectId}&dropDownSearch=${dropDownSearch}&type=${type}&globalSearch=${globalSearch}`;
    if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        },
    });
    return await response.json();
}

export async function uploadMediaAsset({ projectId, files, type, filetypes }) {
    try {
        const auth = getAuth();
        const authBearer = `Bearer ${auth?.access_token}`;

        const filenames = files.map(file => file.name);

        const requestBody = {
            filenames,
            type,
        };

        const res = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/file-upload?projectId=${projectId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body: JSON.stringify(requestBody),
            }
        );

        const { status, files: uploadedFiles, message } = await res.json();
        if (!status) {
            throw new Error(message || 'Failed to generate signed S3 URLs');
        }

        const uploadedData = await Promise.all(
            files.map((file, index) => {
                const { signedUrl, token } = uploadedFiles[index];

                return fetch(signedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': filetypes[index],
                    },
                    body: file,
                }).then((res) => {
                    if (res?.status !== 200) {
                        throw new Error(`Failed to upload ${file.name}`);
                    }
                    return { filename: uploadedFiles[index].filename, token };
                });
            })
        );

        return uploadedData;
    } catch (e) {
        console.error(e);
        throw new Error(e.message || 'Error uploading files');
    }
}

export async function createMediaAsset({ projectId, files, type }) {
    try {
        const auth = getAuth();
        const authBearer = `Bearer ${auth?.access_token}`;

        const data = {
            files: files.map(file => ({
                name: file.name,
                token: file.token,
            })),
            type,
        };

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets?projectId=${projectId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    } catch (e) {
        console.error(e);
        throw new Error(e.message || 'Error creating media asset');
    }
}

export async function deleteMediaAsset({ projectId, mediaAssetId }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/${mediaAssetId}?projectId=${projectId}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function deleteManyMediaAsset({ projectId, mediaAssetIds }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/bulk-delete?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ ids: mediaAssetIds }),
        }
    );
    return await response.json();
}
