import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as ls from 'local-storage';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import Layout from 'components/app/common/layout';
import { useDispatch } from 'react-redux';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import { GetUser } from 'apis/rest/sponsorship/GetUser';
import * as act from '../../../../../../redux/actions/live/uiActionsLive';

import './projectsDashboard.scss';

function SponsorProjectDashboard() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [allProjects, setAllProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(act.enableSwitchEvent());
        handleGetUser();
    }, []);

    const handleClickGoToProject = (projectId) => {
        ls.set('tempProjId', projectId);
        history.push(`/project/sponsors`);
    };

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: null,
            key: 'action',
            render: (text, record) => (
                <button
                    onClick={() => handleClickGoToProject(record.projectId)}
                    className="btn myxp btn-primary ml-auto"
                >
                    Go to project
                </button>
            ),
        },
    ];

    const handleGetUser = async () => {
        try {
            const response = await GetUser();
            if (response.status) {
                const projectList = response?.sponsor?.projects;
                setAllProjects(projectList);
            } else history.replace('/login');
        } catch (error) {
            console.error('ERROR handleGetUser', error);
        } finally {
            setIsLoading(false);
        }
    };

    const globalLoadingComponent = {
        spinning: isLoading,
        indicator: <GlobalLoading />,
    };

    return (
        <Layout disableMenuWidth>
            <div className="container" style={{ width: '950px' }}>
                <div className="dashboard dashboard__xpmgr project-list">
                    <div className="dashboard__left">
                        <h1>Projects</h1>
                    </div>
                </div>
                <div className="mt-5">
                    <Table
                        rowKey="id"
                        columns={columns}
                        loading={globalLoadingComponent}
                        dataSource={allProjects}
                    />
                </div>
            </div>
        </Layout>
    );
}

SponsorProjectDashboard.propTypes = {};

export default SponsorProjectDashboard;
