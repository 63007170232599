import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import FrontArrow from './FrontArrow';

import { deskTopMenuOnly } from './constant';

const Links = ({ card = [] }) => {
    const { companyId, projectId } = useParams();

    const LinkItem = ({ link = '', name = '', index = 0 }) =>
        deskTopMenuOnly.includes(link) ? (
            <p
                className={`tw-flex tw-gap-1  tw-items-center tw-mb-0 ${
                    index !== 0 ? 'tw-mt-2' : ''
                }`}
            >
                <FrontArrow className={'tw-text-mobileTextBlue tw-opacity-50 '} />{' '}
                <span className="tw-text-base tw-text-mobileTextBlue tw-opacity-50 tw-font-openSans tw-font-semibold">
                    {name}
                </span>{' '}
                <Tooltip
                    trigger={['hover', 'click']}
                    placement="right"
                    title="This feature is not available on mobile. Please access it on desktop"
                >
                    <QuestionCircleOutlined />
                </Tooltip>
            </p>
        ) : (
            <Link
                key={link}
                className={`tw-flex tw-gap-1 tw-text-mobileTextBlue tw-items-center ${
                    index !== 0 ? 'tw-mt-2' : ''
                }`}
                to={`/company/${companyId}/project/${projectId}/${link}?sponsor=1`}
            >
                <FrontArrow />
                <span className="tw-text-base tw-font-openSans tw-font-semibold">
                    {name}
                </span>
            </Link>
        );

    return card?.sublinks && card?.sublinks.length ? (
        card?.sublinks?.map((link, index) => (
            <LinkItem
                key={link.link}
                link={link.link}
                index={index}
                name={link.name}
            />
        ))
    ) : (
        <LinkItem key={card.link} link={card.link} name={card.name} />
    );
};

export default Links;
