/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as userAct from 'redux/actions/common/userActions';
import Prompt from 'components/app/common/prompt';
import * as ls from 'local-storage';
import { getSponsorCompanies } from 'apis/rest/GetSponsorCompanies';
import { sponsorAuthorize, sponsorLogout } from 'apis/rest/Authentication';
import { getSponsor, refreshSponsorTempToken } from 'apis/rest/Sponsor';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { Button, Dropdown, Table, Tooltip } from 'antd';
import { LogoutIcon } from 'components/app/common/header/UserProfile/icons';
import * as act from '../../../../../../redux/actions/live/uiActionsLive';
import ListingCards from '../../../../../../ComponentsV2/CommonComponents/MobileView/listingCards/ListingCards';
import GevmeLogoPortrait from '../../../../../../ComponentsV2/Icons/GevmeLogoPortrait';
import { getNameInitials } from '../../../../utils/functions/common';
import './index.scss';
import 'antd/dist/antd.css';
import { LogoutIconMobile } from 'components/app/common/header/UserProfile/icons/LogoutIcon';
import { debounce } from 'lodash';

const maxNameLength = 30;
const PAGE_SIZE = 10;

function SponsorCompanyProfiles() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);

    const [companyList, setCompanyList] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [projectSearchWidth, setProjectSearchWidth] = useState('251px');
    const [searchDebounceText, setSearchDebounceText] = useState('');
    const [loading, setLoading] = useState(false);

    const firstRender = useRef(true);
    const projectSearchRef = useRef('');

    const columns = [
        { title: 'Company Name', dataIndex: 'companyName', key: '1' },
        { title: 'Project Name', dataIndex: 'projectName', key: '2' },
        {
            title: null,
            key: 'action',
            render: (text, record) => {
                return (
                    <button
                        onClick={() =>
                            handleCompanyClick(record.key, record.project)
                        }
                        className="btn myxp btn-primary ml-auto"
                    >
                        Go to project
                    </button>
                );
            },
        },
    ];

    const handleInputSearchEvent = (width, status) => {
        setProjectSearchWidth(width);
        setSearchIsFocused(status);
    };

    const handleSearch = (value) => {
        setCurrentPage(1);
        setSearchValue(value);
        searchDebounce(value);
    };

    const searchDebounce = useCallback(
        debounce((text) => setSearchDebounceText(text), 700),
        []
    );

    const handleSponsorTempToken = async (sponsorTempAuthToken) => {
        try {
            const { status, token, message } = await refreshSponsorTempToken(
                sponsorTempAuthToken?.access_token
            );

            if (status) {
                ls.set('sponsorTempAuth', token);
                handleSponsorCompanyListing();
            } else throw new Error(message);
        } catch (error) {
            if (
                error?.message &&
                (error.message.includes('invalid') ||
                    error.message.includes('expired'))
            ) {
                ls.clear();
                history.replace('/login');
            } else
                openNotification('error', {
                    message:
                        error?.message ||
                        'Something went wrong, please try to login and logout again.',
                });
        }
    };

    const handleSponsorCompanyListing = async () => {
        setLoading(true);
        const sponsorTempAuthToken = ls.get('sponsorTempAuth');
        try {
            if (sponsorTempAuthToken) {
                const { status, message, companyProfiles, pagination } =
                    await getSponsorCompanies(
                        currentPage,
                        10,
                        searchDebounceText
                    );
                if (status) {
                    setCompanyList(
                        companyProfiles.map((data) => ({
                            key: data.id,
                            projectName: data.project.name,
                            companyName: data.name,
                            project: data.project,
                        }))
                    );
                    setPaginationData(pagination);
                } else throw new Error(message);
            } else history.replace('/login');
        } catch (error) {
            if (
                (typeof error === 'string' && error?.includes('expired')) ||
                (error?.message && error?.message?.includes('expired'))
            ) {
                handleSponsorTempToken(sponsorTempAuthToken);
            } else
                openNotification(
                    'error',
                    {
                        message:
                            error?.message ||
                            'Something went wrong, please try to logout and login again.',
                    },
                    0
                );
        } finally {
            setLoading(false);
        }
    };

    const handleGetSponsor = async (companyId, project, token) => {
        const user = await getSponsor(token);

        dispatch(userAct.setUser(user));
        if (user?.user?.permission)
            dispatch({
                type: 'UPDATE_SPONSOR_MENU_DATA',
                payload: user.user.permission,
            });

        // ls('user', user.user);
        ls('project', project);
        ls.set('tempProjId', project.projectId);

        history.push(
            `/company/${companyId}/project/${project.projectId}/sponsor-home`
        );
    };

    const handleCompanyClick = async (companyId, project) => {
        try {
            const identifier = ls.get('sponsorTempAuth');

            if (!identifier) {
                ls.clear();
                history.replace('/login');
            } else {
                const token = await sponsorAuthorize({
                    identifier,
                    exhibitorCompanyProfileId: companyId,
                });

                if (token) {
                    const hasSponsorAuth = ls.get('sponsorAuth');
                    let sponsorAuth = hasSponsorAuth || {};

                    sponsorAuth = {
                        ...token,
                        projectId: project.projectId,
                        companyId,
                    };

                    ls.set('sponsorAuth', sponsorAuth);
                    window.SPONSOR_AUTH = sponsorAuth;

                    Object.freeze(window.SPONSOR_AUTH);

                    await handleGetSponsor(companyId, project, token);
                } else throw new Error();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogout = async () => {
        try {
            const res = await sponsorLogout();
            if (res?.status) {
                ls.clear();
                history.replace('/login');
            } else throw res;
        } catch (error) {
            console.error(error);
        }
    };

    const avatar = (
        <div className="tw-flex tw-flex-1 tw-min-h-[40px] tw-text-[#020202] tw-rounded-full tw-bg-[#e4eaf7] tw-w-[40px] tw-h-[40px] tw-text-sm tw-font-semibold tw-items-center tw-justify-center tw-uppercase">
            <span>{getNameInitials(user)}</span>
        </div>
    );
    const fullName = `${user?.firstname} ${user?.lastname}`;

    const profile = (
        <div className="tw-flex tw-p-4 tw-shadow-lg tw-flex-col tw-items-center tw-justify-center tw-w-72 tw-h-auto tw-mt-3 tw-bg-white tw-rounded-md tw-cursor-default">
            {avatar}
            <div className="tooltip-fullname tw-m-0 tw-p-0 sm:tw-text-sm tw-text-[18px] tw-mt-2 tw-text-mobileTextBlack tw-font-semibold sm:tw-text-black tw-break-words tw-text-center">
                {fullName?.length > maxNameLength ? (
                    <Tooltip
                        placement="top"
                        title={fullName}
                        zIndex={9999}
                        getPopupContainer={(triggerNode) => triggerNode}
                    >
                        <span className="tw-p-0 tw-m-0 tw-break-words">
                            {`${fullName.slice(0, maxNameLength - 1)} ...`}
                        </span>
                    </Tooltip>
                ) : (
                    fullName
                )}
            </div>
            <div className="tw-m-0 tw-p-0 sm:tw-text-xs tw-text-sm tw-mt-1 tw-text-mobileTextGray sm:tw-font-extralight tw-font-normal tw-mb-5">
                {user?.email}
            </div>

            <div
                className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-mt-0 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                onClick={() => setShowLogoutConfirm(true)}
            >
                <span className="tw-m-0 tw-p-0 sm:tw-pl-4 tw-pl-0 tw-pr-2">
                    <LogoutIconMobile />
                </span>
                <span className="tw-m-0 tw-p-0 sm:tw-text-sm tw-text-xl tw-text-black sm:tw-font-light tw-font-semibold">
                    Logout
                </span>
            </div>
        </div>
    );

    useEffect(() => {
        dispatch(act.updateAllowFX(false));
        // Let Layout component know that menus are not visible
        dispatch(act.updateMainMenuVisibility(false));
        dispatch(act.updateSubMenuVisibility(false));
        // Reset defaults
        dispatch(act.updateHeaderTitle(''));
        dispatch(act.updateActiveLivePageName(''));

        handleSponsorCompanyListing();
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        handleSponsorCompanyListing();
    }, [currentPage, searchDebounceText]);

    useEffect(() => {
        if (
            companyList.length === 1 &&
            paginationData?.total < 2 &&
            !searchDebounceText
        ) {
            dispatch({
                type: 'UPDATE_SINGLECOMPANY_SPONSOR',
                payload: true,
            });
            handleCompanyClick(companyList[0]?.key, companyList[0]?.project);
        }
    }, [companyList]);

    return (
        <>
            <div className="companyProfiles">
                <div className="header header--xpmanager shadow-sm">
                    <div className="header__custom">
                        <div className="header-main">
                            <div className="header-main__title-group">
                                <a href="/company-profiles" title="Gevme">
                                    <GevmeLogoPortrait />
                                </a>
                            </div>
                            <div className="tw-text-xl tw-text-mobileTextBlack tw-font-semibold">
                                Exhibitor Dashboard
                            </div>
                        </div>
                    </div>
                    {user?.id ? (
                        <div className="flex">
                            <Dropdown
                                overlay={profile}
                                trigger={['click']}
                                getPopupContainer={(node) => node.parentElement}
                            >
                                {avatar}
                            </Dropdown>
                        </div>
                    ) : (
                        <Button type="link" onClick={() => handleLogout()}>
                            Logout
                        </Button>
                    )}
                </div>
                <Prompt
                    show={showLogoutConfirm}
                    message="Are you sure you want to Logout?"
                    buttons={['Cancel', 'Proceed to Logout']}
                    onHide={() => setShowLogoutConfirm(false)}
                    callback={() => handleLogout()}
                />
                <div className="md:tw-flex tw-hidden tw-mt-[80px] tw-px-[160px] tw-mb-3 tw-w-full tw-items-center tw-justify-end">
                    <div className="project-search">
                        <input
                            value={searchValue}
                            ref={projectSearchRef}
                            type="text"
                            placeholder="Search By Company/Project Name"
                            className="form-control m-0"
                            style={{
                                width: projectSearchWidth,
                            }}
                            onChange={(e) => handleSearch(e.target.value)}
                            onFocus={() =>
                                handleInputSearchEvent('300px', true)
                            }
                            onBlur={() =>
                                handleInputSearchEvent('251px', false)
                            }
                        />
                        <i
                            className={
                                'fa ' +
                                (searchValue === '' && !searchIsFocused
                                    ? 'fa-search'
                                    : 'fa-close')
                            }
                            onClick={() => {
                                searchValue === '' && !searchIsFocused
                                    ? handleInputSearchEvent('251px', false)
                                    : handleSearch('');
                            }}
                        >
                            {' '}
                        </i>
                    </div>
                </div>
                <Table
                    style={{
                        width: '100%',
                        padding: '0 160px 0 160px',
                        background: '#f5f6fc',
                    }}
                    columns={columns}
                    dataSource={companyList}
                    loading={loading}
                    className="tw-hidden md:tw-block"
                    pagination={{
                        position: ['none', 'bottomCenter'],
                        onChange: setCurrentPage,
                        pageSize: PAGE_SIZE,
                        total: paginationData?.total,
                        showSizeChanger: false,
                        current: currentPage,
                    }}
                />

                <ListingCards
                    companyList={companyList}
                    gotToDashboard={handleCompanyClick}
                    paginationData={{
                        currentPage,
                        total: paginationData?.total,
                        pageSize: PAGE_SIZE,
                        onChange: setCurrentPage,
                    }}
                    searchValue={searchValue}
                    handleSearch={handleSearch}
                />
            </div>
        </>
    );
}

export default withRouter(SponsorCompanyProfiles);
