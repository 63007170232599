import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter, useHistory, Redirect } from 'react-router-dom';

import { Modal, Button, Dropdown } from 'react-bootstrap';
// import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';

import {
    getCompanies,
    createCompany,
    addManagerToCompany,
} from 'apis/rest/SuperAdmin';
import TablePreloader from '../../../common/preloader/tablePreloader';
import Moment from 'react-moment';

import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import useInputChange from 'components/app/utils/hooks/useInputChange';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import createProjectValidationCriteria from 'components/app/utils/validators/createProjectValidationCriteria';

import Layout from 'components/app/common/layout';
import './dashboard.scss';

import * as ls from 'local-storage';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';

function Dashboard(props) {
    const { showAlertBar } = useAlertBar();
    const history = useHistory();

    const [isBusy, setIsBusy] = useState(false);
    const [companies, setCompanies] = useState(null);
    const [visibleCreateCompanyModal, setVisibleCreateCompanyModal] = useState(
        false
    );
    const [visibleAddManagerModal, setVisibleAddManagerModal] = useState(false);

    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit, setCurrentLimit] = useState(10);

    const [headerHovered, setHeaderHovered] = useState(-1);

    const [projectSearchWidth, setProjectSearchWidth] = useState('132px');
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState('');
    const [sortedColumn, setSortedColumn] = useState({
        name: 'updatedAt',
        desc: true,
    });
    const projectSearchRef = useRef('');

    //check if user is a super admin
    const user = useSelector(state => state.user)
    const authFails =
        user?.roleId != '1' ||
        user?.role?.id != '1' ||
        user?.role?.name != 'Admin';

    //add a new manager states
    const initManagerInput = {
        firstname: '',
        lastname: '',
        email: '',
    };
    const [inputManager, setInputManager] = useState(initManagerInput);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    //create new company states
    const initCompanyInput = {
        name: '',
        contactPersonFirstname: '',
        contactPersonLastname: '',
        contactPersonEmail: '',
        contactPersonMobile: '',
        projectId: null,
        quota: 1,
    };
    const [input, setInput] = useState(initCompanyInput);

    const tableHeaders = [
        { id: 0, name: 'name', desc: 'Company', isSortShown: false },
        { id: 1, name: 'createdAt', desc: 'Created At', isSortShown: false },
        { id: 2, name: 'updatedAt', desc: 'Updated At', isSortShown: true },
        { id: 2, name: 'actions', desc: 'Actions', isSortShown: false },
    ];

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, [currentPage, currentLimit, searchDebounceText, sortedColumn]);

    const handleOnSort = (table) => {
        setSortedColumn({
            name: table.name,
            desc: table.name === sortedColumn.name ? !sortedColumn.desc : true,
        });
        setCurrentPage(1);
    };

    const searchDebounce = useCallback(
        debounce((text) => setSearchDebounceText(text), 700),
        []
    );
    const handleSearch = (value) => {
        setIsBusy(true);
        setCurrentPage(1);
        setSearchValue(value);
        searchDebounce(value);
    };

    const handleInputSearchEvent = (width, status) => {
        setProjectSearchWidth(width);
        setSearchIsFocused(status);
    };

    const hasPagination = () => {
        return !!Object.keys(pagination).length;
    };

    //Fetch companies
    const fetchCompanies = async () => {
        setIsBusy(true);
        try {
            let projectPage = currentPage;
            if (hasPagination()) {
                let computedPage = Math.ceil(pagination.total / currentLimit);
                projectPage =
                    computedPage < currentPage && !!computedPage
                        ? computedPage
                        : currentPage;
            }

            const response = await getCompanies(
                currentLimit,
                projectPage,
                searchDebounceText,
                false, //isArchivedFilter
                sortedColumn.name,
                sortedColumn.desc
            );

            setCurrentPage(projectPage);
            setCompanies(response.companies);
            setPagination(response.pagination);
        } catch (error) {
            console.error('fetchCompany ERROR', error);
        } finally {
            setIsBusy(false);
        }
    };

    // Update the handler to work with Ant Design's pagination
    const handlePaginationClick = (page) => setCurrentPage(page);

    const handleCurrentLimit = (limit) => {
        setCurrentLimit(limit);
        let computedPage = Math.ceil(pagination.total / limit);
        let projectPage =
            computedPage < currentPage ? computedPage : currentPage;

        setCurrentPage(projectPage);
    };

    const {
        validateInput,
        renderFieldError,
        hasErrors,
        resetFormErrors,
    } = useFormValidator({
        input,
        validationCriteria: createProjectValidationCriteria,
    });

    const submitCreateCompany = async () => {
        const formErrors = validateInput();
        if (!hasErrors(formErrors)) {
            let data = {
                name: input.name,
                contactPersonFirstname: input.contactPersonFirstname,
                contactPersonLastname: input.contactPersonLastname,
                contactPersonEmail: input.contactPersonEmail,
                contactPersonMobile: input.contactPersonMobile,
                projectId: input.projectId,
                quota: input.quota,
            };
            try {
                const response = await createCompany(data);
                if (response.status === false) {
                    showAlertBar(response.message, 'error');
                } else {
                    showAlertBar(
                        'Create a new project successfully.',
                        'success'
                    );
                    setVisibleCreateCompanyModal(false);
                    setInput(initCompanyInput);
                    fetchCompanies();
                }
            } catch (error) {
                console.error('createCompany ERROR', error);
                showAlertBar('The project could not be created', 'error');
            }
        }
    };

    const submitAddManager = async () => {
        if (selectedCompanyId) {
            let data = {
                firstname: inputManager.firstname,
                lastname: inputManager.lastname,
                email: inputManager.email,
                companyId: selectedCompanyId,
            };
            try {
                const response = await addManagerToCompany(data);
                if (response.status === false) {
                    showAlertBar(response.message, 'error');
                } else {
                    showAlertBar('Add a new manager successfully.', 'success');
                    setVisibleAddManagerModal(false);
                    setSelectedCompanyId(null);
                    setInputManager(initManagerInput);
                    fetchCompanies();
                }
            } catch (error) {
                console.error('submitAddManager ERROR', error);
                showAlertBar('The manager could not be add', 'error');
            }
        }
    };

    const onCloseCreateCompanyModal = () => {
        setVisibleCreateCompanyModal(false);
        setInput(initCompanyInput);
        resetFormErrors();
    };

    const onCloseAddManagerModal = () => {
        setVisibleAddManagerModal(false);
    };

    const { handleInputChange } = useInputChange(input, setInput);

    const renderCreateNewCompany = () => {
        return (
            <Modal
                backdrop="static"
                show={visibleCreateCompanyModal}
                onHide={onCloseCreateCompanyModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a new company</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="row">
                        <div className="col-12 mt-2">
                            <label>
                                Company Name{' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control mb-0"
                                onChange={handleInputChange}
                                autoFocus
                                value={input.name}
                            />
                            {renderFieldError('name')}
                        </div>
                        <div className="col-12 mt-2">
                            <label>
                                Contact Person First Name{' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="contactPersonFirstname"
                                className="form-control mb-0"
                                onChange={handleInputChange}
                                autoFocus
                                value={input.contactPersonFirstname}
                            />
                            {renderFieldError('contactPersonFirstname')}
                        </div>
                        <div className="col-12 mt-2">
                            <label>
                                Contact Person Last Name{' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="contactPersonLastname"
                                className="form-control mb-0"
                                onChange={handleInputChange}
                                autoFocus
                                value={input.contactPersonLastname}
                            />
                            {renderFieldError('contactPersonLastname')}
                        </div>
                        <div className="col-12 mt-2">
                            <label>
                                Contact Person E-Mail{' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="contactPersonEmail"
                                className="form-control mb-0"
                                onChange={handleInputChange}
                                autoFocus
                                value={input.contactPersonEmail}
                            />
                            {renderFieldError('contactPersonEmail')}
                        </div>
                        <div className="col-12 mt-2">
                            <label>Contact Person Mobile</label>
                            <input
                                type="text"
                                name="contactPersonMobile"
                                className="form-control mb-0"
                                onChange={handleInputChange}
                                autoFocus
                                value={input.contactPersonMobile}
                            />
                            {renderFieldError('contactPersonMobile')}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="myxp btn btn-cancel"
                        variant="secondary"
                        onClick={onCloseCreateCompanyModal}
                    >
                        Close
                    </Button>
                    <Button
                        className="myxp btn btn-primary"
                        variant="primary"
                        onClick={() => submitCreateCompany()}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const renderAddManager = () => {
        return (
            <Modal
                backdrop="static"
                show={visibleAddManagerModal}
                onHide={onCloseAddManagerModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a manager</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="row">
                        <div className="col-12 mt-2">
                            <label>
                                First Name{' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="firstname"
                                className="form-control mb-0"
                                onChange={(e) =>
                                    setInputManager({
                                        ...inputManager,
                                        ...{ firstname: e.target.value },
                                    })
                                }
                                value={inputManager.firstname}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label>
                                Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="lastname"
                                className="form-control mb-0"
                                onChange={(e) =>
                                    setInputManager({
                                        ...inputManager,
                                        ...{ lastname: e.target.value },
                                    })
                                }
                                value={inputManager.lastname}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label>
                                E-Mail <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="email"
                                className="form-control mb-0"
                                onChange={(e) =>
                                    setInputManager({
                                        ...inputManager,
                                        ...{ email: e.target.value },
                                    })
                                }
                                value={inputManager.email}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="myxp btn btn-cancel"
                        variant="secondary"
                        onClick={onCloseAddManagerModal}
                    >
                        Close
                    </Button>
                    <Button
                        className="myxp btn btn-primary"
                        variant="primary"
                        onClick={() => submitAddManager()}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    if (!authFails) {
        return (
            <>
                {renderCreateNewCompany()}
                {renderAddManager()}
                <Layout disableMenuWidth={true}>
                    <div className="container">
                        <div className="dashboard dashboard__xpmgr project-list mb-5">
                            <div className="dashboard__left">
                                <h1>Super Admin Dashboard</h1>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="project-search mr-3">
                                    <input
                                        value={searchValue}
                                        ref={projectSearchRef}
                                        type="text"
                                        className="form-control m-0"
                                        style={{ width: projectSearchWidth }}
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                        onFocus={() =>
                                            handleInputSearchEvent(
                                                '210px',
                                                true
                                            )
                                        }
                                        onBlur={() =>
                                            handleInputSearchEvent(
                                                '132px',
                                                false
                                            )
                                        }
                                    />
                                    <i
                                        className={
                                            'fa ' +
                                            (searchValue === '' &&
                                            !searchIsFocused
                                                ? 'fa-search'
                                                : 'fa-close')
                                        }
                                        onClick={() => {
                                            searchValue === '' &&
                                            !searchIsFocused
                                                ? handleInputSearchEvent(
                                                      '132px',
                                                      false
                                                  )
                                                : handleSearch('');
                                        }}
                                    >
                                        {' '}
                                    </i>
                                </div>
                                <button
                                    onClick={() =>
                                        setVisibleCreateCompanyModal(true)
                                    }
                                    className="myxp btn btn-primary btn-sm "
                                >
                                    <i className="fas fa-plus mr-1"></i> Create
                                    a company
                                </button>
                            </div>
                        </div>
                        <table className="table table-borderless project-list-table">
                            <thead>
                                <tr>
                                    {tableHeaders.map((table) => (
                                        <th
                                            scope="col"
                                            key={table.name}
                                            onMouseEnter={() =>
                                                setHeaderHovered(table.name)
                                            }
                                            onMouseLeave={() =>
                                                setHeaderHovered(-1)
                                            }
                                            onClick={() => handleOnSort(table)}
                                        >
                                            <div>
                                                {table.desc}
                                                {(sortedColumn.name ===
                                                    table.name ||
                                                    headerHovered ===
                                                        table.name) && (
                                                    <i
                                                        className={
                                                            'fa ' +
                                                            (!sortedColumn.desc &&
                                                            sortedColumn.name ===
                                                                table.name
                                                                ? 'fa-chevron-up'
                                                                : 'fa-chevron-down')
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {!isBusy &&
                                (companies ? (
                                    <tbody>
                                        {companies.map((company, i) => {
                                            return (
                                                <tr
                                                    key={
                                                        'company-row' +
                                                        company.id
                                                    }
                                                >
                                                    <td
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: `/admin/dashboard/${company.id}`,
                                                                state: {
                                                                    company,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <span className="project-title">
                                                            {company.name}
                                                            <span
                                                                className="_p-id"
                                                                hidden={true}
                                                                style={{
                                                                    visibility:
                                                                        'hidden',
                                                                }}
                                                            >
                                                                {company.id}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <Moment format="D/MM/YYYY h:mm a">
                                                            {company.createdAt}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <Moment format="D/MM/YYYY h:mm a">
                                                            {company.updatedAt}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => {
                                                                setSelectedCompanyId(
                                                                    company.id
                                                                );
                                                                setVisibleAddManagerModal(
                                                                    true
                                                                );
                                                            }}
                                                            className="myxp btn btn-primary btn-sm "
                                                        >
                                                            <i className="fas fa-user-plus mr-1"></i>{' '}
                                                            Add a manager
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="5"
                                            style={{ textAlign: 'center' }}
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                ))}
                            {isBusy && (
                                <TablePreloader columns={6}> </TablePreloader>
                            )}
                        </table>
                        <div className="align-items-center d-flex justify-content-between">
                            <Dropdown style={{ width: '100px' }}>
                                <Dropdown.Toggle
                                    variant="secondary"
                                    id=""
                                    style={{ padding: '8px 16px' }}
                                    className="m-0 myxp bg-white w-100 border rounded font-weight-bold justify-content-between"
                                >
                                    <span>Limit</span> {currentLimit}
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight={true}>
                                    {Array(3)
                                        .fill(5)
                                        .map((el, i) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    handleCurrentLimit(
                                                        el * (i + 1)
                                                    );
                                                }}
                                                key={i}
                                            >
                                                {el * (i + 1)}
                                            </Dropdown.Item>
                                        ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={handlePageCount()}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) =>
                                    handlePaginationClick(data)
                                }
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            /> */}
                            <Pagination
                                total={pagination?.total || 0}
                                current={currentPage}
                                pageSize={currentLimit}
                                showSizeChanger={false}
                                showQuickJumper={false}
                                onChange={handlePaginationClick}
                            />
                        </div>
                    </div>
                </Layout>
            </>
        );
    } else {
        return <Redirect to="/login" />;
    }
}

export default withRouter(Dashboard);
