import * as ls from 'local-storage';

/**
 * generates otp (email gets sent with otp)
 * @param {object} data data required to generate otp
 */
export async function generateOtp(data, type) {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/login/${type}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );

    return response;
}

/**
 * authenticates otp received to get authentication token
 * @param {object} data data required to get authentication token
 */
export async function authenticate(data) {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/validate`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}
export async function getAuthCode(data) {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/code`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function logout(data) {
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/logout`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function sponsorLogout() {
    const sponsorAuth = ls.get('sponsorAuth');
    const sponsorTempAuth = ls.get('sponsorTempAuth');
    let token = [];

    if (sponsorTempAuth) token.push(sponsorTempAuth.access_token);
    if (sponsorAuth) {
        // Get all tokens
        token = [...token, sponsorAuth.access_token];

        const bearerToken = sponsorAuth.access_token;

        const res = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/logout`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
                body: JSON.stringify({ token }),
            }
        );
        return await res.json();
    }
    return { status: true, message: 'No sponsor auth token generated yet' };
}

export async function generateSponsorOtp(data) {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/login`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );

    return response;
}

export async function sponsorTempAuthenticate(data) {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/otp`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}
export async function sponsorAuthorize(data) {
    const auth = ls.get('sponsorTempAuth');
    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/authorize`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function handleSSOLogin(code, state) {
    const [identifier, ssoType] = state?.split('|');
    try {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/auth/sso/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code,
                    identifier,
                    ssoType,
                }),
            }
        );
        const data = await response.json();
        localStorage.setItem('auth', JSON.stringify(data?.token));
        window.location.href = '/';
    } catch (error) {
        console.log(error);
    }
}  
