import getAuthBearer from '../../utils/getAuthBearer';

/**
 * gets the list of leads users
 */
export async function getLeadsUsers({
    projectId,
    type = 'all',
    limit = 10,
    page = 1,
}) {
    const authBearer = getAuthBearer();
    if (authBearer) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads?projectId=${projectId}&limit=${limit}&type=${type}&page=${page}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
    return null;
}
export async function updateActivation({ projectId, id, status }) {
    const authBearer = getAuthBearer();
    if (authBearer) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads/${id}/${status}?projectId=${projectId}`,
            {
                method: 'PUT',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
    return null;
}
export async function getLeadsCaptured({ projectId, page, limit }) {
    const authBearer = getAuthBearer();
    if (authBearer) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads/captured?projectId=${projectId}&page=${page}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
    return null;
}
export async function exportLeadsCaptured({ projectId }) {
    const authBearer = getAuthBearer();
    if (authBearer) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads/captured/export?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.blob();
    }
    return null;
}
