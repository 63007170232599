import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import './inviteUsers.scss';

function InviteUsers(props) {
    const {
        setModalVisibility,
        setModalView,
        leadsQuota,
        totalLeadsQuota,
        className = '',
        showText = true,
    } = props;

    /**
     * renders text for inviting users
     */
    const renderText = () => {
        if (leadsQuota < totalLeadsQuota) {
            return (
                <>
                    You can invite up to{' '}
                    <strong>{totalLeadsQuota - leadsQuota} user(s)</strong>
                </>
            );
        }
        return "You don't have invites left.";
    };

    return (
        <div className={`invite-users ${className}`}>
            <button
                className="tw-w-full tw-min-h-[48px] md:tw-w-auto tw-py-2 tw-px-0 md:tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white !tw-text-base tw-font-semibold tw-font-inter tw-text-center tw-cursor-pointer"
                onClick={() => {
                    setModalVisibility(true);
                    setModalView('inviteUsers');
                }}
                disabled={leadsQuota >= totalLeadsQuota ? true : false}
            >
                Invite Your Users
            </button>
            {showText && (
                <p className="tw-text-text-gray-dark md:tw-text-black tw-text-xssm">
                    {renderText()}
                </p>
            )}
        </div>
    );
}

InviteUsers.propTypes = {
    setModalVisibility: PropTypes.func.isRequired,
    setModalView: PropTypes.func.isRequired,
    leadsQuota: PropTypes.number.isRequired,
    totalLeadsQuota: PropTypes.number.isRequired,
};

export default withRouter(InviteUsers);
