import * as ls from 'local-storage';

let authBearer = null;

const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            const auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
export async function ImportSpeaker(inputs, projectId) {
    setAuthBearer();
    // Will change this
    const formData = new FormData();

    Object.keys(inputs).forEach((property) => {
        if (Object.prototype.hasOwnProperty.call(inputs, property)) {
            formData.append(property, inputs[property]);
        }
    });

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/import?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
            body: formData,
        }
    );

    return await response.json();
}
export async function downloadSampleSpeakers(projectId) {
    setAuthBearer();

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/demo-csv?projectId=${projectId}`,
        {
            headers: {
                Authorization: authBearer,
            },
        }
    );

    return await response.text();
}
