import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, isObject } from 'lodash';

import { useParams } from 'react-router-dom';
import { Modal, DatePicker, TimePicker, List, Select, message } from 'antd';
import moment from 'moment';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import { CreateNewPoll } from 'apis/rest/polls/CreateNewPoll';
import { UpdatePoll } from 'apis/rest/polls/UpdatePoll';
import { UpdatePollStatus } from 'apis/rest/polls/UpdatePollStatus';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import NotificationBox from '../notificationBox';

import './scheduleModal.scss';

function ScheduleModal({
    visible,
    setVisible,
    notificationSendNow,
    notificatonData,
    onRefreshData,
    notificationEditMode,
    sendDirect = false,
    scheduleDate,
    setScheduleDate,
    sendMode,
    selectedTrigger,
    triggerTime,
    timeUnit,
}) {
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const { projectId } = useParams();

    const handleCreateNotification = async () => {
        const {
            question,
            options,
            showResultAfterVoting,
            position,
            targetType,
            target,
            ctaText,
            ctaColor,
            ctaTextColor,
        } = notificatonData;

        const inputs = {
            ...(targetType === 'project' && {
                targetType: 'project',
            }),
            ...(targetType === 'livepage' && {
                targetType: 'livepage',
                target: target.map((value) => value.key),
            }),
            question,
            options,
            position,
            ctaText,
            ctaColor,
            ctaTextColor,
            showResultAfterVoting,
            projectId,
        };
        try {
            const response = await CreateNewPoll(inputs);
            if (response.status) {
                return response.id;
            }
            throw new Error(response.message);
        } catch (error) {
            openNotification('error', {
                message: error.message || 'Something went wrong',
            });
        }
    };

    const handleSendNotification = debounce(async (notificationStatus) => {
        if (notificationStatus === 'sendNow') {
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();

                const response = await UpdatePollStatus(
                    newNotificationId,
                    'publish',
                    projectId
                );
                if (response.status) {
                    openNotification('success', { message: 'Poll published!' });
                    setVisible(false);
                    onRefreshData();
                }
                if (!response.status) {
                    openNotification('error', { message: response.message });
                }
            }
            //Publish exist notification
            if (notificationEditMode) {
                const {
                    question,
                    options,
                    showResultAfterVoting,
                    position,
                    targetType,
                    target,
                    ctaText,
                    ctaColor,
                    ctaTextColor,
                } = notificatonData;

                const inputs = {
                    ...(targetType === 'project' && {
                        targetType: 'project',
                    }),
                    ...(targetType === 'livepage' && {
                        targetType: 'livepage',
                        target: target.map((value) => value.key),
                    }),
                    question,
                    options,
                    position,
                    ctaText,
                    ctaColor,
                    ctaTextColor,
                    showResultAfterVoting,
                    projectId,
                };

                if (sendDirect) {
                    const response = await UpdatePollStatus(
                        notificatonData.id,
                        'publish',
                        projectId
                    );

                    if (response.status) {
                        openNotification('success', {
                            message: 'Poll published!',
                        });
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification('error', {
                            message: response.message,
                        });
                    }
                }

                if (!sendDirect) {
                    const response = await UpdatePoll(
                        notificatonData.id,
                        inputs,
                        projectId
                    );

                    if (response.status) {
                        const publishResponse = await UpdatePollStatus(
                            notificatonData.id,
                            'publish',
                            projectId
                        );

                        if (publishResponse.status) {
                            openNotification('success', {
                                message: 'Poll published!',
                            });
                            setVisible(false);
                            onRefreshData();
                        }
                    }
                    if (!response.status) {
                        openNotification('error', {
                            message: response.message,
                        });
                    }
                }
            }
        } else if (notificationStatus === 'schedule') {
            const {
                question,
                options,
                showResultAfterVoting,
                position,
                targetType,
                target,
                ctaText,
                ctaColor,
                ctaTextColor,
            } = notificatonData;

            const notificationSchedule = scheduleDate.utc().format();

            if (
                moment()
                    .add(4, 'minutes')
                    .diff(moment(notificationSchedule), 'second') > 0
            ) {
                const userResponse = window.confirm(
                    'Scheduled Time should be atleast 5 minutes from now.Click on "OK" to reset the time.'
                );
                if (userResponse)
                    setScheduleDate(moment().tz(timezone).add(5, 'minutes'));
                return;
            }

            const inputs = {
                ...(targetType === 'project' && {
                    targetType: 'project',
                }),
                ...(targetType === 'livepage' && {
                    targetType: 'livepage',
                    target: target.map((value) => value?.key || value?.id),
                }),
                question,
                options: options.map((value) =>
                    isObject(value) ? value.label : value
                ),
                position,
                ctaText,
                ctaColor,
                ctaTextColor,
                showResultAfterVoting,
                projectId,
                scheduledTime: notificationSchedule,
            };
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();

                const response = await UpdatePoll(
                    newNotificationId,
                    inputs,
                    projectId
                );

                if (response.status) {
                    const publishResponse = await UpdatePollStatus(
                        newNotificationId,
                        'publish',
                        projectId
                    );
                    if (publishResponse.status) {
                        openNotification('success', {
                            message: 'Poll scheduled!',
                        });
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification('error', {
                            message: response.message,
                        });
                    }
                } else if (newNotificationId) {
                    openNotification('error', {
                        message:
                            response.message ||
                            'Notification created, but an error was encountered while scheduled.',
                    });
                    onRefreshData();
                } else {
                    openNotification('error', {
                        message: response.message,
                    });
                }
            }
            //Schedule exist notification
            if (notificationEditMode) {
                const response = await UpdatePoll(
                    notificatonData.id,
                    inputs,
                    projectId
                );

                if (response.status) {
                    const publishResponse = await UpdatePollStatus(
                        notificatonData.id,
                        'publish',
                        projectId
                    );

                    if (publishResponse.status) {
                        openNotification('success', {
                            message: 'Poll scheduled!',
                        });
                        setVisible(false);
                        onRefreshData();
                    }
                }
                if (!response.status) {
                    openNotification('error', { message: response.message });
                }
            }
        } else {
            //trigger

            const {
                question,
                options,
                showResultAfterVoting,
                position,
                targetType,
                target,
                ctaText,
                ctaColor,
                ctaTextColor,
            } = notificatonData;

            let inputs = {
                ...(targetType === 'project' && {
                    targetType: 'project',
                }),
                ...(targetType === 'livepage' && {
                    targetType: 'livepage',
                    target: target.map((value) => value.key),
                }),
                question,
                options,
                position,
                ctaText,
                ctaColor,
                ctaTextColor,
                showResultAfterVoting,
                projectId,
                triggerCode: selectedTrigger.code,
                oncePerParticipant: true,
            };

            let timeInSecs = null;

            if (
                [
                    'LIVEPAGE_SPECIFIC_TIME',
                    'LIVEPAGE_USER_INACTIVE_TIME',
                ].includes(selectedTrigger.code)
            ) {
                timeInSecs =
                    timeUnit === 'secs' ? triggerTime : triggerTime * 60;
                inputs = {
                    ...inputs,
                    triggerConfig: { timeInSecs, timeUnit },
                };
            }

            if (notificatonData?.id) {
                //edit mode
                const updateResponse = await UpdatePoll(
                    notificatonData?.id,
                    inputs,
                    projectId
                );
                if (!updateResponse.status) {
                    openNotification('error', {
                        message: updateResponse?.message,
                    });
                    return;
                }
            }

            let notifId = notificatonData?.id;
            if (!notifId) {
                const { id = null } = await CreateNewPoll(inputs);
                notifId = id;
            }

            if (notifId) {
                //edit mode
                const response = await UpdatePollStatus(
                    notifId,
                    'publish',
                    projectId
                );
                if (response.status) {
                    openNotification('success', { message: 'Poll published!' });
                    setVisible(false);
                    onRefreshData();
                }
                if (!response.status) {
                    openNotification('error', { message: response.message });
                }
            } else {
                openNotification('error', {
                    message: 'invalid poll id',
                });
            }
        }
    }, 500);
    return (
        <Modal
            destroyOnClose
            forceRender
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            width={905}
        >
            <div className="p-4" style={{ height: 560, overflow: 'auto' }}>
                <div className="row h-100">
                    <div className="col-sm-5 d-flex flex-column justify-content-between">
                        <div className="row">
                            <div className="col-12 mb-3 modalHead">
                                <h2>Review your settings</h2>
                                <p>
                                    The poll will be sent based on your
                                    settings.
                                </p>
                            </div>

                            <div className="col-12 mb2">
                                {notificatonData?.targetType === 'project' ? (
                                    <div className="infoBox">
                                        <label>Livepages(All)</label>
                                        <div>All Livepages</div>
                                    </div>
                                ) : (
                                    <>
                                        <label className="font-weight-normal text-center">
                                            It will be sent to the following{' '}
                                            <strong>
                                                {
                                                    notificatonData?.target?.filter(
                                                        function (e) {
                                                            return e;
                                                        }
                                                    ).length
                                                }{' '}
                                                livepages:
                                            </strong>
                                        </label>
                                        <List
                                            className="live-pages-list"
                                            size="small"
                                            bordered
                                            dataSource={notificatonData?.target?.filter(
                                                function (e) {
                                                    return e;
                                                }
                                            )}
                                            renderItem={(item) => (
                                                <List.Item key={item?.id}>
                                                    {item?.value || item?.title}
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                )}

                                <div className="infoBox">
                                    <label>When</label>
                                    <div>
                                        {sendMode === 'sendNow'
                                            ? 'Now'
                                            : sendMode === 'trigger'
                                            ? 'Based on trigger'
                                            : 'Scheduled'}
                                    </div>
                                    {sendMode === 'trigger' && (
                                        <>
                                            <div className="mt-2">
                                                {selectedTrigger.description}
                                                {[
                                                    'LIVEPAGE_SPECIFIC_TIME',
                                                    'LIVEPAGE_USER_INACTIVE_TIME',
                                                ].includes(
                                                    selectedTrigger.code
                                                ) && (
                                                    <>
                                                        <br />- {triggerTime}{' '}
                                                        {timeUnit}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {!notificationSendNow && (
                                <>
                                    <div className="col-6">
                                        <label className="mb-1 timeLabel">
                                            Date
                                        </label>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            disabledDate={(current) =>
                                                current <
                                                moment()
                                                    .tz(timezone)
                                                    .startOf('day')
                                            }
                                            allowClear={false}
                                            value={scheduleDate}
                                            onChange={setScheduleDate}
                                            showToday={false}
                                            format="D MMM YYYY"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label className="mb-1 timeLabel">
                                            Time
                                        </label>
                                        <TimePicker
                                            allowClear={false}
                                            style={{ width: '100%' }}
                                            disabledDate={(current) =>
                                                current <
                                                moment()
                                                    .tz(timezone)
                                                    .add(9, 'minutes')
                                            }
                                            value={scheduleDate}
                                            onChange={setScheduleDate}
                                            format="hh:mm a"
                                            showNow={false}
                                        />
                                    </div>
                                    <div className="col-12 mt-3 ">
                                        <label className="mb-1 timeLabel">
                                            Time Zone
                                        </label>
                                        <TimezoneSelector
                                            selectStyle={{ width: '100%' }}
                                            wrapperClass=""
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mx-auto">
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="myxp btn btn-secondary"
                                            onClick={() => setVisible(false)}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="myxp btn btn-sm btn-dark mx-2"
                                            onClick={() =>
                                                handleSendNotification(sendMode)
                                            }
                                        >
                                            Send now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="AppContent rounded h-100">
                            <div className="w-100">
                                <h3 className="mt-3 mb-4 text-center">
                                    Have a final look:
                                </h3>
                                <div className="position-relative">
                                    <NotificationBox
                                        question={notificatonData?.question}
                                        options={notificatonData?.options}
                                        position={null}
                                        buttonLabel={notificatonData?.ctaText}
                                        buttonColor={notificatonData?.ctaColor}
                                        buttonTextColor={
                                            notificatonData?.ctaTextColor
                                        }
                                        previewMode
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

ScheduleModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func,
    notificationSendNow: PropTypes.bool,
    notificatonData: PropTypes.object,
    onRefreshData: PropTypes.func,
    notificationEditMode: PropTypes.bool,
};

ScheduleModal.defaultProps = {
    visible: false,
    notificationSendNow: false,
    notificatonData: null,
    notificationEditMode: false,
};

export default ScheduleModal;
