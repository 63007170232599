import React, { useState, useCallback, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import uuid from 'react-uuid';

import { UploadFile } from 'apis/rest/products/UploadFile';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import styles from './index.module.css';

const ProductFileUploader = ({
    fileType = 'images',
    multiple = false,
    previewImage,
    setResourceId,
    onRemoveClick,
    projectId = null,
    resetDefault,
    enableView = false,
    label = '',
    ctaIcon = '',
    isSubmitted = false,
    setIsSubmitted = () => null,
}) => {
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [inputID, setInputID] = useState(uuid());
    const [input2ID, setInput2ID] = useState(uuid());
    const [prepareFile, setPrepareFile] = useState(null);
    const [name, setName] = useState(label);
    const [icon, setIcon] = useState(ctaIcon);
    const [error, setError] = useState(false);

    useEffect(() => {
        setName(label);
    }, [label]);

    useEffect(() => {
        setIcon(ctaIcon);
    }, [ctaIcon]);

    const handlePrepareFileUpload = async () => {
        try {
            if (prepareFile) {
                const input = {
                    name,
                    resource: prepareFile,
                    icon,
                };
                const response = await UploadFile(projectId, input);
                if (response.status) {
                    setResourceId(response?.id);
                } else throw response;
                setError(false);
            }
        } catch (error) {
            setError(true);
            openNotification('error', {
                message: error?.message || 'Failed to upload',
            });
        } finally {
            setIsSubmitted(false);
        }
    };
    useEffect(() => {
        if (isSubmitted) {
            handlePrepareFileUpload();
        }
    }, [isSubmitted]);

    const onDrop = useCallback(async (acceptedFiles) => {
        setFilesToBeUploaded(acceptedFiles);
        setPrepareFile(acceptedFiles[0]);
    }, []);

    const getAcceptedFileTypes = () => {
        let acceptedFileType = '';
        switch (fileType) {
            case 'pdf':
                acceptedFileType = 'application/pdf';
                break;
            case 'images':
                acceptedFileType = 'image/*';
            default:
                acceptedFileType = 'image/*';
        }
        return acceptedFileType;
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
        useDropzone({
            accept: getAcceptedFileTypes(),
            multiple,
            onDrop,
            maxSize: 26214400, //25MB limit
        });

    const renderThumbnailUploader = () => {
        if (filesToBeUploaded.length > 0) {
            return filesToBeUploaded?.map((item, index) => {
                return (
                    <div
                        className={`${styles.FilePreview} ${
                            fileType === 'pdf' ? styles.FilePreviewPDF : ''
                        } file-preview`}
                        style={{
                            backgroundImage: `url(${URL.createObjectURL(item)}`,
                        }}
                    >
                        {fileType === 'pdf' && item.name}
                    </div>
                );
            });
        }
    };

    const selectControl = () => {
        return (
            <>
                <span
                    onClick={open}
                    className={`${styles.ChangeFile} change-file-txt`}
                >
                    Change
                </span>
                <input
                    id={`${fileType}-upload-${inputID}`}
                    {...getInputProps()}
                />
            </>
        );
    };

    const onRemoveImage = () => {
        setFilesToBeUploaded([]);
        setInput2ID(uuid());
        setPrepareFile(null);

        if (onRemoveClick) onRemoveClick(true);
    };

    const removeImage = () => {
        return (
            <span
                onClick={() => onRemoveImage()}
                className={`${styles.RemoveFile} remove-file-txt`}
            >
                Remove
            </span>
        );
    };

    const resetValue = () => {
        return (
            <span
                onClick={() => resetDefault()}
                className={`${styles.RemoveFile} remove-file-txt`}
            >
                Reset to default
            </span>
        );
    };

    const view = () => {
        return (
            <a
                href={previewImage}
                target="_blank"
                className={`${styles.RemoveFile} change-file-txt`}
                rel="noreferrer"
            >
                View
            </a>
        );
    };

    return (
        <div className={styles.FileUploaderContainer}>
            <div
                {...getRootProps()}
                className={`${styles.Uploader} ${
                    error ? 'error' : ''
                } uploader `}
            >
                <input
                    key={input2ID}
                    id={`${fileType}-main-${input2ID}`}
                    {...getInputProps()}
                />

                {!isDragActive &&
                    filesToBeUploaded?.length === 0 &&
                    !previewImage && (
                        <span>
                            Select {fileType === 'pdf' ? 'PDF' : 'image'}
                        </span>
                    )}

                {isDragReject && 'File type not accepted, sorry!'}

                {/* {previewImage  ? renderPreview()  : renderThumbnailUploader()  } */}
                {renderThumbnailUploader()}
            </div>
            <div className={styles.OutsideControls}>
                {(filesToBeUploaded?.length !== 0 || previewImage) &&
                    enableView &&
                    view()}
                {(filesToBeUploaded?.length !== 0 || previewImage) &&
                    resetDefault &&
                    resetValue()}
                {(filesToBeUploaded?.length !== 0 || previewImage) &&
                    selectControl()}
                {(filesToBeUploaded?.length !== 0 || previewImage) &&
                    onRemoveClick &&
                    removeImage()}
            </div>
            {error && <span className="required txt">Required</span>}
        </div>
    );
};

export default ProductFileUploader;
