import React, { useState, useEffect } from 'react';
import './bookmarks.scss';
import { useParams } from 'react-router-dom';
import { getBookmarks, exportBookmarks } from 'apis/rest/AnalyticsBookmarks';
// import ReactPaginate from 'react-paginate';
import Heading from './Heading';
import FileSaver from 'file-saver';
import PageSpinner from 'components/app/common/pageSpinner';
import { Pagination } from 'antd';

export default function Bookmarks() {
    const { projectId } = useParams();

    const [sessions, setSessions] = useState(null);
    const [profile, setProfile] = useState(null);
    const [resources, setResourses] = useState(null);
    const [attendees, setAttendees] = useState(null);
    const [products, setProducts] = useState(null);
    const [isLoading, toggleLoader] = useState(true);

    const [isExporting, setExport] = useState({
        exhibitor: false,
        session: false,
        resource: false,
        attendee: false,
        product: false,
    });

    const TYPE_ATTENDEE = 'attendee';
    const TYPE_EXHIBITOR = 'exhibitor';
    const TYPE_PRODUCT = 'product';
    const TYPE_RESOURCE = 'resource';
    const TYPE_SESSION = 'session';

    const BOOKMARK_TYPES = [
        TYPE_ATTENDEE,
        TYPE_EXHIBITOR,
        TYPE_PRODUCT,
        TYPE_RESOURCE,
        TYPE_SESSION,
    ];

    const bookmarksLimit = 5;

    useEffect(() => {
        BOOKMARK_TYPES.forEach((type) =>
            getBookmarkDetailsByType(bookmarksLimit, 1, type)
        );
    }, []);

    useEffect(() => {
        if (
            sessions &&
            profile &&
            resources &&
            attendees &&
            products &&
            isLoading
        )
            toggleLoader(false);
    }, [sessions, profile, resources, attendees, products]);

    const getBookmarkDetailsByType = async (limit, page, type) => {
        try {
            const response = await getBookmarks(projectId, type, limit, page);
            if (response?.status) {
                type === TYPE_EXHIBITOR && setProfile(response);

                type === TYPE_SESSION && setSessions(response);

                type === TYPE_RESOURCE && setResourses(response);

                type === TYPE_PRODUCT && setProducts(response);

                type === TYPE_ATTENDEE && setAttendees(response);
            }
        } catch (error) {
            console.error(error);
            toggleLoader(false);
        }
    };

    const handlePageChange = (page, type) => {
        // No need to add 1 since Ant Design uses 1-based page numbers
        getBookmarkDetailsByType(bookmarksLimit, page, type);
    };
    
    const handlePageCount = (type) => {
        // The logic remains the same since we're just calculating totals
        switch (type) {
            case TYPE_EXHIBITOR:
                return profile.pagination?.total || 0;
            case TYPE_SESSION:
                return sessions.pagination?.total || 0;
            case TYPE_PRODUCT:
                return products.pagination?.total || 0;
            case TYPE_ATTENDEE:
                return attendees.pagination?.total || 0;
            default:
                return resources.pagination?.total || 0;
        }
    };

    const exportData = async (type) => {
        try {
            if (!isExporting[type]) {
                setExport((data) => ({ ...data, ...{ [type]: true } }));
                const response = await exportBookmarks(projectId, type);
                FileSaver.saveAs(response, `${type}.csv`);
                setExport((data) => ({ ...data, ...{ [type]: false } }));
            }
        } catch (error) {
            console.error(error);
            setExport((data) => ({ ...data, ...{ [type]: false } }));
        }
    };

    return (
        <>
            {isLoading ? (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                    msg="Fetching data..."
                />
            ) : (
                <div className="bookmarks">
                    {/* Company Profile */}
                    <h2>Who bookmarked your company profile?</h2>
                    <div className="bookmark-container">
                        <div className="heading">
                            <Heading
                                count={profile?.pagination?.total}
                                text="Visitors bookmarked your company profile"
                            />
                        </div>
                        {profile?.bookmarks?.length > 0 && (
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Job Title</th>
                                            <th>Company</th>
                                            <th>Bookmark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {profile?.bookmarks.map((prfData) => {
                                            return (
                                                <tr key={prfData.id}>
                                                    <td>
                                                        {prfData.user
                                                            ?.firstname || 'NA'}
                                                    </td>
                                                    <td>
                                                        {prfData.user
                                                            ?.lastname || 'NA'}
                                                    </td>
                                                    <td>
                                                        {prfData.user?.email ||
                                                            'NA'}
                                                    </td>
                                                    <td>
                                                        {prfData.user
                                                            ?.jobTitle || 'NA'}
                                                    </td>
                                                    <td>
                                                        {prfData.user
                                                            ?.company || 'NA'}
                                                    </td>
                                                    <td>
                                                        {prfData.target?.name ||
                                                            'NA'}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {profile?.pagination?.totalpage > 1 && (
                                    <div className="pagination-container">
                                        {/* <ReactPaginate
                                            disabledClassName
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={handlePageCount(
                                                TYPE_EXHIBITOR
                                            )}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) =>
                                                handlePageChange(
                                                    data,
                                                    TYPE_EXHIBITOR
                                                )
                                            }
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                        /> */}
                                        <Pagination
                                            total={handlePageCount(
                                                TYPE_EXHIBITOR
                                            )}
                                            pageSize={bookmarksLimit}
                                            showSizeChanger={false}
                                            showQuickJumper={false}
                                            onChange={(page) =>
                                                handlePageChange(
                                                    page,
                                                    TYPE_EXHIBITOR
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div className="exports">
                                    <button
                                        onClick={() =>
                                            exportData(TYPE_EXHIBITOR)
                                        }
                                    >
                                        {!isExporting.exhibitor
                                            ? 'Export Data'
                                            : 'Exporting...'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Sessions */}
                    <h2>Who bookmarked your sessions?</h2>
                    <div className="bookmark-container">
                        <div className="heading">
                            <Heading
                                count={sessions?.pagination?.total}
                                text="Visitors bookmarked your sessions"
                            />
                        </div>
                        {sessions?.bookmarks?.length > 0 && (
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Company</th>
                                            <th>Job Title</th>
                                            <th>Bookmark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sessions?.bookmarks.map(
                                            (
                                                { id, session, user, target },
                                                index
                                            ) => {
                                                return (
                                                    <tr key={id ? id : index}>
                                                        <td>
                                                            {user?.firstname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.lastname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.email ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.company ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.jobTitle ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {target?.name ||
                                                                'NA'}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                                {sessions?.pagination?.totalpage > 1 && (
                                    <div className="pagination-container">
                                        {/* <ReactPaginate
                                            disabledClassName
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={handlePageCount(
                                                TYPE_SESSION
                                            )}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) =>
                                                handlePageChange(
                                                    data,
                                                    TYPE_SESSION
                                                )
                                            }
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                        /> */}
                                        <Pagination
                                            total={handlePageCount(
                                                TYPE_SESSION
                                            )}
                                            pageSize={bookmarksLimit}
                                            showSizeChanger={false}
                                            showQuickJumper={false}
                                            onChange={(page) =>
                                                handlePageChange(
                                                    page,
                                                    TYPE_SESSION
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div className="exports">
                                    <button
                                        onClick={() => exportData(TYPE_SESSION)}
                                    >
                                        {!isExporting.session
                                            ? 'Export Data'
                                            : 'Exporting...'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Products */}
                    <h2>who bookmarked your products?</h2>
                    <div className="bookmark-container">
                        <div className="heading">
                            <Heading
                                count={products?.pagination?.total}
                                text="Visitors bookmarked your products"
                            />
                        </div>
                        {products?.bookmarks?.length > 0 && (
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Company</th>
                                            <th>Job Title</th>
                                            <th>Bookmark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products?.bookmarks.map(
                                            ({ id, user, target }, index) => {
                                                return (
                                                    <tr key={id ? id : index}>
                                                        <td>
                                                            {user?.firstname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.lastname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.email ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.company ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.jobTitle ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {target?.name ||
                                                                'NA'}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                                {products?.pagination?.totalpage > 1 && (
                                    <div className="pagination-container">
                                        {/* <ReactPaginate
                                            disabledClassName
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={handlePageCount(
                                                TYPE_PRODUCT
                                            )}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) =>
                                                handlePageChange(
                                                    data,
                                                    TYPE_PRODUCT
                                                )
                                            }
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                        /> */}
                                        <Pagination
                                            total={handlePageCount(
                                                TYPE_PRODUCT
                                            )}
                                            pageSize={bookmarksLimit}
                                            showSizeChanger={false}
                                            showQuickJumper={false}
                                            onChange={(page) =>
                                                handlePageChange(
                                                    page,
                                                    TYPE_PRODUCT
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div className="exports">
                                    <button
                                        onClick={() => exportData(TYPE_PRODUCT)}
                                    >
                                        {!isExporting.product
                                            ? 'Export Data'
                                            : 'Exporting...'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Resources */}
                    <h2>who bookmarked your resources?</h2>
                    <div className="bookmark-container">
                        <div className="heading">
                            <Heading
                                count={resources?.pagination?.total}
                                text="Visitors bookmarked your resources"
                            />
                        </div>
                        {resources?.bookmarks?.length > 0 && (
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Company</th>
                                            <th>Job Title</th>
                                            <th>Bookmark</th>
                                            <th>Bookmark Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resources?.bookmarks.map(
                                            ({ id, user, target }, index) => {
                                                return (
                                                    <tr key={id ? id : index}>
                                                        <td>
                                                            {user?.firstname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.lastname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.email ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.company ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.jobTitle ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {target?.label ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {(
                                                                <a
                                                                    href={
                                                                        target?.link
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    {
                                                                        target?.link
                                                                    }
                                                                </a>
                                                            ) || 'NA'}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                                {resources?.pagination?.totalpage > 1 && (
                                    <div className="pagination-container">
                                        {/* <ReactPaginate
                                            disabledClassName
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={handlePageCount(
                                                TYPE_RESOURCE
                                            )}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) =>
                                                handlePageChange(
                                                    data,
                                                    TYPE_RESOURCE
                                                )
                                            }
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                        /> */}
                                        <Pagination
                                            total={handlePageCount(
                                                TYPE_RESOURCE
                                            )}
                                            pageSize={bookmarksLimit}
                                            showSizeChanger={false}
                                            showQuickJumper={false}
                                            onChange={(page) =>
                                                handlePageChange(
                                                    page,
                                                    TYPE_RESOURCE
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div className="exports">
                                    <button
                                        onClick={() =>
                                            exportData(TYPE_RESOURCE)
                                        }
                                    >
                                        {!isExporting.resource
                                            ? 'Export Data'
                                            : 'Exporting...'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Attendees */}
                    <h2>who bookmarked your attendees?</h2>
                    <div className="bookmark-container">
                        <div className="heading">
                            <Heading
                                count={attendees?.pagination?.total}
                                text="Visitors bookmarked your attendees"
                            />
                        </div>
                        {attendees?.bookmarks?.length > 0 && (
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Company</th>
                                            <th>Job Title</th>
                                            <th>Bookmark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendees?.bookmarks.map(
                                            ({ id, user, target }, index) => {
                                                return (
                                                    <tr key={id ? id : index}>
                                                        <td>
                                                            {user?.firstname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.lastname ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.email ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.company ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {user?.jobTitle ||
                                                                'NA'}
                                                        </td>
                                                        <td>
                                                            {`${target?.firstname} ${target?.lastname}` ||
                                                                'NA'}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                                {attendees?.pagination?.totalpage > 1 && (
                                    <div className="pagination-container">
                                        {/* <ReactPaginate
                                            disabledClassName
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={handlePageCount(
                                                TYPE_ATTENDEE
                                            )}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) =>
                                                handlePageChange(
                                                    data,
                                                    TYPE_ATTENDEE
                                                )
                                            }
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                        /> */}
                                        <Pagination
                                            total={handlePageCount(
                                                TYPE_ATTENDEE
                                            )}
                                            pageSize={bookmarksLimit}
                                            showSizeChanger={false}
                                            showQuickJumper={false}
                                            onChange={(page) =>
                                                handlePageChange(
                                                    page,
                                                    TYPE_ATTENDEE
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div className="exports">
                                    <button
                                        onClick={() =>
                                            exportData(TYPE_ATTENDEE)
                                        }
                                    >
                                        {!isExporting.attendee
                                            ? 'Export Data'
                                            : 'Exporting...'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
