import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Space, Input, Button, Dropdown, Menu } from 'antd';
import { SearchOutlined, MoreOutlined } from '@ant-design/icons';

import moment from 'moment';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../../../../../../../ComponentsV2/CommonComponents/MobileView/noDataComponent/NoDataComponent';

import './draftTable.scss';

const DraftTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickDuplicatePoll,
    onClickDeletePoll,
    sendNotification,
    setSorter,
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const sortDirections = ['descend', 'ascend', 'descend'];

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    useEffect(() => {
        const trimSearchText = searchText?.trim();
        search(trimSearchText);
    }, [searchText]);

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder="Search"
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        const searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (a, b, sorter) => {
        const { field, order } = sorter;

        setSorter({
            sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
            sortBy: field,
        });
    };

    const renderDropDownMenu = (item) => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <a onClick={() => onClickDuplicatePoll(item.id)}>
                        Duplicate
                    </a>
                </Menu.Item>
                <Menu.Item key="1">
                    <a onClick={() => onClickDeletePoll(item.id)}>Delete</a>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            sorter: true,
            sortDirections,
            ...getColumnSearchProps('question'),
        },
        {
            title: 'Created On',
            dataIndex: 'createdAt',
            sorter: true,
            sortDirections,
            render: (createdAt, item) => {
                if (!createdAt) return null;
                return (
                    <span>
                        {moment(createdAt)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] HH:mm')}
                    </span>
                );
            },
        },

        {
            title: 'Target',
            dataIndex: 'target',
            sorter: true,
            sortDirections,
            render: (target, item) => {
                return (
                    <span>
                        {item?.targetType === 'project'
                            ? 'All Livepages'
                            : target?.length}
                    </span>
                );
            },
        },

        {
            render: (item) => {
                return (
                    <div className="d-flex justify-content-end">
                        <button
                            className="myxp btn btn-sm btn-link"
                            style={{ minWidth: 90 }}
                            onClick={() => sendNotification('sendNow', item)}
                        >
                            Send now
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => sendNotification('schedule', item)}
                        >
                            Schedule
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-hidden md:tw-block"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/edit-poll/${item.id}`
                                )
                            }
                        >
                            Edit
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-flex md:tw-hidden"
                            onClick={() => onClickDuplicatePoll(item.id)}
                        >
                            Duplicate
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-flex md:tw-hidden text-danger"
                            onClick={() => onClickDeletePoll(item.id)}
                        >
                            Delete
                        </button>
                        <Dropdown
                            overlay={renderDropDownMenu(item)}
                            trigger={['click']}
                            className="tw-hidden sm:tw-block"
                        >
                            <MoreOutlined
                                onClick={(e) => e.preventDefault()}
                                style={{ fontSize: 19 }}
                                className="pt-2"
                            />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: (
            <GlobalLoading
                loadingText="Fetching data..."
                height={45}
                width={45}
            />
        ),
    };

    const renderPagination = (current, type, originalElement) => {
        return <a onClick={() => pagination(current)}>{originalElement}</a>;
    };

    return (
        <>
            {!loading && data?.polls.length ? (
                <Table
                    onChange={handleChange}
                    className="sponsors-company-table"
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.polls}
                    loading={globalLoadingComponent}
                    pagination={
                        data?.pagination?.totalPage > 1
                            ? {
                                  itemRender: renderPagination,
                                  pageSize: 10,
                                  total: data?.pagination?.total,
                                  current: data?.pagination?.currentPage,
                              }
                            : false
                    }
                    scroll={{ x: 767 }}
                />
            ) : (
                <NoDataComponent
                    loading={loading}
                    buttonText="Create poll"
                    description="No poll have been Created"
                    onClickHandler={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/notification/create-poll`
                        )
                    }
                />
            )}
        </>
    );
};

DraftTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    onClickDuplicateNotification: PropTypes.func,
    onClickDeleteNotification: PropTypes.func,
    sendNotification: PropTypes.func,
    loading: PropTypes.bool,
    setSorter: PropTypes.func,
};

DraftTable.defaultProps = {
    data: null,
    loading: false,
};

export default DraftTable;
