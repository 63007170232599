import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';

import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import Prompt from 'components/app/common/prompt';

import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import TimezoneSelector from 'components/app/common/timezoneSelector';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import { GetProducts } from 'apis/rest/products/GetProducts';
import { DeleteProduct } from 'apis/rest/products/DeleteProduct';
import CreatedTable from './tables/createdTable';
import Layout from '../../../../common/layout';

import './products.scss';
import PreviewModal from './previewModal';
import RadioGroupButtons from '../../../../../../ComponentsV2/CommonComponents/MobileView/radioGroup/RadioGroupButtons';

const { TabPane } = Tabs;
const options = [
    {
        label: 'Publish',
        value: 'publish',
    },
    {
        label: 'Drafts',
        value: 'draft',
    },
];
function Products() {
    const history = useHistory();
    const { projectId, companyId } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [productsData, setProductsData] = useState(null);
    const [tableCurrentPage, setTableCurrentPage] = useState(1);
    const [tableSearchText, setTableSearchText] = useState('');
    const [currentTab, setCurrentTab] = useState('publish');
    const [tabOptions, setTabOptions] = useState(options);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [previewModalData, setPreviewModalData] = useState(null);

    const [deleteItemId, setDeleteItemId] = useState('');

    const handleGetProducts = async (page = 1, searchTerm = '') => {
        setIsLoading(true);
        try {
            const response = await GetProducts(
                projectId,
                currentTab,
                '',
                page,
                searchTerm
            );
            if (response.status) {
                if (isEmpty(response.products)) {
                    if (response.pagination.currentPage > 1) {
                        setTableCurrentPage(1);
                    }
                }
                setProductsData(response);
            } else {
                openNotification('error', { message: response?.message });
            }
        } catch (error) {
            console.error('ERROR handleGetProducts', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onClickDeleteProduct = async (productId) => {
        try {
            const response = await DeleteProduct(projectId, productId);
            if (response.status) {
                handleGetProducts(
                    productsData?.products?.length === 1 ? 1 : tableCurrentPage,
                    tableSearchText
                );
                openNotification('success', {
                    message: 'product has been deleted.',
                });
            } else {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        } catch (error) {
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
            console.error('ERROR onClickDeleteProduct', error);
        } finally {
            setShowDeletePrompt(false);
            setDeleteItemId('');
        }
    };

    const handlePreDelete = (item) => {
        setShowDeletePrompt(true);
        setDeleteItemId(item.id);
    };

    // Close prompt for delete and duplicate
    const handleClosePrompt = (type) => {
        if (type === 'delete') {
            setShowDeletePrompt(false);
            setDeleteItemId('');
        }
    };

    const tabsMobileView = () => {
        const onChange3 = (e) => {
            setTableSearchText('');
            setTableCurrentPage(1);
            setCurrentTab(e.target.value);
        };
        return (
            <div className="tw-w-full tw-flex md:tw-hidden tw-flex-col tw-text-base">
                {/* Create Button  */}
                <div className="tw-flex tw-flex-col tw-w-full tw-mb-3 tw-items-start md:tw-hidden tw-py-3 tw-px-5">
                    <div className="tw-w-full tw-flex tw-justify-start">
                        <button
                            className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/products/create-product`
                                )
                            }
                        >
                            Create Product
                        </button>
                    </div>
                </div>
                <div className="tw-bg-white tw-p-4 tw-gap-3 tw-flex tw-flex-col tw-w-full md:tw-hidden">
                    {/* Radio  */}
                    <RadioGroupButtons
                        changeHandler={onChange3}
                        options={tabOptions || options}
                        currentValue={currentTab}
                    />
                    {/* Timezone  */}
                    <div className="tw-w-full">
                        <TimezoneSelector
                            selectStyle={{ width: '100%' }}
                            selectClassnames="selectMobileView"
                            wrapperClass="wrapperMobileView"
                        />
                    </div>
                </div>
                {/* tabs  */}
                <div className="tw-bg-white tw-rounded-sm tw-h-[75vh] tw-p-7 tw-px-0 tw-pt-0 tw-overflow-auto">
                    {isLoading && <GlobalLoading />}

                    {currentTab === 'publish' ? (
                        <CreatedTable
                            data={productsData}
                            loading={isLoading}
                            pagination={(page) => {
                                setTableSearchText(tableSearchText);
                                setTableCurrentPage(page);
                                handleGetProducts(page, tableSearchText);
                            }}
                            search={(searchText) => {
                                setTableCurrentPage(1);
                                setTableSearchText(searchText);
                                handleGetProducts(1, searchText);
                            }}
                            onClickDelete={handlePreDelete}
                            onClickPreview={(item) => {
                                setPreviewModalData(item);
                                setIsPreviewModalVisible(true);
                            }}
                        />
                    ) : (
                        <CreatedTable
                            data={productsData}
                            loading={isLoading}
                            pagination={(page) => {
                                setTableSearchText(tableSearchText);
                                setTableCurrentPage(page);
                                handleGetProducts(page, tableSearchText);
                            }}
                            search={(searchText) => {
                                setTableCurrentPage(1);
                                setTableSearchText(searchText);
                                handleGetProducts(1, searchText);
                            }}
                            onClickDelete={handlePreDelete}
                            onClickPreview={(item) => {
                                setPreviewModalData(item);
                                setIsPreviewModalVisible(true);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (!productsData) return;
        const temp = [
            {
                label: `${
                    !productsData?.pagination?.totalPublished
                        ? '0 Published'
                        : `${productsData?.pagination?.totalPublished} Published`
                }`,
                value: 'publish',
            },
            {
                label: `${
                    !productsData?.pagination?.totalDraft
                        ? '0 Drafts'
                        : `${productsData?.pagination?.totalDraft} Drafts`
                }`,
                value: 'draft',
            },
        ];
        setTabOptions(temp);
    }, [productsData]);

    useEffect(() => {
        handleGetProducts();
    }, [currentTab]);

    return (
        <Layout disableSubMenuWidth className="tw-p-0">
            <div className="container products">
                <div className="row">
                    <div className="col-6">
                        <h1 className="my-3 tw-hidden md:tw-block">Products</h1>
                    </div>
                    <div className="col-6 md:tw-flex tw-hidden">
                        <button
                            className="myxp btn btn-primary ml-auto my-3"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/products/create-product`
                                )
                            }
                        >
                            Create Product
                        </button>
                    </div>
                    <div className="col-12 md:tw-block tw-hidden">
                        <TimezoneSelector
                            selectStyle={{ width: 150, zIndex: 10, right: 20 }}
                        />

                        <Tabs
                            className="bg-white py-3 border border-radius products-tabs"
                            defaultActiveKey={currentTab}
                            activeKey={currentTab}
                            onChange={(tabKey) => {
                                setTableSearchText('');
                                setTableCurrentPage(1);
                                setCurrentTab(tabKey);
                            }}
                        >
                            <TabPane
                                tab={`${
                                    isUndefined(
                                        productsData?.pagination?.totalPublished
                                    )
                                        ? 0
                                        : productsData?.pagination
                                              ?.totalPublished
                                } Publish`}
                                key="publish"
                            >
                                {isLoading && <GlobalLoading />}
                                {currentTab === 'publish' && (
                                    <CreatedTable
                                        data={productsData}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setTableSearchText(tableSearchText);
                                            setTableCurrentPage(page);
                                            handleGetProducts(
                                                page,
                                                tableSearchText
                                            );
                                        }}
                                        search={(searchText) => {
                                            setTableCurrentPage(1);
                                            setTableSearchText(searchText);
                                            handleGetProducts(1, searchText);
                                        }}
                                        onClickDelete={handlePreDelete}
                                        onClickPreview={(item) => {
                                            setPreviewModalData(item);
                                            setIsPreviewModalVisible(true);
                                        }}
                                    />
                                )}
                            </TabPane>
                            <TabPane
                                tab={`${
                                    isUndefined(
                                        productsData?.pagination?.totalDraft
                                    )
                                        ? 0
                                        : productsData?.pagination?.totalDraft
                                } Drafts`}
                                key="draft"
                            >
                                {isLoading && <GlobalLoading />}
                                {currentTab === 'draft' && (
                                    <CreatedTable
                                        data={productsData}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setTableSearchText(tableSearchText);
                                            setTableCurrentPage(page);
                                            handleGetProducts(
                                                page,
                                                tableSearchText
                                            );
                                        }}
                                        search={(searchText) => {
                                            setTableCurrentPage(1);
                                            setTableSearchText(searchText);
                                            handleGetProducts(1, searchText);
                                        }}
                                        onClickDelete={handlePreDelete}
                                        onClickPreview={(item) => {
                                            setPreviewModalData(item);
                                            setIsPreviewModalVisible(true);
                                        }}
                                    />
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                    {tabsMobileView()}
                </div>
            </div>

            <Prompt
                show={showDeletePrompt}
                message="Are you sure you want to delete of this product?"
                buttons={['Cancel', 'Proceed to Delete']}
                onHide={() => handleClosePrompt('delete')}
                callback={() => onClickDeleteProduct(deleteItemId)}
            />
            <PreviewModal
                visible={isPreviewModalVisible}
                setVisible={setIsPreviewModalVisible}
                data={{
                    name: previewModalData?.name,
                    description: previewModalData?.description,
                    exhibitorCompanyProfileId:
                        previewModalData?.exhibitorCompanyProfileId,
                    topicTags: !isEmpty(previewModalData?.topicTags)
                        ? previewModalData?.topicTags.map((item) => item?.name)
                        : [],
                    productGalleryItems: !isEmpty(
                        previewModalData?.productGalleryItems
                    )
                        ? previewModalData?.productGalleryItems.map(
                              (item) => item?.url
                          )
                        : [],
                    resources: !isEmpty(previewModalData?.productResources)
                        ? orderBy(
                              previewModalData?.productResources,
                              ['order'],
                              ['asc']
                          )
                        : [],
                    custom1: previewModalData?.custom1,
                    custom2: previewModalData?.custom2,
                    custom3: previewModalData?.custom3,
                    custom4: previewModalData?.custom4,
                    custom5: previewModalData?.custom5,
                    enableCta: previewModalData?.enableCta,
                    ctaLabel: previewModalData?.ctaLabel,
                    ctaLink: previewModalData?.ctaLink,
                    ctaBackgroundColor: previewModalData?.ctaBackgroundColor,
                    ctaColor: previewModalData?.ctaColor,
                }}
            />
            <SidebarContainer>
                <MainMenu priActive="products" all />
            </SidebarContainer>
        </Layout>
    );
}

Products.propTypes = {};

export default Products;
