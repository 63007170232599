import * as ls from 'local-storage';
import { store } from 'redux/store';

export const upgradePlan = async (companyId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/companies/${companyId}/upgrade`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const establishChargbeeSession = async (companyId, redirectUrl) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/companies/${companyId}/chargebee-session`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ redirectUrl }),
        }
    );
    return await response.json();
};

export const getCheckoutPayload = async (companyId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/companies/${companyId}/chargebee-hosted-page`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const requestOTP = async (email) => {
    const auth = ls.get('auth');
    const user = store?.getState()?.user;
    if (user?.email === email) {
        email = '';
    }

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/accounts/otp`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: email ? JSON.stringify({ email }) : '',
        }
    );
    return await response.json();
};

export const verifyAccount = async (code) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/accounts/verify`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ code }),
        }
    );
    return await response.json();
};

// Widgets
export const getAllWidgets = async ({
    projectId,
    sortBy = 'title',
    sortOrder = 'asc',
    limit = 10,
    search = '',
    start = 0,
    exhibitorCompanyProfileId = null,
    short = '',
    getAll = '',
    type = 'widget',
    widgetType = '',
}) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${
            process.env.REACT_APP_MYXP_API_VIRTUAL
        }/v1/manager/widgets?projectId=${projectId}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}${
            search ? `&search=${search}` : ''
        }&start=${start}${
            exhibitorCompanyProfileId
                ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                : ''
        }${short ? `&short=${short}` : ''}${
            getAll ? `&getAll=${getAll}` : ''
        }&type=${type}${widgetType ? `&widgetType=${widgetType}` : ''}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
};

export const getWidget = async (projectId, showcaseId) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/${showcaseId}?projectId=${projectId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
};

export const createWidget = async (projectId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/create?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const updateSpecificWidget = async (
    projectId,
    showcaseId,
    blockId,
    payload
) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/update/${showcaseId}/content-block/${blockId}?projectId=${projectId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const deleteSpecificWidget = async (projectId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/${projectId}/delete-page`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const deleteWidget = async (projectId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/${projectId}/delete-page`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const updateCSP = async (projectId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/custom-domain/${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const getProjectCSP = async (projectId) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/csp/${projectId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
};

export const updateBrandingOption = async (projectId, payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/remove-watermark/${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export const updateWidgetDetails = async (projectId, showcaseId, payload) => {
    //
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/update-widget-details/${showcaseId}?projectId=${projectId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};

export async function clearCache(projectId, showcaseid) {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth?.access_token}`;

    const { response } = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${showcaseid}/clear-cache?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

// Shared Password

export const getPasswordOtp = async (projectId) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth?.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/shared-password/${projectId}/otp`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
};

export async function getSharedPassword(code,projectId) {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth?.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/shared-password/${projectId}/verify`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ code })
        }
    );
    return await response.json();
}