import React, { useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import AlertSimple from 'components/app/common/alertSimple';
import useScreenSize from 'components/app/utils/hooks/useScreenSize/useScreenSize';
import SponsorDropdownMenu from 'ComponentsV2/CommonComponents/MobileView/SponsorDropdownMenu';
import Header from '../header';

import './layout.scss';

function Layout({
    children,
    pagePayload,
    background,
    disableSubMenuWidth = false,
    disableMenuWidth = false,
    isProjListing = false,
    isCompListing = false,
    allowFixed = true,
    menus = [],
    setActiveMenu = null,
    activeMenu = '',
    projectOnly = false,
    header,
    sendRequestOtp,
    setSendRequestOtp,
    className = '',
    hideBreadcrumbs = false,
}) {
    const alert = useSelector((state) => state.alert);
    const screenType = useScreenSize();

    // Redux states for Sidebar
    const isMainMenuVisible = useSelector(
        (state) => state.menus.mainMenuVisible
    );
    const isSubMenuVisible = useSelector((state) => state.menus.subMenuVisible);
    const isMainMenuOpen = useSelector((state) => state.menus.mainMenuOpen);
    const isSubMenuOpen = useSelector((state) => state.menus.subMenuOpen);
    const isFXAllowed = useSelector((state) => state.layout.allowFX);

    // Component states
    const [distance, setDistance] = useState(0);

    useLayoutEffect(() => {
        // Both menus are not visible
        if (!isMainMenuVisible && !isSubMenuVisible) setDistance(0);

        // If main menu is visible but the sub is not
        if (isMainMenuVisible && !isSubMenuVisible) {
            isMainMenuOpen
                ? disableMenuWidth
                    ? setDistance(0)
                    : setDistance(166)
                : setDistance(80);
        }

        // If main menu is visible but the sub is not
        if (!isMainMenuVisible && isSubMenuVisible) {
            isSubMenuOpen ? setDistance(166) : setDistance(80);
        }

        // If both menus are visible
        if (isMainMenuVisible && isSubMenuVisible) {
            // If both main and sub are opened
            isMainMenuOpen && isSubMenuOpen && disableSubMenuWidth
                ? setDistance(166)
                : setDistance(340);
            // If main is opened but sub is collapsed
            isMainMenuOpen && !isSubMenuOpen && setDistance(190);
            // If main is collapsed and sub is opened
            !isMainMenuOpen && isSubMenuOpen && setDistance(260);
            // If both menus are collpased
            !isMainMenuOpen && !isSubMenuOpen && setDistance(110);
        }
    }, [isMainMenuOpen, isSubMenuOpen, isMainMenuVisible, isSubMenuVisible]);

    return (
        <div className="layout-main-wrapper">
            <AlertSimple alert={alert} />
            <div
                className="myxp layout layout--xpmanager d-flex flex-column"
                style={{ background: background || '' }}
            >
                <Header
                    pagePayload={pagePayload}
                    isProjListing={isProjListing}
                    isCompListing={isCompListing}
                    menus={menus}
                    setActiveMenu={setActiveMenu}
                    activeMenu={activeMenu}
                    projectOnly={projectOnly}
                    header={header}
                    sendRequestOtp={sendRequestOtp}
                    setSendRequestOtp={setSendRequestOtp}
                >
                    {' '}
                </Header>
                <div
                    style={{
                        marginLeft:
                            screenType === 'desktop' ? `${distance}px` : 0,
                    }}
                    className={`layout__body flex-grow-1 ${
                        allowFixed ? 'allow-fixed tw-mt-[55px]' : ''
                    } ${isFXAllowed && 'transFx'} ${className} md:tw-p-11`}
                >
                    {!hideBreadcrumbs && (
                        <div className="tw-px-7 tw-pt-8 md:tw-hidden">
                            <SponsorDropdownMenu />
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
}

Layout.propTypes = {
    pagePayload: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
        .isRequired,
};

export default Layout;
