import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Menu, Dropdown, Tabs } from 'antd';

import ArrowUpIcon from 'Apps/VenueBuilder/Icons/ArrowUpIcon';
import ArrowRightIcon from 'Apps/VenueBuilder/Icons/ArrowRightIcon';
import MoreDotsIcon from 'Apps/VenueBuilder/Icons/MoreDotsIcon';
import VisibilityControl from 'Apps/VenueBuilder/Components/Common/VisibilityControl';
import { getUserRole } from 'Apps/VenueBuilder/helpers/common';
import {
    deleteBlock,
    updateSidebarMenu,
    setActiveContent,
    createContentBlock,
} from 'Apps/VenueBuilder/Components/Layout/AppSideBar/actions';
import { useLocation } from 'react-router-dom';
import getIconByType from '../../../helpers/getIconByType';

import {
    contentBlocks,
    websiteBuilderContentBlocks,
} from '../../../helpers/contentBlocks';

import styles from './ContentLayout.module.css';

/**
 * ContentLayout Component
 *

 * @ContentLayout
 * @category Venue Builder
 * @subcategory Common
 * @description Block/Tags wrapper
 * @param {string} title A heading title
 * @param {function} onBack Editor text value
 * @param {function} onBack Editor text value
 * @param {boolean} enabledVisibilityControl enable visibility control
 * @param {boolean} enableSwap On drawer open or custom delete
 */

const { TabPane } = Tabs;

const ContentLayout = ({
    title,
    onBack,
    children,
    enabledVisibilityControl = false,
    enableSwap = true,
    itemData,
    onDeleteItem,
    customStyle = null,
    deleteType,
    isWidget,
    advancedContent,
}) => {
    const dispatch = useDispatch();

    const role = getUserRole();

    let preventDelete = false;
    let preventChangeType = !enableSwap;
    let enabledBlocks = null;
    let preventAdvancedSettings = false;

    if (role && itemData) {
        preventDelete = itemData?.preventDelete
            ? itemData?.preventDelete[role]
            : false;
        preventChangeType = itemData?.preventChangeType
            ? itemData?.preventChangeType[role]
            : false;
        preventAdvancedSettings =
            itemData.preventAdvancedSettings &&
            typeof itemData.preventAdvancedSettings[role] === 'boolean'
                ? itemData.preventAdvancedSettings[role]
                : false;
    }
    if (itemData && itemData?.enabledBlocks !== '*') {
        enabledBlocks = itemData.enabledBlocks;
    }

    // get blockid state
    const blockId = useSelector((state) => state?.sidebar?.item?.id);
    const showcaseId = useSelector(
        (state) => state?.header?.selection?.livepageId
    );
    const location = useLocation();
    const item = useSelector((state) => state?.sidebar?.item);
    const contentLayout = useSelector((state) => state?.sidebar?.contentLayout);
    const selection = useSelector((state) => state?.header?.selection);
    const activeApp = location?.pathname?.split('/')?.[5];
    const blockList =
        activeApp === 'website-builder'
            ? websiteBuilderContentBlocks
            : contentBlocks;

    const switchBlock = (item, data) => {
        if (data.type === 'zoom') {
            item.meetingType = 'zoom';
        }
        switch (data.action) {
            case 'MEETING_BLOCK':
                item.title = '';
                break;
            case 'SPEAKERS_BLOCK':
                if (item.type === 'Sponsor' && item.sortBy && item.sortOrder) {
                    item.sortBy = null;
                    item.sortOrder = null;
                }
                break;
        }
        const switchItem = {
            ...item,
            type: data.type,
            updateBlockType: {
                from: item.type,
                to: data.type,
            },
        };
        dispatch(updateSidebarMenu(data.action, switchItem));
    };
    const blocks = (
        <Menu className="blocks-menu-lists">
            {blockList.map((contents) => {
                return contents.data.map((block, idx) => {
                    if (enabledBlocks && !enabledBlocks.includes(block.type))
                        return null;
                    return (
                        <Menu.Item
                            key={`Menu_Swap_blocks_${idx}_${block?.type}`}
                            style={{
                                display:
                                    item?.type === block?.type ||
                                    item?.streamingService === block?.type
                                        ? block?.type === 'LiveStream' &&
                                          ![
                                              'vimeo',
                                              'youtube',
                                              'facebook',
                                              'msteams',
                                          ].includes(block?.type)
                                            ? 'block'
                                            : 'none'
                                        : 'block',
                                padding: '6px 0px 6px 30px',
                            }}
                            onClick={() =>
                                switchBlock(
                                    {
                                        ...item,
                                    },
                                    block
                                )
                            }
                        >
                            <span>{getIconByType(block.type, 'small')}</span>
                            <span>{block.name}</span>
                        </Menu.Item>
                    );
                });
            })}
        </Menu>
    );

    const DefaultMenu = () => (
        <Menu>
            {!preventChangeType && deleteType !== 'item' && (
                <Menu.Item>
                    <Dropdown
                        overlay={blocks}
                        overlayClassName="blocks"
                        placement="bottomRight"
                    >
                        <span>
                            Swap block type to <ArrowRightIcon />
                        </span>
                    </Dropdown>
                </Menu.Item>
            )}
            {!preventChangeType && deleteType !== 'item' && (
                <Menu.Item
                    onClick={() =>
                        dispatch(
                            createContentBlock(null, showcaseId, blockId, true)
                        )
                    }
                >
                    <span>Duplicate</span>
                </Menu.Item>
            )}
            {!preventDelete &&
                (deleteType === 'item' ? (
                    <Menu.Item onClick={() => onDeleteItem(itemData)}>
                        <span>Delete</span>
                    </Menu.Item>
                ) : (
                    <Menu.Item
                        onClick={() =>
                            dispatch(deleteBlock(blockId, showcaseId))
                        }
                    >
                        <span>Delete</span>
                    </Menu.Item>
                ))}
        </Menu>
    );

    return (
        <div
            className={`${styles.Container} content-layout `}
            style={customStyle}
        >
            {selection?.type !== 'widget' && (
                <div className={styles.Header}>
                    <div className={styles.Title}>
                        {selection?.type !== 'widget' && (
                            <span
                                onClick={() => {
                                    onBack();
                                    if (enabledVisibilityControl)
                                        dispatch(setActiveContent('content'));
                                }}
                            >
                                <ArrowUpIcon />
                            </span>
                        )}
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: `${title} ${
                                    selection?.type === 'widget'
                                        ? '- Widget'
                                        : ''
                                }`,
                            }}
                        />
                    </div>

                    {/* show this on edit for more settings */}
                    {blockId && (!preventChangeType || !preventDelete) && (
                        <Dropdown
                            trigger={['click']}
                            overlay={() => <DefaultMenu />}
                            placement="bottomRight"
                            overlayClassName={`${
                                deleteType === 'item' ? 'delete-item' : ''
                            } swap-block`}
                            overlayStyle={{
                                left: deleteType === 'item' ? 202 : 148,
                            }}
                        >
                            <div className={`${styles.MoreDots} DotsContainer`}>
                                <MoreDotsIcon />
                            </div>
                        </Dropdown>
                    )}
                </div>
            )}

            <div className={styles.Contents}>
                {!blockId ? (
                    children
                ) : !enabledVisibilityControl || preventAdvancedSettings ? (
                    children
                ) : (
                    <Tabs
                        centered
                        activeKey={!contentLayout ? 'content' : contentLayout}
                        className="contentlayout-tabs"
                        onChange={(e) => dispatch(setActiveContent(e))}
                    >
                        {isWidget ? (
                            <>
                                <TabPane tab="Content" key="content">
                                    {children}
                                </TabPane>
                                <TabPane tab="Advanced" key="advanced">
                                    {advancedContent}
                                </TabPane>
                                <TabPane tab="CSS" key="css">
                                    <VisibilityControl
                                        title={title}
                                        blockId={blockId}
                                        blockName={item?.name}
                                        blockData={item}
                                        showcaseId={showcaseId}
                                        isStarterPlan={selection?.isStarterPlan}
                                    />
                                </TabPane>
                            </>
                        ) : (
                            <>
                                <TabPane tab="Content" key="content">
                                    {children}
                                </TabPane>
                                <TabPane tab="Advanced" key="advanced">
                                    <VisibilityControl
                                        title={title}
                                        blockId={blockId}
                                        blockName={item?.name}
                                        blockData={item}
                                        showcaseId={showcaseId}
                                        isStarterPlan={selection?.isStarterPlan}
                                    />
                                </TabPane>
                            </>
                        )}
                    </Tabs>
                )}
            </div>

            <style>{`
                .ant-dropdown.swap-block {
                    left: 134px !important;
                }
                .ant-dropdown.swap-block.delete-item {
                    left: 202px !important;
                }
                .ant-dropdown.blocks {
                    left: 302px !important;
                    top: 77px !important;
                }
                .blocks-menu-lists {
                    padding: 22px 16px;
                }
                .blocks-menu-lists li {
                    position: relative;
                    padding: 6px 0px 6px 30px;
                    margin: 6px 0;
                }
                .blocks-menu-lists li span:nth-child(2) {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                }
                .blocks-menu-lists li span:first-child {
                    content: ' ';
                    position: absolute;
                    left: 5px;
                    background: #E1E7F9;
                    border-radius: 2px;
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .blocks-menu-lists li:hover {
                    background: none !important;
                }
                .contentlayout-tabs .ant-tabs-nav-list {
                    width: 100%;
                }
                .contentlayout-tabs .ant-tabs-nav-list .ant-tabs-tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    margin: 0;
                }
                .contentlayout-tabs .ant-tabs-tab {
                    background: #F1F6FA;
                }
                .contentlayout-tabs .ant-tabs-tab.ant-tabs-tab-active {
                    background: #fff;
                }
                .contentlayout-tabs .ant-tabs-tab-btn {
                    font-family: "Open Sans",sans-serif!important;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
                .contentlayout-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    font-weight: 600;
                }
                .contentlayout-tabs .ant-tabs-ink-bar {
                    background: #ffffff;
                }
                .contentlayout-tabs .ant-tabs-content {
                    display: flex;
                    flex-direction: column;
                    overflow: auto;
                    flex: 1 1;
                    height: 100%;
                }
                .contentlayout-tabs .ant-tabs-content::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                }
                .contentlayout-tabs .ant-tabs-content::-webkit-scrollbar-thumb {
                    background: none;
                }
                {/* .Contents::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                }
                .Contents::-webkit-scrollbar-thumb {
                    background: none;
                } */}
                .swap-block .ant-menu-item {
                    height: 29px;
                    line-height: 30px;
                    margin: 0 !important;
                    padding: 0px 24px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 900;
                    font-size: 12px;
                    letter-spacing: -0.02em;
                }
                .swap-block .ant-menu-item:hover {
                    background: #EFF3F6;
                    color: #000000;
                }
                .swap-block {
                    border: 1px solid #E2E4E7;
                    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
                    border-radius: 4px;
                    padding: 16px 0;
                    background: #fff;
                }
            `}</style>
        </div>
    );
};

export default ContentLayout;
