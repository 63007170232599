import React from 'react';

export default function DeleteIconSession() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_21337_180066)">
                <path
                    d="M16.875 4.6875H3.125"
                    stroke="#236DF2"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.125 8.125V13.125"
                    stroke="#236DF2"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.875 8.125V13.125"
                    stroke="#236DF2"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.625 4.6875V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.6875"
                    stroke="#236DF2"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.125 4.6875V2.8125C13.125 2.48098 12.9933 2.16304 12.7589 1.92862C12.5245 1.6942 12.2065 1.5625 11.875 1.5625H8.125C7.79348 1.5625 7.47554 1.6942 7.24112 1.92862C7.0067 2.16304 6.875 2.48098 6.875 2.8125V4.6875"
                    stroke="#236DF2"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_21337_180066">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
