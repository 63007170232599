import React from 'react';

export default function CrossLink() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 2L2 18M2 2L18 18"
                stroke="#344054"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
