/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { routes } from 'config/routes';

import { Helmet } from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as ls from 'local-storage';

import {
    faExclamationTriangle,
    faCheckCircle,
    faSignOutAlt,
    faTimes,
    faAsterisk,
    faUser,
    faInfoCircle,
    faAngleDown,
    faAngleUp,
    faQuestionCircle,
    faBuilding,
    faUserTie,
    faListAlt,
    faProjectDiagram,
    faLink,
    faCaretSquareDown,
    faCaretSquareUp,
    faCreditCard,
    faDownload,
    faHamburger,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faExternalLinkAlt,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faDesktop,
    faMobileAlt,
    faRedo,
    faPencilAlt,
    faPlusSquare,
    faArrowDown,
    faArrowUp,
    faListUl,
    faCloudUploadAlt,
    faSortDown,
    faPlus,
    faTrash,
    faEnvelope,
    faHistory,
    faThList,
} from '@fortawesome/free-solid-svg-icons';

import './app.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'apis/rest/User';
import * as act from 'redux/actions/common/userActions';
import { isEmpty } from 'lodash';
import Plans from 'ComponentsV2/CommonUtils/IdentifyPlan';
import uuid from 'react-uuid';
import Loader from './components/venue/Loader';
import PrivateRoute from './hocs/privateRoute';

const devUserGuidingScript = `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','583066982ID');`;
const prodUserGuidingScript = `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','172887472ID');`;

const { STARTER_PLAN } = Plans;
const freshChatBlacklistedCompanyIds = [
    'JXHr8aptocafE3y21MouMxlgb',
    'a86887ee-6714-4ded-b57f-add908725c8b',
];

function App(props) {
    library.add(
        faCheckCircle,
        faSignOutAlt,
        faExclamationTriangle,
        faTimes,
        faAsterisk,
        faUser,
        faCreditCard,
        faInfoCircle,
        faAngleDown,
        faAngleUp,
        faQuestionCircle,
        faBuilding,
        faUserTie,
        faListAlt,
        faProjectDiagram,
        faLink,
        faCaretSquareDown,
        faCaretSquareUp,
        faDownload,
        faHamburger,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faExternalLinkAlt,
        faAngleDoubleLeft,
        faAngleDoubleRight,
        faDesktop,
        faMobileAlt,
        faRedo,
        faPencilAlt,
        faPlusSquare,
        faArrowDown,
        faArrowUp,
        faListUl,
        faCloudUploadAlt,
        faSortDown,
        faPlus,
        faTrash,
        faEnvelope,
        faHistory,
        faThList
    );
    const domain = window.location.origin || '';
    const isExhibitor =
        domain.includes(process.env.REACT_APP_EXHIBITOR_DASHBOARD_DOMAIN) ||
        domain === process.env.REACT_APP_EXHIBITOR_DASHBOARD_DOMAIN;

    const { isDevEnvironment } = props;

    const preloadLinks = [
        '/assets/products/xpmanager/iconsv2/peoples-active.svg',
        '/assets/products/xpmanager/iconsv2/virtual-active.svg',
        '/assets/products/xpmanager/iconsv2/agenda-active.svg',
        '/assets/products/xpmanager/iconsv2/sponsorship-active.svg',
        '/assets/products/xpmanager/iconsv2/livemanager-active.svg',
        '/assets/products/xpmanager/iconsv2/livepages-active.svg',
        '/assets/products/xpmanager/iconsv2/analytics-active.svg',
        '/assets/products/xpmanager/iconsv2/channels-active.svg',
        '/assets/products/xpmanager/iconsv2/videoApp-active.svg',
        '/assets/products/xpmanager/iconsv2/meeting-active.svg',
    ];

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const isSponsor = !!ls.get('sponsorAuth') || isExhibitor;
    const project = ls.get('project');
    const companyDetails = useSelector((state) => state?.projects?.company);
    const isCompanyFreshChatEnabled =
        freshChatBlacklistedCompanyIds.indexOf(companyDetails?.id) === -1;
    const tagsArray = [
        Object.prototype.hasOwnProperty.call(companyDetails, 'package') &&
        companyDetails?.package?.id === STARTER_PLAN
            ? 'starter'
            : isSponsor
            ? 'booth'
            : 'gevmevirtual',
    ];
    const dispatch = useDispatch();

    const getUserData = async () => {
        try {
            const userSignUp = ls('userSignUp');
            const res = await getUser();
            if (userSignUp && !isEmpty(userSignUp)) {
                const updatedData = { ...res.user, ...userSignUp };
                dispatch(act.setUser({ ...res, user: updatedData }));
                setUser(updatedData);
            } else {
                dispatch(act.setUser(res));
                setUser(res);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {
        if (user && window?.userGuiding?.identify) {
            const data = window?.userGuiding?.identify(`${user?.id}`);
        }
    }, [user, window?.userGuiding?.identify]);

    useEffect(() => {
        if (!isCompanyFreshChatEnabled) {
            return;
        }

        function initFreshChat() {
            window.fcWidget.init({
                token: `${process.env.REACT_APP_FRESHCHAT_KEY}`,
                host: 'https://globalsignin.freshchat.com',
                tags: tagsArray,
            });

            const freshchatUserProperties = {
                email: user?.email,
                firstName: user?.firstname,
                lastName: user?.lastname,
                phone: user?.phoneNo,
                cf_gevme_external_id: user?.id,
                cf_gevme_restored_id: user?.id,
                cf_topics_tag: JSON.stringify(tagsArray),
                cf_project_name: project?.name,
                cf_project_id: project?.projectId,
                cf_company_id: project?.id,
            };

            window.fcWidget.on('widget:loaded', () => {
                window.fcWidget.user.setProperties(freshchatUserProperties);
            });
        }

        function initialize(i, t) {
            if (i.getElementById(t)) {
                initFreshChat();
                return;
            }

            const e = i.createElement('script');
            e.id = t;
            e.async = true;
            e.src = 'https://globalsignin.freshchat.com/js/widget.js';
            e.onload = initFreshChat;
            i.head.appendChild(e);
        }

        // Check if script already exists
        if (!document.getElementById('Freshchat-js-sdk')) {
            initialize(document, 'Freshchat-js-sdk');
        }

        return () => {
            const scriptElement = document.getElementById('Freshchat-js-sdk');
            if (scriptElement) {
                scriptElement.remove();
            }
            if (window.fcWidget) {
                window.fcWidget.destroy();
            }
        };
    }, [isCompanyFreshChatEnabled, user, tagsArray, project]);

    useEffect(() => {
        // Check if the current path starts with '/sponsor'
        if (window.location.pathname.startsWith('/sponsor')) {
            // Redirect to the root path ('/')
            window.location.href =
                process.env.REACT_APP_EXHIBITOR_DASHBOARD_DOMAIN;
        }
    }, []);

    if (window.location.pathname.startsWith('/sponsor')) return '';

    return (
        <Router>
            <div className="App">
                {!loading ? (
                    <>
                        <Helmet>
                            <title>
                                {isExhibitor
                                    ? 'Gevme Exhibitor Dashboard'
                                    : 'Gevme Admin'}
                            </title>
                            {preloadLinks.map((val, index) => (
                                <link
                                    key={index + val}
                                    rel="preload"
                                    href={val}
                                    as="image"
                                    type="image/svg+xml"
                                />
                            ))}
                            <script id="user-guide">
                                {isDevEnvironment
                                    ? devUserGuidingScript
                                    : prodUserGuidingScript}
                            </script>
                        </Helmet>
                        <Switch>
                            {routes.map((route) => {
                                const routeProps = {
                                    exact: route.isExact,
                                    path: route.path,
                                    component: route.component,
                                    requiredRole: route.requiredRole,
                                    user: user?.user || {},
                                    private: route?.isPrivate,
                                    isInStarterPlan:
                                        companyDetails?.package?.id ===
                                        STARTER_PLAN,
                                };

                                return route.isPrivate ? (
                                    <PrivateRoute
                                        key={route.component}
                                        {...routeProps}
                                    />
                                ) : (
                                    <Route
                                        key={route.component}
                                        {...routeProps}
                                    />
                                );
                            })}
                        </Switch>
                    </>
                ) : (
                    <div className="Skeleton-Loading">
                        <Loader text="Loading your dashboard..." />
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;
