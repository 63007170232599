import React from 'react';
import PropTypes from 'prop-types';

import './pollResult.scss';
import useScreenSize from 'components/app/utils/hooks/useScreenSize/useScreenSize';

const PollResult = ({ data }) => {
    const deviceType = useScreenSize();
    const calculatePercent = (votes = 0) => {
        let total = data?.voters.length || 0;
        if (votes === 0 && total === 0) {
            return '0%';
        }
        return `${((votes / total) * 100).toFixed(1)}%`;
    };
    return (
        <>
            {deviceType !== 'mobile' ? (
                <div className="poll-container">
                    {data?.options?.map((option, index) => {
                        return (
                            <div
                                className="poll-item border rounded my-2 "
                                key={'poll-option-' + index}
                            >
                                <div
                                    className="poll-background-fill"
                                    style={{
                                        width: calculatePercent(
                                            option?.voters.length
                                        ),
                                        backgroundColor: '#EDEDED',
                                    }}
                                />
                                <div className="poll-background-label w-100 px-2 d-flex justify-content-between">
                                    <span className="poll-radio-item">
                                        {option.label}
                                    </span>
                                    <span className="poll-background-label-percent">
                                        {calculatePercent(
                                            option?.voters.length
                                        )}{' '}
                                        ({option?.voters.length})
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="poll-container">
                    {data?.options?.map((option, index) => {
                        return (
                            <div
                                className="poll-item border tw-flex tw-h-[44px] tw-items-center rounded my-2 "
                                key={'poll-option-' + index}
                            >
                                <div
                                    className="poll-background-fill"
                                    style={{
                                        width: calculatePercent(
                                            option?.voters.length
                                        ),
                                        backgroundColor: '#EDEDED',
                                    }}
                                />
                                <div className="poll-background-label w-100 px-2 d-flex justify-content-between">
                                    <span className="poll-radio-item tw-text-base tw-font-normal tw-text-[#667085]">
                                        {option.label}
                                    </span>
                                    <span className="poll-background-label-percent tw-text-base tw-font-semibold tw-text-[#667085]">
                                        {calculatePercent(
                                            option?.voters.length
                                        )}{' '}
                                        ({option?.voters.length})
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

PollResult.propTypes = {
    data: PropTypes.object,
};

PollResult.defaultProps = {
    data: null,
};

export default PollResult;
