import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { List } from 'antd';

import speakerEndPoints from 'apis/rest/livestream/Speakers';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import BoxPreloader from 'components/app/common/preloader/boxPreloader';
import Prompt from 'components/app/common/prompt';
import * as ls from 'local-storage';
import './speakers.scss';
import isEmpty from 'lodash/isEmpty';
import SpeakerForm from './speakerForm';
import SpeakerEmpty from './speakerEmpty';

const { getSpeakersList, deleteSpeaker } = speakerEndPoints;
const SpeakerCard = ({
    firstname,
    lastname,
    profilePhoto,
    company,
    jobTitle,
    bio,
    onEdit,
    id,
    exhibitorCompanyProfileId,
    onDelete,
    // disableDelete,
    // disableEdit,
}) => {
    const { projectId, companyId } = useParams();

    let nameInitials = '';
    if (!profilePhoto || profilePhoto === 'null') {
        nameInitials = `${firstname} ${lastname}`
            ?.split('#')[0]
            ?.replace(/[^a-zA-Z- ]/g, '')
            ?.match(/\b\w/g)
            ?.join('')
            ?.toUpperCase();
    }

    const renderDeleteButton = () => {
        if (!isEmpty(ls.get('sponsorAuth'))) {
            if (companyId !== exhibitorCompanyProfileId) return null;
        }
        return <span onClick={() => onDelete(id)}>Delete</span>;
    };
    const renderEditButton = () => {
        if (!isEmpty(ls.get('sponsorAuth'))) {
            if (companyId !== exhibitorCompanyProfileId) return null;
        }
        return <span onClick={() => onEdit(id)}>Edit</span>;
    };

    return (
        <>
            <div className="speakerTitle">
                {profilePhoto && profilePhoto !== 'null' ? (
                    <img src={profilePhoto} />
                ) : (
                    <div className="noImage">{nameInitials}</div>
                )}

                <div className="ml15">
                    <span className="name"> {`${firstname} ${lastname}`}</span>
                    <br />
                    {jobTitle || company ? (
                        <span className="jobTitle">
                            {jobTitle && company
                                ? `${jobTitle}, ${company}`
                                : jobTitle || company}
                        </span>
                    ) : null}
                </div>
            </div>

            <div className="speakerEditBtns">
                {renderDeleteButton()}
                {renderEditButton()}
                {/* {!disableDelete && (
                    <span onClick={() => onDelete(id)}>Delete</span>
                )} */}
                {/* {!disableEdit && (
                    <span onClick={() => onEdit(id)}>Edit</span>
                )} */}
            </div>

            {bio ? (
                <p
                    className="biography"
                    dangerouslySetInnerHTML={{ __html: bio }}
                />
            ) : null}
        </>
    );
};

export default function Speakers({ search, setSearch, showForm, toggleForm, currentTabs }) {
    const totalSpeakersLength = useRef(0);

    const { projectId } = useParams();

    const [speakersData, setSpeakersData] = useState([]);
    const [speakersPagination, setSpeakersPagination] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [speakerId, setSpeakerId] = useState(''); //used in Form Editing

    const { showAlertBar } = useAlertBar();

    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    const handlePreDelete = (id) => {
        setSpeakerId(id);
        setShowDeletePrompt(true);
    };

    const handleClosePrompt = () => {
        setShowDeletePrompt(false);
        setSpeakerId('');
    };

    useEffect(() => {
        if (!showDeletePrompt && speakerId) toggleForm(true);
    }, [speakerId, showDeletePrompt]);

    useEffect(() => {
        totalSpeakersLength.current = speakersData.length;
    }, [speakersData]);

    const getSpeakers = async (search = '', pagination = 1) => {
        try {
            setIsLoading(true);
            const response = await getSpeakersList(
                projectId,
                pagination,
                search
            );
            if (response.status) {
                setSpeakersData(response.speakers);
                setSpeakersPagination(response.pagination);
            } else throw 'Error';
        } catch (e) {
            showAlertBar('Fetching Speakers Failed', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const removeSpeaker = async () => {
        try {
            const { status } = await deleteSpeaker(projectId, speakerId);
            if (status) {
                setSpeakersData(
                    speakersData.filter((data) => data.id !== speakerId)
                );
                getSpeakers();
                setSearch('');
                showAlertBar('Speaker Removed Successfully', 'success');
                setShowDeletePrompt(false);
                setSpeakerId('');
                toggleForm(false);
            } else throw 'Error';
        } catch (e) {
            showAlertBar('Failed to Delete Speaker', 'error');
        }
    };

    useEffect(() => {
        if (currentTabs === 'speakers') {
            getSpeakers(search);
        }
    }, [search]);

    useEffect(() => {
        if (showForm) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }, [showForm]);

    const renderPagination = (current, type, originalElement) => {
        return (
            <a
                onClick={() => {
                    if (!current) return null;
                    getSpeakers('', current);
                }}
            >
                {originalElement}
            </a>
        );
    };

    if (isLoading) {
        return <BoxPreloader noOfBoxes={5} hasTitle={false} />;
    }

    return (
        <div
            id="speaker-container"
            className="speakerContainer tw-p-4 tw-pt-0 tw-bg-white sm:tw-bg-transparent tw-mt-0 md:tw-pt-7"
        >
            {isEmpty(speakersData) ? (
                <SpeakerEmpty />
            ) : (
                <List
                    itemLayout="horizontal"
                    dataSource={speakersData}
                    renderItem={(item, index) => (
                        <List.Item>
                            <div
                                key={`speaker${index}`}
                                className="speakerCard"
                            >
                                <SpeakerCard
                                    // disableDelete={!isEmpty(ls.get('sponsorAuth')) && item?.exhibitorCompanyProfileId !== companyId }
                                    // // disableEdit={!!ls.get('sponsorAuth')}
                                    onEdit={setSpeakerId}
                                    onDelete={handlePreDelete}
                                    {...item}
                                />
                            </div>
                        </List.Item>
                    )}
                    pagination={{
                        itemRender: renderPagination,
                        pageSize: 10,
                        total: speakersPagination?.total,
                        current: speakersPagination?.currentPage,
                        position: 'bottom',
                        align: 'start',
                    }}
                />
            )}
            {/* <SpeakersList
                setSpeakerId={setSpeakerId}
                speakersData={speakersData}
                handlePreDelete={handlePreDelete}
            /> */}

            <SpeakerForm
                showBlanket
                zIndex={999}
                setSpeakerId={setSpeakerId}
                speakerId={speakerId}
                showAlertBar={showAlertBar}
                getSpeakers={getSpeakers}
                showForm={showForm}
                toggleForm={toggleForm}
            />

            <Prompt
                show={showDeletePrompt}
                message="Are you sure you want to delete this speaker?"
                buttons={['Cancel', 'Proceed to Delete']}
                onHide={() => handleClosePrompt()}
                callback={removeSpeaker}
            />
        </div>
    );
}
