import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Tooltip } from 'antd';

import * as ls from 'local-storage';
import Overview from 'ComponentsV2/ProjectDashboard/Components/analytics/overview';
import Layout from '../../../../common/layout';
import SidebarContainer from '../../../../common/globalBar/sidebarContainer';
import MainMenu from '../../../../components/admin/menus/mainMenu';
import Visits from './visits';
import ContentTracking from './content';
import MediaTracking from './media';
import PageViewsTracking from './pageViews';
import Chat from './chat';
import Logins from './logins';
// import Polls from './polls';
import Bookmarks from './bookmarks';
import './analytics.scss';
import { getAnalyticsToken } from './api';
import BoothOverview from './overview/boothOverview';

const analyticsAuth = 'analyticsAuth';
const { Option } = Select;

function Analytics(props) {
    const { match } = props;
    const { projectId } = match.params;
    const history = useHistory();
    const location = useLocation();
    const activeTab = location?.hash?.slice(1);
    const enabledVenue = useSelector(
        (state) => state?.venuesettings?.enableVenue
    );
    const isSponsor = !!ls.get('sponsorIdentifier');

    // -- start tabs --
    const tabs = [];
    if (isSponsor && enabledVenue)
        tabs.push({
            type: 'Booth Overview',
            tooltip: 'Booth traffic dashboard',
        });

    enabledVenue &&
        !isSponsor &&
        tabs.push({ type: 'Overview', tooltip: 'Event traffic dashboard' });

    if (!isSponsor) {
        tabs.push({
            type: 'Attendance',
            tooltip: 'Event Attendance Dashboard',
        });
    }

    tabs.push({
        type: 'Media',
        tooltip: 'The number of times a visitor played a media.',
    });

    enabledVenue &&
        !isSponsor &&
        tabs.push({
            type: 'Visits',
            tooltip:
                'A visit is a browsing session by a visitor where they view pages, watch media and interact with content.',
        });

    if (enabledVenue) {
        tabs.push({
            type: 'Content',
            tooltip:
                'The number of times a content block was interacted with (eg, a ‘click’ on a banner or PDF).',
        });
        tabs.push({
            type: 'Page Views',
            tooltip: 'The number of times visitors land on a page.',
        });
    }

    tabs.push({ type: 'Chat', tooltip: null });
    tabs.push({ type: 'Bookmarks', tooltip: null });

    const [selTabVal, goToTab] = useState(activeTab || tabs[0].type);
    // -- end tabs --

    const [isAnalyticsAuthSet, setAnalyticsAuth] = useState(
        isSponsor
            ? !!ls.get(analyticsAuth)?.[window?.SPONSOR_AUTH?.companyId]
            : !!ls.get(analyticsAuth)
    );

    const dispatch = useDispatch();

    const fetchAnalyticsToken = async () => {
        try {
            const { status, accessToken, message } = await getAnalyticsToken();
            if (status) {
                if (window?.SPONSOR_AUTH?.companyId) {
                    const analyticsAuthValue = ls.get(analyticsAuth) || {};

                    ls.set(analyticsAuth, {
                        ...analyticsAuthValue,
                        [window?.SPONSOR_AUTH?.companyId]: { accessToken },
                    });
                } else {
                    ls.set(analyticsAuth, { accessToken });
                }
                setAnalyticsAuth(true);
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTabChange = (type) => {
        goToTab(type);
        if (type !== 'Attendance')
            dispatch({
                type: 'SET_ACTIVE_MENU',
                payload: { activeMenu: 'absolute' },
            });

        history.push(`#${type}`);
    };

    const renderOptions = () => {
        if (tabs?.length) {
            return tabs.map(({ type, tooltip }, index) => (
                <Option key={type} value={type}>
                    <span>
                        {tooltip ? (
                            <Tooltip placement="top" title={tooltip}>
                                {type}
                            </Tooltip>
                        ) : (
                            type
                        )}
                    </span>
                </Option>
            ));
        }
    };
    const renderMobileView = () => {
        return (
            <div className="tw-w-full tw-flex tw-flex-col md:tw-hidden tw-mb-2">
                {/* Dates  */}
                <div className="tw-w-full tw-mt-2">
                    <Select
                        showSearch
                        className="selectMobileView"
                        allowClear={false}
                        value={selTabVal}
                        onChange={handleTabChange}
                        style={{
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#101828',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {renderOptions()}
                    </Select>
                </div>
                <style>{`
                @media screen and (max-width: 780px) {
            .selectMobileView .ant-select-selection-item {
                display: flex !important;
                align-items: center !important;
            }
            .selectMobileView .ant-select-selector {
                border-radius: 9px !important;
                height: 44px !important;
            }
        }
            `}</style>
            </div>
        );
    };

    useEffect(() => {
        if (enabledVenue && !isAnalyticsAuthSet) {
            fetchAnalyticsToken();
        }
    }, [enabledVenue]);

    useEffect(() => {
        return () => dispatch({ type: 'RESET_DEFAULT' });
    }, []);

    return (
        <Layout className="tw-p-0">
            <div className="analyticsHead md:tw-block tw-hidden">
                <span className="title">Analytics</span>
                {tabs.map(({ type, tooltip }, index) => (
                    <span
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => handleTabChange(type)}
                        className={type == selTabVal ? 'activeTab' : 'subTitle'}
                    >
                        {tooltip ? (
                            <Tooltip placement="top" title={tooltip}>
                                {type}
                            </Tooltip>
                        ) : (
                            type
                        )}
                    </span>
                ))}
            </div>
            <div className="tw-bg-white tw-p-7 md:tw-bg-transparent tw-h-[90vh] tw-overflow-auto sm:tw-h-auto ">
                {renderMobileView()}
                {selTabVal === 'Booth Overview' && <BoothOverview />}
                {selTabVal === 'Overview' && <Overview />}
                {selTabVal === 'Visits' && (
                    <Visits
                        isAnalyticsAuthSet={isAnalyticsAuthSet}
                        projectId={projectId}
                        enabledVenue={enabledVenue}
                    />
                )}
                {selTabVal === 'Content' && (
                    <ContentTracking
                        projectId={projectId}
                        enabledVenue={enabledVenue}
                    />
                )}
                {selTabVal === 'Media' && (
                    <MediaTracking
                        projectId={projectId}
                        enabledVenue={enabledVenue}
                    />
                )}
                {selTabVal === 'Attendance' && <Logins projectId={projectId} />}
                {selTabVal === 'Page Views' && (
                    <PageViewsTracking
                        projectId={projectId}
                        enabledVenue={enabledVenue}
                    />
                )}
                {selTabVal === 'Chat' && (
                    <Chat projectId={projectId} enabledVenue={enabledVenue} />
                )}
                {/* {selTabVal === 'Polls' && (
                <Polls projectId={projectId} enabledVenue={enabledVenue} />
            )} */}
                {selTabVal === 'Bookmarks' && (
                    <Bookmarks
                        projectId={projectId}
                        enabledVenue={enabledVenue}
                    />
                )}
            </div>
            <SidebarContainer>
                <MainMenu priActive="analytics" all />
            </SidebarContainer>
        </Layout>
    );
}

export default withRouter(Analytics);
