import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DatePicker,
    Modal,
    Select,
    Button,
    Tooltip,
    Menu,
    Dropdown,
    Space,
} from 'antd';
import moment from 'moment';
import RadioGroupButtons from 'ComponentsV2/CommonComponents/MobileView/radioGroup/RadioGroupButtons';
import useIsMobile from 'hooks/useIsMobile';

import './index.css';

const { Option } = Select;

const DateTimeRangePicker = ({
    showTime = false,
    showRelativePicker = false,
    showLive = false,
}) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const { startDate, endDate, activeMenu } = useSelector(
        (state) => state?.venueAnalytics
    );
    const [relativeTimeUnit, setRelativeTimeUnit] = useState('hour');
    const [relativeTimeValue, setRelativeTimeValue] = useState(1);
    const [currentTab, setCurrentTab] = useState(activeMenu);
    const [showRelativeDropdown, setShowRelativeDropdown] = useState(false);

    const options = [
        {
            label: 'Live',
            value: 'live',
            disabled: !showLive,
        },
        {
            label: 'Absolute',
            value: 'absolute',
            disabled: !showTime,
        },
        {
            label: 'Relative',
            value: 'relative',
            disabled: !showRelativePicker,
        },
    ];
    const handleRelativeOk = () => {
        if (relativeTimeValue && relativeTimeUnit) {
            const start = moment()
                .subtract(relativeTimeValue, relativeTimeUnit)
                .format('YYYY-MM-DDTHH:mm:ss');
            const end = moment().format('YYYY-MM-DDTHH:mm:ss');
            dispatch({
                type: 'SET_DATE',
                payload: { startDate: start, endDate: end },
            });
            setShowRelativeDropdown(false);
        }
    };
    const handleRelativeCancel = () => {
        dispatch({
            type: 'SET_ACTIVE_MENU',
            payload: { activeMenu: 'absolute' },
        });

        setShowRelativeDropdown(false);
    };

    const handleChooseRelativeTime = () => {
        dispatch({
            type: 'SET_ACTIVE_MENU',
            payload: { activeMenu: 'relative' },
        });
        setShowRelativeDropdown(true);
    };

    const handleChooseAbsoluteTime = () => {
        dispatch({
            type: 'SET_ACTIVE_MENU',
            payload: { activeMenu: 'absolute' },
        });
        setShowRelativeDropdown(false);
    };

    const handleChooseLive = () => {
        dispatch({ type: 'SET_ACTIVE_MENU', payload: { activeMenu: 'live' } });
        setShowRelativeDropdown(false);

        const start = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const end = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        dispatch({
            type: 'SET_DATE',
            payload: { startDate: start, endDate: end },
        });
    };
    const menu = (
        <Menu>
            <div className="relative-picker-dropdown">
                <p className="caption">Select Relative Time</p>
                <div className="d-flex align-item-center justify-content-between">
                    <input
                        type="number"
                        min="1"
                        className="relative-time-input"
                        defaultValue={1}
                        onChange={(e) => setRelativeTimeValue(e.target.value)}
                    />

                    <Select
                        style={{
                            width: 90,
                            fontSize: 12,
                            fontFamily: 'Open Sans',
                            fontWeight: 700,
                        }}
                        size="middle"
                        defaultValue="hours"
                        onChange={(value) => setRelativeTimeUnit(value)}
                    >
                        <Option value="hours">Hours</Option>
                        <Option value="days">Days</Option>
                        <Option value="weeks">Weeks</Option>
                        <Option value="months">Months</Option>
                    </Select>
                </div>

                <div className="d-flex justify-content-end mt-3">
                    <Space>
                        <Button
                            style={{ fontWeight: 600 }}
                            className="bg-light text-secondary border-0 fw-bold"
                            onClick={handleRelativeCancel}
                            type="secondary"
                            size="small"
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ fontWeight: 600 }}
                            type="primary"
                            onClick={handleRelativeOk}
                            size="small"
                        >
                            Ok
                        </Button>
                    </Space>
                </div>
            </div>
        </Menu>
    );
    const mobileView = () => {
        const onChange3 = (e) => {
            const { value } = e.target;
            setCurrentTab(value);
            switch (value) {
                case 'live':
                    handleChooseLive();
                    break;
                case 'absolute':
                    handleChooseAbsoluteTime();
                    break;
                case 'relative':
                    handleChooseRelativeTime();
                    break;
            }
        };
        return (
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4 md:tw-hidden">
                <RadioGroupButtons
                    changeHandler={onChange3}
                    options={options}
                    currentValue={currentTab}
                />
                <Modal
                    visible={showRelativeDropdown}
                    className="relative_modal md:tw-hidden"
                    footer={null}
                    closable={false}
                    centered
                    style={{ width: 'fit-content' }}
                    styles={{ padding: 0 }}
                    width="fit-content"
                >
                    {menu}
                </Modal>
                <style>{`
                .relative_modal .ant-menu{
                    padding: 0;
                }
                label[class*="ant-radio-button-wrapper"] {
                    width: ${100 / options.length}%;
                }
                .relative_modal .ant-modal-body{
                    display: flex;
                    justify-content: center;
                }
                `}</style>
                {activeMenu === 'absolute' && (
                    <DatePicker.RangePicker
                        showTime={!!showTime}
                        defaultValue={[moment(startDate), moment(endDate)]}
                        style={{
                            width: '100%',
                            height: '44px',
                            borderRadius: '8px',
                        }}
                        onChange={(dates) => {
                            let [start, end] = dates;
                            start = start.format('YYYY-MM-DDTHH:mm:ss');

                            end = end.format('YYYY-MM-DDTHH:mm:ss');

                            dispatch({
                                type: 'SET_DATE',
                                payload: { startDate: start, endDate: end },
                            });
                        }}
                        allowClear={false}
                        format={
                            showTime ? 'YYYY-MM-DD (HH:mm:ss)' : 'YYYY-MM-DD'
                        }
                    />
                )}
                {activeMenu === 'relative' && (
                    <Button
                        style={{
                            width: '100%',
                            height: '44px',
                            borderRadius: '8px',
                        }}
                    >
                        Showing last {relativeTimeValue}-{relativeTimeUnit}
                        &#40;s&#41; data
                    </Button>
                )}

                {activeMenu === 'live' && (
                    <Button
                        style={{
                            width: '100%',
                            height: '44px',
                            borderRadius: '8px',
                        }}
                    >
                        Showing latest data of the day
                    </Button>
                )}
            </div>
        );
    };
    return (
        <>
            <div className="visitorDate tw-hidden md:tw-flex tw-justify-end tw-items-center tw-shrink tw-gap-2">
                {showLive && (
                    <Button
                        className="mx-1"
                        onClick={handleChooseLive}
                        type={`${activeMenu === 'live' && 'primary'}`}
                    >
                        Live
                    </Button>
                )}
                <Tooltip title="Select the datetime range">
                    <Button
                        className=""
                        onClick={handleChooseAbsoluteTime}
                        type={`${activeMenu === 'absolute' && 'primary'}`}
                    >
                        Absolute
                    </Button>
                </Tooltip>
                {showRelativePicker && (
                    <>
                        <Tooltip title="Select the time period elapsed">
                            <Dropdown
                                className="mx-1"
                                overlay={menu}
                                placement="bottomCenter"
                                trigger={['click']}
                                visible={showRelativeDropdown}
                            >
                                <Button
                                    className="mx-1"
                                    onClick={handleChooseRelativeTime}
                                    type={`${
                                        activeMenu === 'relative' && 'primary'
                                    }`}
                                >
                                    Relative
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </>
                )}
                {activeMenu === 'absolute' && (
                    <DatePicker.RangePicker
                        showTime={!!showTime}
                        defaultValue={[moment(startDate), moment(endDate)]}
                        style={{ width: '360px' }}
                        onChange={(dates) => {
                            let [start, end] = dates;
                            start = start.format('YYYY-MM-DDTHH:mm:ss');

                            end = end.format('YYYY-MM-DDTHH:mm:ss');

                            dispatch({
                                type: 'SET_DATE',
                                payload: { startDate: start, endDate: end },
                            });
                        }}
                        allowClear={false}
                        format={
                            showTime ? 'YYYY-MM-DD (HH:mm:ss)' : 'YYYY-MM-DD'
                        }
                    />
                )}
                {activeMenu === 'relative' && (
                    <Button style={{ width: 'auto' }}>
                        Showing last {relativeTimeValue}-{relativeTimeUnit}
                        &#40;s&#41; data
                    </Button>
                )}

                {activeMenu === 'live' && (
                    <Button style={{ width: 'auto' }}>
                        Showing latest data of the day
                    </Button>
                )}
            </div>
            {isMobile && mobileView()}
        </>
    );
};

export default DateTimeRangePicker;
