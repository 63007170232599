import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { produce } from 'immer';
import arrayMove from 'array-move';
import useInputChange from 'components/app/utils/hooks/useInputChange';

import './socialMediaLinks.scss';
import { Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
const { Option } = Select;
const SortableContainer = sortableContainer(({ children }) => {
    return <tbody className="SortableList ">{children}</tbody>;
});

const SortableContainerMobileView = sortableContainer(({ children }) => {
    return <div className="tw-flex tw-flex-col tw-gap-4 ">{children}</div>;
});

const SortableItem = sortableElement(
    ({
        value,
        sortIndex,
        handleInputChange,
        renderFieldError,
        deleteSocialMediaLink,
    }) => {
        const antdChange = (value, option, event) => {
            const eventObject = {
                target: {
                    value,
                    name: `socialMediaLinks[${sortIndex}].type`,
                    type: 'select-one',
                    getAttribute: () => {},
                },
            };
            handleInputChange(eventObject);
        };
        return (
            <tr>
                <td>
                    <Select
                    name={`socialMediaLinks[${sortIndex}].type`}
                    className="fa"
                    onChange={antdChange}
                    value={value.type}
                >
                    <Option className="fa" value="facebook">
                        &#xf082; Facebook
                    </Option>
                    <Option className="fa" value="linkedin">
                        &#xf08c; LinkedIn
                    </Option>
                    <Option className="fa" value="instagram">
                        &#xf16d; Instagram
                    </Option>
                    <Option className="fa" value="wechat">
                        &#xf1d7; WeChat
                    </Option>
                    <Option className="fa" value="youtube">
                        &#xf167; YouTube
                    </Option>
                    <Option className="fa" value="website">
                    <GlobalOutlined /> WebSite
                    </Option>
                </Select>
                </td>
                <td>
                    <input
                        className="form-control form-control-sm mb-0"
                        onChange={handleInputChange}
                        type="text"
                        value={value.url}
                        name={`socialMediaLinks[${sortIndex}].url`}
                        placeholder="Link"
                    />
                </td>
                <td>
                    <input
                        className="form-control form-control-sm mb-0"
                        onChange={handleInputChange}
                        type="text"
                        value={value.label}
                        name={`socialMediaLinks[${sortIndex}].label`}
                        placeholder="Label"
                    />
                </td>
                <td>
                    <img
                        src="/assets/icon_trash.svg"
                        alt=""
                        onClick={() => deleteSocialMediaLink(sortIndex)}
                    />
                    <img
                        className="tw-ml-6"
                        src="/assets/icon_draggable.svg"
                        alt=""
                    />
                </td>
            </tr>
        );
    }
);
const SortableItemMobileView = sortableElement(
    ({
        value,
        sortIndex,
        handleInputChange,
        renderFieldError,
        deleteSocialMediaLink,
    }) => {
        const antdChange = (value, option, event) => {
            const eventObject = {
                target: {
                    value,
                    name: `socialMediaLinks[${sortIndex}].type`,
                    type: 'select-one',
                    getAttribute: () => {},
                },
            };
            handleInputChange(eventObject);
        };
        return (
            <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-border tw-border-border-gray-light tw-bg-white tw-rounded-md tw-border-solid">
                <Select
                    name={`socialMediaLinks[${sortIndex}].type`}
                    className="fa"
                    onChange={antdChange}
                    value={value.type}
                >
                    <Option className="fa" value="facebook">
                        &#xf082; Facebook
                    </Option>
                    <Option className="fa" value="linkedin">
                        &#xf08c; LinkedIn
                    </Option>
                    <Option className="fa" value="instagram">
                        &#xf16d; Instagram
                    </Option>
                    <Option className="fa" value="wechat">
                        &#xf1d7; WeChat
                    </Option>
                    <Option className="fa" value="youtube">
                        &#xf167; YouTube
                    </Option>
                    <Option className="fa" value="website">
                    <GlobalOutlined /> WebSite
                    </Option>
                </Select>
                <input
                    className="form-control form-control-sm mb-0 tw-border-border-gray-light"
                    onChange={handleInputChange}
                    type="text"
                    value={value.url}
                    name={`socialMediaLinks[${sortIndex}].url`}
                    placeholder="Link"
                />
                <input
                    className="form-control form-control-sm mb-0 tw-border-border-gray-light"
                    onChange={handleInputChange}
                    type="text"
                    value={value.label}
                    name={`socialMediaLinks[${sortIndex}].label`}
                    placeholder="Label"
                />
                {value.label && value.url && (
                    <span
                        className="tw-text-mobileTextBlue tw-text-sm tw-cursor-pointer tw-text-left"
                        onClick={() => deleteSocialMediaLink(sortIndex)}
                    >
                        Delete
                    </span>
                )}
            </div>
        );
    }
);
const SocialMediaLinks = ({ data, onChangeData }) => {
    const [input, setInput] = useState({
        socialMediaLinks: data?.socialMediaLinks ? data?.socialMediaLinks : [],
    });

    const { handleInputChange } = useInputChange(input, setInput);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const rearrangedCustomMenu = arrayMove(
            input.socialMediaLinks,
            oldIndex,
            newIndex
        );
        const nextState = produce(input, (draftState) => {
            draftState.socialMediaLinks = rearrangedCustomMenu;
        });
        setInput(nextState);
    };

    const addSocialMediaLink = () => {
        const nextState = produce(input, (draftState) => {
            if (!input.socialMediaLinks) {
                draftState.socialMediaLinks = [];
            }

            draftState.socialMediaLinks.push({
                type: 'facebook',
                url: '',
                label: '',
            });
        });
        setInput(nextState);
    };

    const deleteSocialMediaLink = (selectedMenuIndex) => {
        const isDeleteConfirmed = window.confirm(
            'Are you sure you want to delete this?'
        );

        if (isDeleteConfirmed) {
            const nextState = produce(input, (draftState) => {
                draftState.socialMediaLinks.splice(selectedMenuIndex, 1);
            });
            setInput(nextState);
        }
    };

    useEffect(() => {
        let updatedData = { ...data };
        updatedData.socialMediaLinks = input['socialMediaLinks'];

        onChangeData(updatedData);
    }, [input]);

    return (
        <div className="w-100 widget-menu ">
            <table className="table table-general md:tw-table tw-hidden">
                <thead>
                    <tr>
                        <th>Links</th>
                        <th>URL</th>
                        <th>Label</th>
                        <th></th>
                    </tr>
                </thead>
                <SortableContainer onSortEnd={onSortEnd} pressDelay={200}>
                    {!isEmpty(input.socialMediaLinks) &&
                        input.socialMediaLinks.map((socialMediaItem, index) => {
                            return (
                                <SortableItem
                                    key={index}
                                    index={index}
                                    value={socialMediaItem}
                                    sortIndex={index}
                                    handleInputChange={handleInputChange}
                                    renderFieldError={null}
                                    deleteSocialMediaLink={
                                        deleteSocialMediaLink
                                    }
                                />
                            );
                        })}
                </SortableContainer>
            </table>
            <div className="tw-flex md:tw-hidden tw-flex-col tw-gap-3">
                <label className="-tw-mb-1"> Links</label>
                <SortableContainerMobileView
                    onSortEnd={onSortEnd}
                    pressDelay={200}
                >
                    {!isEmpty(input.socialMediaLinks) &&
                        input.socialMediaLinks.map((socialMediaItem, index) => {
                            return (
                                <SortableItemMobileView
                                    key={index}
                                    index={index}
                                    value={socialMediaItem}
                                    sortIndex={index}
                                    handleInputChange={handleInputChange}
                                    renderFieldError={null}
                                    deleteSocialMediaLink={
                                        deleteSocialMediaLink
                                    }
                                />
                            );
                        })}
                </SortableContainerMobileView>
            </div>
            <div className="tw-w-full tw-flex tw-justify-start tw-mt-4 md:tw-mt-0 md:tw-block">
                <button
                    className="btn btn-primary btn-block btn-xs tw-w-fit md:tw-block"
                    onClick={addSocialMediaLink}
                >
                    + Add Links
                </button>
            </div>
        </div>
    );
};

SocialMediaLinks.propTypes = {
    data: PropTypes.object,
    onChangeData: PropTypes.func,
};

SocialMediaLinks.defaultProps = {
    data: null,
};

export default SocialMediaLinks;
