import React, { useState, useEffect, memo } from 'react';
import {
    Layout,
    Collapse,
    Select,
    Spin,
    notification,
    Radio,
    DatePicker,
    TimePicker,
    InputNumber,
    Input,
} from 'antd';
import * as ls from 'local-storage';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import moment from 'moment';

import './index.scss';
import { useSelector } from 'react-redux';
import previewWindow from '../../announcements/createAnnouncement/previewWindow';

const { Sider } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const LivePageSearch = ({
    livePagesList = [],
    setLivePages,
    livePages = [],
    previewMode = false,
    isLivePageSelectRequired,
    setLivePageSelectRequired,
}) => {
    const [srchdLivePages, setSrchdLivePages] = useState(null);
    const { projectId } = useParams();

    const [srchVal, setSrchVal] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (srchVal) {
            handleSearch();
        } else setSrchdLivePages(null);
    }, [srchVal]);

    const handleSearch = debounce(async () => {
        try {
            setLoading(true);
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                0,
                srchVal,
                [],
                '',
                1
            );
            if (!showcases || showcases.length === 0) throw 'failed';

            const showcaseList = [];
            showcases.map((item) => {
                showcaseList.push({
                    value: item.title,
                    key: item.id,
                });
            });

            setSrchdLivePages(showcaseList);
            setLoading(false);
        } catch (error) {
            setSrchdLivePages([]);
            setLoading(false);
        }
    }, 100);

    const optionsData = srchdLivePages
        ? srchdLivePages.map(({ key, value }) => (
              <Option value={key} key={key}>
                  {value}
              </Option>
          ))
        : livePagesList.map(({ key, value }) => (
              <Option value={key} key={key}>
                  {value}
              </Option>
          ));
    return (
        <>
            <Select
                mode="multiple"
                style={{ width: '100%', marginTop: '8px' }}
                placeholder="Search and select livepage(s)"
                onSearch={setSrchVal}
                onChange={(a, b) => {
                    setLivePages(
                        b.map((item) => ({
                            value: item.children,
                            key: item.key,
                        }))
                    );
                    if (b.length) setLivePageSelectRequired(false);
                    else setLivePageSelectRequired(true);
                }}
                defaultValue={livePages.map((item) => item.key)}
                searchValue={srchVal}
                showArrow={false}
                showSearch
                autoClearSearchValue
                filterOption={false}
                notFoundContent={isLoading ? <Spin size="small" /> : null}
                disabled={previewMode}
                onSelect={() => setSrchVal('')}
            >
                {optionsData}
            </Select>
            {isLivePageSelectRequired && (
                <p className="invalid-feedback d-block">Required</p>
            )}
        </>
    );
};

const SendSettings = ({
    type = 'notification',
    hideTitle = false,
    selectedLivePages,
    setSelectedLivePages,
    livePagesSource,
    setLivePagesSource,
    livePagesList,
    isSponsor,
    previewMode,
    sendMode = 'now',
    updateSendMode,
    scheduleDate,
    setScheduleDate,
    timezone,
    selectedTrigger,
    updateTrigger,
    triggerTime,
    setTriggerTime,
    timeUnit,
    setTimeUnit,
    isLivePageSelectRequired,
    setLivePageSelectRequired,
}) => {
    const { triggers } = useSelector((state) => state?.settings);

    return (
        <Sider width="100%" className="tw-bg-white">
            <div className="sendSettings">
                {!hideTitle && <h2 className="sideHead">Send Settings</h2>}
                <Collapse
                    bordered={false}
                    className="mt-3 bg-white"
                    defaultActiveKey="settingLivepage"
                >
                    <Panel
                        header={<h2 className="panelHeading">Livepages</h2>}
                        key="settingLivepage"
                    >
                        <div className="form-input-container my-3">
                            <span className="notification-input-label mr-1">
                                Which livepage(s) should this {type} be sent to?
                            </span>
                            <Select
                                style={{ width: '100%' }}
                                className="rounded mt-2"
                                optionFilterProp="children"
                                defaultValue={livePagesSource}
                                onChange={(val) => setLivePagesSource(val)}
                                disabled={previewMode || isSponsor}
                            >
                                <Option value="project">All Livepages</Option>
                                <Option value="livepage">
                                    Specific Livepages
                                </Option>
                            </Select>
                        </div>
                        {livePagesSource === 'livepage' && (
                            <LivePageSearch
                                previewMode={previewMode}
                                setLivePages={setSelectedLivePages}
                                livePages={selectedLivePages}
                                livePagesList={livePagesList}
                                isLivePageSelectRequired={
                                    isLivePageSelectRequired
                                }
                                setLivePageSelectRequired={
                                    setLivePageSelectRequired
                                }
                            />
                        )}
                    </Panel>
                </Collapse>
                <Collapse
                    bordered={false}
                    className="mt-3 bg-white"
                    defaultActiveKey="settingWhen"
                >
                    <Panel
                        header={<h2 className="panelHeading">When?</h2>}
                        key="settingWhen"
                    >
                        <div className="form-input-container my-3">
                            <span className="notification-input-label mr-1">
                                When should this {type} be sent?
                            </span>

                            <Radio.Group
                                disabled={previewMode}
                                onChange={(e) => {
                                    if (e?.target?.value !== sendMode)
                                        updateSendMode(e?.target?.value);
                                }}
                                value={sendMode}
                                className="radioContainer"
                            >
                                <div>
                                    <Radio
                                        value="sendNow"
                                        className={
                                            sendMode === 'sendNow' && 'fw600'
                                        }
                                    >
                                        Now
                                    </Radio>
                                </div>
                                <div>
                                    <Radio
                                        className={
                                            sendMode === 'schedule' && 'fw600'
                                        }
                                        value="schedule"
                                    >
                                        Schedule for a later time
                                    </Radio>

                                    {sendMode === 'schedule' && (
                                        <>
                                            <div className="seperator" />
                                            <div className="dateTimeContainer mt-1">
                                                <div className="mr-2">
                                                    <label className="mb-1">
                                                        Date
                                                    </label>
                                                    <DatePicker
                                                        disabled={previewMode}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabledDate={(
                                                            current
                                                        ) =>
                                                            current <
                                                            moment()
                                                                .tz(timezone)
                                                                .startOf('day')
                                                        }
                                                        allowClear={false}
                                                        value={scheduleDate}
                                                        onChange={
                                                            setScheduleDate
                                                        }
                                                        showToday={false}
                                                        format="D MMM YYYY"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="mb-1">
                                                        Time
                                                    </label>
                                                    <TimePicker
                                                        disabled={previewMode}
                                                        allowClear={false}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabledDate={(
                                                            current
                                                        ) =>
                                                            current <
                                                            moment()
                                                                .tz(timezone)
                                                                .add(
                                                                    4,
                                                                    'minutes'
                                                                )
                                                        }
                                                        value={scheduleDate}
                                                        onChange={
                                                            setScheduleDate
                                                        }
                                                        format="hh:mm a"
                                                        showNow={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="timezoneContainer">
                                                <label className="mb-1">
                                                    Time Zone
                                                </label>
                                                <TimezoneSelector
                                                    disabled={previewMode}
                                                    selectStyle={{
                                                        width: '100%',
                                                    }}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="triggerContainer">
                                    <Radio
                                        className={
                                            sendMode === 'trigger' && 'fw600'
                                        }
                                        value="trigger"
                                    >
                                        Based on trigger
                                    </Radio>
                                    {sendMode === 'trigger' && (
                                        <>
                                            <div className="seperator" />
                                            <label>Choose a trigger:</label>
                                            <Select
                                                onChange={(val) =>
                                                    updateTrigger(
                                                        triggers.find(
                                                            ({ code }) =>
                                                                code === val
                                                        )
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                                defaultValue={
                                                    selectedTrigger.code
                                                }
                                                disabled={previewMode}
                                            >
                                                {triggers.map(
                                                    ({
                                                        code,
                                                        description,
                                                        id,
                                                    }) => (
                                                        <Option
                                                            key={id}
                                                            style={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontStyle:
                                                                    'normal',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                            value={code}
                                                        >
                                                            {description}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                            {[
                                                'LIVEPAGE_SPECIFIC_TIME',
                                                'LIVEPAGE_USER_INACTIVE_TIME',
                                            ].includes(
                                                selectedTrigger.code
                                            ) && (
                                                <div className="timePicker">
                                                    <Input
                                                        disabled={previewMode}
                                                        min={1}
                                                        step={1}
                                                        onChange={(e) => {
                                                            setTriggerTime(
                                                                e?.target?.value
                                                            );
                                                        }}
                                                        defaultValue={
                                                            triggerTime || 2
                                                        }
                                                        type="number"
                                                        style={{
                                                            width: '75%',
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontStyle: 'normal',
                                                            fontWeight:
                                                                'normal',
                                                            fontSize: '12px',
                                                            height: '32px',
                                                            borderRadius: '0px',
                                                        }}
                                                    />
                                                    <Select
                                                        disabled={previewMode}
                                                        defaultValue={timeUnit}
                                                        onChange={setTimeUnit}
                                                        style={{
                                                            width: '25%',
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontStyle: 'normal',
                                                            fontWeight:
                                                                'normal',
                                                            fontSize: '12px',
                                                        }}
                                                        showArrow={false}
                                                    >
                                                        <Option value="mins">
                                                            Mins
                                                        </Option>
                                                        <Option value="secs">
                                                            Secs
                                                        </Option>
                                                    </Select>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Radio.Group>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </Sider>
    );
};

export default React.memo(SendSettings);
