import React from 'react';
import { useHistory } from 'react-router-dom';

export default function NoChannelView({
    companyId,
    projectId,
    tab = 'active-channels',
}) {
    const history = useHistory();
    return (
        <div className="col-12 !tw-p-0 !md:tw-px-4">
            <div className="bg-white border border-radius d-flex flex-column justify-content-center align-items-center tw-my-0 md:tw-my-3 tw-px-2 empty-notification-table ">
                <div className="text-center">
                    {tab !== 'active-channels' ? (
                        <h2 className="!tw-text-base !tw-font-semibold !tw-text-mobileTextBlack !tw-font-openSans">
                            No expired channels here.
                        </h2>
                    ) : (
                        <>
                            <div className="empty-ellips mb-3 mx-auto" />
                            <span className="!tw-text-base !tw-font-semibold !tw-text-mobileTextBlack !tw-font-openSans tw-mb-2">
                                Create and manage your Live Stream channels
                            </span>
                            <p className="tw-text-sm tw-text-font-normal !tw-text-mobileTextGray">
                                Channels are the conduit for your live streams
                                and help you manage them more easily. Create
                                your channels here then add them to your
                                livepages through the live stream blocks.
                            </p>

                            <button
                                className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-sm !tw-font-semibold !tw-text-base tw-text-center tw-cursor-pointer tw-my-2"
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/channels/create`
                                    )
                                }
                            >
                                Create Live Stream channel
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
