import React, { useRef, useState } from 'react';

export default function MobileInput({
    value,
    setValue,
    placeholder = `Search`,
}) {
    const inputRef = useRef(null);
    const [currentIcon, setCurrentIcon] = useState('fa-search');
    const [isInputActive, setIsInputActive] = useState(false);

    const handleInputFocus = (icon) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setIsInputActive(true);
        setCurrentIcon(icon);
    };

    const handleClearInput = (e) => {
        e.stopPropagation();
        setValue('');
        setIsInputActive(false);
        setCurrentIcon('fa-search');
    };

    return (
        <div className="agenda-search-container tw-w-full">
            <div className="agenda-search input-group">
                {!isInputActive && (
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i
                                className="fa fa-search tw-text-base"
                                onClick={(e) => handleInputFocus('fa-close')}
                            />
                        </span>
                    </div>
                )}
                <input
                    ref={inputRef}
                    placeholder={placeholder}
                    value={value}
                    type="text"
                    id={`${
                        !isInputActive
                            ? 'mobileInput_mainSearch'
                            : 'mobileInput'
                    }`}
                    className=" form-control"
                    onChange={(e) => setValue(e.target.value)}
                    onClick={(e) => handleInputFocus('fa-close')}
                    onFocus={(e) => handleInputFocus('fa-close')}
                />
                {isInputActive && (
                    <div
                        className="input-group-append"
                        onClick={handleClearInput}
                    >
                        <span className="input-group-text">
                            <i
                                className={`fa ${currentIcon} !tw-text-base closeInputIcon`}
                            />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
