import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactPlayer from 'react-player';

import Modal from '../modal';
import { MinimizeIcon, CrossLinkIcon, DownloadIcon } from './icons';

export default function MediaAssetCardModal({
    mediaAsset,
    visible,
    onClose,
    onMediaAssetDownloadHandler,
    mediaPlayerSettings,
    showDetails,
}) {
    const mediaPlayerRef = useRef(null);
    const details = [
        {
            name: 'File name',
            value:
                mediaAsset?.name && mediaAsset?.name?.length > 46
                    ? `${mediaAsset?.name?.substring(0, 45)} ...`
                    : mediaAsset?.name,
        },
        {
            name: 'Size',
            value: `${(mediaAsset?.size || 0).toFixed(2)} MB`,
        },
        {
            name: 'Date added',
            value: moment.utc(mediaAsset?.createdAt).format('MMM DD, YYYY'),
        },
        {
            name: 'Added by',
            value: `${mediaAsset?.createdByUser?.firstname} ${mediaAsset?.createdByUser?.lastname}`,
        },
        {
            name: 'Link',
            value: (
                <a
                    href={mediaAsset?.url}
                    target="_blank"
                >
                    <CrossLinkIcon />
                </a>
            ),
            className: 'tw-self-center tw-cursor-pointer',
        },
        {
            name: 'Download',
            value: <DownloadIcon stroke="white" />,
            className: 'tw-self-center tw-cursor-pointer',
            onClick: onMediaAssetDownloadHandler,
        },
    ];

    useEffect(() => {
        if (!mediaPlayerRef.current || !mediaPlayerSettings?.playing || !mediaPlayerSettings?.seekTo) return;
        mediaPlayerRef.current.seekTo(mediaPlayerSettings.seekTo);
    }, [mediaPlayerSettings]);

    if (!visible) {
        return null;
    }

    return (
        <Modal
            className="tw-bg-transparent tw-w-full md:tw-align-middle md:tw-py-8 md:tw-px-8 md:tw-w-full lg:tw-max-w-5xl md:tw-max-w-3xl md:tw-max-w-2xl"
            visible={visible}
            onClose={onClose}
            showCloseIcon={false}
        >
            <div
                className="tw-absolute tw-flex tw-flex-col tw-top-8 tw-right-3 tw-cursor-pointer lg:tw-right-1 md:tw-right-0 md:tw-right-0"
                onClick={onClose}
            >
                <MinimizeIcon />
            </div>

            <div className="md:tw-flex md:tw-flex-col md:tw-justify-center md:tw-items-center">
                <div className="md:tw-flex md:tw-items-center md:tw-justify-between tw-bg-black tw-mt-5 tw-mb-0 tw-rounded-lg tw-overflow-hidden tw-w-full tw-h-128 lg:tw-max-w-4xl md:tw-max-w-2xl md:tw-max-w-xl">
                    <ReactPlayer
                        ref={mediaPlayerRef}
                        width={'100%'}
                        height={'100%'}
                        playing={mediaPlayerSettings?.playing}
                        url={mediaAsset?.url}
                        controls={true}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    />
                </div>
                {showDetails && (
                    <div className="md:tw-flex md:tw-flex-wrap md:tw-items-center md:tw-justify-between tw-bg-blue-600 tw-my-3 tw-py-1 tw-px-4 tw-rounded-lg tw-w-full tw-h-auto lg:tw-max-w-4xl md:tw-max-w-2xl md:tw-max-w-xl">
                        {details.map(({ name, value, className, onClick }) => (
                            <div className="tw-flex tw-flex-col tw-items-start tw-text-white tw-text-sm tw-h-24 tw-justify-around">
                                <p className="tw-font-semibold">{name}</p>
                                <p
                                    className={`tw-text-xs tw-font-extralight ${className || ''}`}
                                    onClick={onClick}
                                >
                                    {value}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    );
}

MediaAssetCardModal.propTypes = {
    mediaAsset: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onMediaAssetDownloadHandler: PropTypes.func,
    mediaPlayerSettings: PropTypes.shape({
        playing: PropTypes.bool,
        seekTo: PropTypes.string,
    }),
    showDetails: PropTypes.bool,
};

MediaAssetCardModal.defaultProps = {
    visible: false,
    onClose: () => {},
    onMediaAssetDownloadHandler: () => {},
    mediaPlayerSettings: {
        playing: false,
        seekTo: '0',
    },
    showDetails: true,
};
