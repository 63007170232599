/* eslint-disable max-len */
const SetupData = {
    name: 'Setup',
    description:
        'Get your company profile, content and booth ready to receive your visitors.',
    sublinks: [
        {
            name: 'Company Profile',
            description:
                'Setup your company profile, logo, tagline and description.',
            link: `company-profile`,
        },
        // {
        //     name: 'Team',
        //     description: 'Setup and manage your team.',
        //     link: '',
        // },
        {
            name: 'Booth',
            description:
                'This is your storefront! Get your booth ready to receive your visitors.',
            link: 'venue-builder',
        },
        {
            name: 'Products',
            description:
                'Showcase your products through images, videos, brochures, etc.',
            link: 'products',
        },
        {
            name: 'Agenda',
            description:
                'Ready for the show? Build your agenda sessions and speakers here.',
            link: 'agenda',
        },
    ],
};

const EngagementData = {
    name: 'Engage',
    description: 'Setup your company profile and get your booth ready.',
    sublinks: [
        {
            name: 'Run Your Live Stream',
            // description: 'Mange your team’s meetings.',
            link: 'channels',
        },
        {
            name: 'Launch Polls',
            // description: 'Browse the attendee directory, search and filter.',
            link: 'notification/polls',
        },
        {
            name: 'Send Announcements',
            // description: 'Browse the attendee directory, search and filter.',
            link: 'notification/announcements',
        },
    ],
};
const LeadsData = {
    name: 'Leads Generation',
    description: 'Capture and qualify leads at the event.',
    sublinks: [
        {
            name: 'App users',
            description:
                'Give users access to capture leads from the GEVME mobile app (iOS, Android)',
            link: 'leads/users',
        },
        {
            name: 'Leads',
            description:
                'View & export complete list captured by all users under your company.',
            link: 'leads/reports',
        },
    ],
};
const AnalyticsData = {
    name: 'Analytics',
    description: 'View and export your data.',
    sublinks: [
        {
            name: 'Booth Visits',
            description: '',
            link: 'analytics',
        },
        {
            name: 'Content Interactions',
            description: '',
            link: 'analytics',
        },
        {
            name: 'Media Views',
            description: '',
            link: 'analytics',
        },
        {
            name: 'Chat Logs',
            description: '',
            link: 'analytics',
        },
    ],
};

const LiveStreamingData = {
    name: 'Live Streaming',
    icon: 'streaming.svg',
    link: 'channels',
    sublinks: [
        {
            name: 'Live Streaming',
            description: '',
            link: 'channels',
        },
    ],
};

export {
    SetupData,
    EngagementData,
    LeadsData,
    AnalyticsData,
    LiveStreamingData,
};
