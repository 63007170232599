import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Space, Input, Button, Menu, Dropdown } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';

import moment from 'moment';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import NoDataComponent from '../../../../../../../../ComponentsV2/CommonComponents/MobileView/noDataComponent/NoDataComponent';

import './draftTable.scss';
import { useSelector } from 'react-redux';

const DraftTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickDuplicateNotification,
    onClickDeleteNotification,
    sendNotification,
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    useEffect(() => {
        const trimSearchText = searchText?.trim();
        search(trimSearchText);
    }, [searchText]);

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder="Search"
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        const searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const renderDropDownMenu = (item) => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <a onClick={() => onClickDuplicateNotification(item)}>
                        Duplicate
                    </a>
                </Menu.Item>
                <Menu.Item key="1">
                    <a
                        onClick={() =>
                            onClickDeleteNotification(
                                item?.id || item?.notificationId
                            )
                        }
                    >
                        Delete
                    </a>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: (a, b) => a?.title?.length - b?.title?.length,
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Last Edit',
            dataIndex: 'updatedAt',
            sorter: (a, b) =>
                moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
            render: (updatedAt, item) => {
                if (!updatedAt) return null;
                return (
                    <span>
                        {moment(updatedAt)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] h:mm:ss a')}
                    </span>
                );
            },
        },
        {
            title: <span className="tw-w-max tw-flex">Target Pages</span>,
            dataIndex: 'targetId',
            sorter: (a, b) => a?.targetId?.length - b?.targetId?.length,
            render: (targetId, item) => {
                return (
                    <span>
                        {item?.targetType === 'project'
                            ? 'All Livepages'
                            : targetId?.length}
                    </span>
                );
            },
        },
        {
            render: (item) => {
                return (
                    <div className="d-flex justify-content-end">
                        <button
                            className="myxp btn btn-sm btn-link"
                            style={{ minWidth: 90 }}
                            onClick={() => sendNotification('sendNow', item)}
                        >
                            Send now
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => sendNotification('schedule', item)}
                        >
                            Schedule
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-hidden md:tw-flex"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/edit-announcement/${item?.notificationId}`
                                )
                            }
                        >
                            Edit
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-flex md:tw-hidden"
                            onClick={() => onClickDuplicateNotification(item)}
                        >
                            Duplicate
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-flex md:tw-hidden text-danger"
                            onClick={() =>
                                onClickDeleteNotification(
                                    item?.id || item?.notificationId
                                )
                            }
                        >
                            Delete
                        </button>
                        <Dropdown
                            overlay={renderDropDownMenu(item)}
                            trigger={['click']}
                            className="tw-hidden sm:tw-block"
                        >
                            <MoreOutlined
                                onClick={(e) => e.preventDefault()}
                                style={{ fontSize: 19 }}
                                className="pt-2"
                            />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: <GlobalLoading />,
    };

    const renderPagination = (current, type, originalElement) => {
        return <a onClick={() => pagination(current)}>{originalElement}</a>;
    };

    return (
        <>
            {!loading && data?.notifications?.length ? (
                <Table
                    className="sponsors-company-table"
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.notifications}
                    loading={globalLoadingComponent}
                    pagination={
                        pagination
                            ? {
                                  itemRender: renderPagination,
                                  pageSize: 10,
                                  total: data?.pagination?.total,
                                  current: data?.pagination?.currentPage,
                              }
                            : false
                    }
                />
            ) : (
                <NoDataComponent
                    loading={loading}
                    buttonText="Create announcement"
                    description="No announcement have been Created"
                    onClickHandler={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/notification/create-announcement`
                        )
                    }
                />
            )}
            <style>{`
            .sponsors-company-table .ant-table{
                overflow: auto;
                }
        `}</style>
        </>
    );
};

DraftTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    onClickDuplicateNotification: PropTypes.func,
    onClickDeleteNotification: PropTypes.func,
    sendNotification: PropTypes.func,
    loading: PropTypes.bool,
};

DraftTable.defaultProps = {
    data: null,
    loading: false,
};

export default DraftTable;
