import { Tooltip } from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';

export default function NoDataComponent({
    buttonText,
    description,
    loading,
    onClickHandler,
}) {
    const isMobile = useIsMobile();

    if (loading) return null;
    return (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-9 tw-bg-white tw-px-2.5 tw-py-12 tw-border tw-border-[#dccfdc] tw-rounded-lg">
            <span className="tw-font-semibold tw-font-openSans tw-text-base">{description}</span>
        </div>
    );
}
