import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
    Link,
    withRouter,
    useLocation,
    useHistory,
    useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as ls from 'local-storage';
import { Dropdown } from 'react-bootstrap';
import Moment from 'react-moment';
import { debounce } from 'lodash';
import Tooltip from 'rc-tooltip/es';
import { InfoCircleOutlined } from '@ant-design/icons';

import useAlertBar from '../../../utils/hooks/useAlertBar';

import { getProjects } from '../../../../../apis/rest/livestream/GetProjects';
import { archiveProject } from '../../../../../apis/rest/livestream/ArchiveProject';

import Layout from '../.././../common/layout';
import EmptyPage from '../../../../../components/app/common/empty';
import TablePreloader from '../../../common/preloader/tablePreloader';
import Prompt from '../../../common/prompt';
import PageSpinner from '../../../common/pageSpinner';
import Users from './users';
import UsageAndBillings from './usageAndBillings';

import { TwoFAOtpModal } from 'ComponentsV2/SignIn/2FA';

// Redux - actions
import * as act from '../../../../../redux/actions/live/uiActionsLive';

import './dashboard.scss';
import {
    getCompanyDetails,
    getSettings,
    updateCompanyDetails,
} from 'apis/rest/livestream/GetSettings';
import { Button, Pagination, Switch } from 'antd';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { handleLogout } from 'ComponentsV2/ProjectDashboard/utils/handleLogout';
import { store } from 'redux/store';
import { updateUser } from 'redux/actions/common/userActions';

const whiteListedCompany = [
    "b48a750a-734b-488e-9a27-d29a190bcafd",
    "19a8de8c-808b-47e0-9a7f-17478cae6d49",
    "4d38c0ab-db12-4b7c-9331-fc5278e8f379",
    "24e0760f-b3c4-4cc9-88ee-9d37101b0ec7",
    "1d04b23c-de46-4c1c-9673-78290ed0ec95",
    "fdba4a34-cd65-494b-a750-9d6c8249131c",
    "7cbf3db7-196d-4da3-ae0b-7950a503b1e9",
    "8bd9d442-e8a8-407d-975f-66f8299f8e11",
    "93943880-f9f1-4737-ac3b-135903e476ea",
    "405101ea-52cc-4fc5-8733-2dbe8cd4f358",
    "7217995b-43fc-477a-8d7b-451b40a4a0b2",
    "db42baf1-97d0-437e-b510-40b8ffa368fd",
    "742b21c9-d743-4572-aa31-231f2f6b05bc",
]

function List() {
    const user = store?.getState().user;
    const { companyId } = useParams();
    const { search: urlSearchQuery } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const getUserProject = ls.get('project');
    const companyDetails = useSelector((state) => state?.projects?.company);
    const isCompanyHavePlan = !!companyDetails?.package?.id;
    const enabledVenue = false;

    const titleRef = useRef([]);
    const { showAlertBar } = useAlertBar();
    const [isBusy, setIsBusy] = useState(false);
    const [hasProject, setHasProject] = useState(false);
    const [projectCheckLoader, setProjectCheckLoader] = useState(true);
    const [projects, setProjects] = useState([]);
    const [isArchivedFilter, setIsArchived] = useState(false);
    const [pagination, setPagination] = useState({});
    const [companyName, setCompanyName] = useState(companyDetails?.name);
    const [isCompanyEnable2FA, setIsCompanyEnable2FA] = useState(
        !!companyDetails?.enable2FA
    );
    const [twoFAVerificationCode, setTwoFAVerificationCode] = useState('');
    const [showTwoFAOtpModal, setShowTwoFAOtpModal] = useState(false);
    const is2FADisabledNow = !!companyDetails?.enable2FA && !isCompanyEnable2FA;
    const [sendRequestOtp, setSendRequestOtp] = useState(false);
    const [isCompanyNameUpdateInProgress, setIsCompanyNameUpdateInProgress] =
        useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit, setCurrentLimit] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState('');
    const [sortedColumn, setSortedColumn] = useState({
        name: 'updatedAt',
        desc: true,
    });
    const [headerHovered, setHeaderHovered] = useState(-1);
    const [projectSearchWidth, setProjectSearchWidth] = useState('132px');
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [archivePrompt, setArchivePrompt] = useState({});
    const [unarchivePrompt, setUnarchivePrompt] = useState({});
    const projectSearchRef = useRef('');
    const [activeMenu, setActiveMenu] = useState('project');

    const tableHeaders = [
        { id: 0, name: 'name', desc: 'Project', isSortShown: false },
        { id: 1, name: 'createdBy', desc: 'Created By', isSortShown: false },
        { id: 2, name: 'createdAt', desc: 'Created On', isSortShown: false },
        {
            id: 3,
            name: 'updatedBy',
            desc: 'Last Modified By',
            isSortShown: false,
        },
        {
            id: 4,
            name: 'updatedAt',
            desc: 'Last Modified On',
            isSortShown: true,
        },
    ];

    const headerMenus = [
        {
            label: 'Projects',
            name: 'project',
            disabled: false,
        },
        {
            label: 'Settings',
            name: 'settings',
            disabled: false,
        },
        {
            label: 'Users',
            name: 'users',
            disabled: false,
        },
        {
            label: 'Usage & Billing',
            name: 'usageAndBilling',
            disabled: !isCompanyHavePlan || whiteListedCompany.includes(companyId), // usage and billing section is only enabled for companies with a plan
        },
        {
            label: 'Data',
            name: 'data',
            disabled: true,
        },
    ];

    // This is triggered when a project has no setting
    if (urlSearchQuery === '?err=snf') {
        showAlertBar('Settings not found', 'error');
        setTimeout(() => history.push('/'), 5000);
    }

    useEffect(() => {
        // Reset allowFX to false, to disable transition effects
        dispatch(act.updateAllowFX(false));
        // Let Layout component know that menus are not visible
        dispatch(act.updateMainMenuVisibility(false));
        dispatch(act.updateSubMenuVisibility(false));
        // Reset defaults
        dispatch(act.updateHeaderTitle(''));
        dispatch(act.updateActiveLivePageName(''));

        // If user is sponsor let's redirect to switch
        if (user.roleId === 4) {
            //FIXME: check here
            history.push('/sponsor');
        } else if (user.roleId === 1) {
            history.push('/admin/dashboard');
        }
        // enable switch event navigation
        if (user.roleId === 4) {
            dispatch(act.enableSwitchEvent());
        } else {
            if (history?.location?.search === '?enableVenue=false')
                dispatch(act.disabledSwitchEvent());
        }
    }, []);

    const handleRequestOTP = () => {
        if (!sendRequestOtp) {
            setSendRequestOtp(true);
        }
        openNotification('info', {
            message: 'Please verify your account first',
        });
    };

    const handleGetSettings = async (projectId) => {
        const response = await getSettings(projectId);

        if (response.status) {
            ls('activeProjData', response.setting);
        }
    };

    const handleGetCompanyDetails = async (companyId) => {
        const respose = await getCompanyDetails(companyId);
    };

    useEffect(() => {
        if (companyId) handleGetCompanyDetails(companyId);
    }, [companyId]);

    useEffect(() => {
        setCompanyName(companyDetails?.name);
        setIsCompanyEnable2FA(!!companyDetails?.enable2FA);
        const data = window?.userGuiding?.identify(`${user?.id}`, {
            plan_type: companyDetails?.package?.name,
            isNewSignup: user?.isNewSignup,
            UserGoals: user?.userGoal,
        });
    }, [companyDetails]);

    useEffect(() => {
        handleGetProjects();
    }, [
        currentPage,
        currentLimit,
        isArchivedFilter,
        searchDebounceText,
        sortedColumn,
        companyId,
    ]);

    const handleGetProjects = async () => {
        try {
            setIsBusy(true);

            const response = await getProjects(
                currentPage,
                currentLimit,
                searchDebounceText,
                isArchivedFilter,
                sortedColumn.name,
                sortedColumn.desc,
                companyId
            );

            if (!response?.status) throw response;

            setPagination(response.pagination);
            setProjects(response.projects);
            setIsBusy(false);
            if (response?.pagination?.total === 0 && !searchValue) {
                setHasProject(false);
            } else setHasProject(true);
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong',
            });
        } finally {
            setProjectCheckLoader(false);
        }
    };

    const setArchiveFilter = (isArchived) => {
        setCurrentPage(1);
        setIsArchived(isArchived);
    };

    const setActiveFilterClass = () => {
        return !isArchivedFilter ? 'active-filter' : '';
    };

    const setArchivedFilterClass = () => {
        return isArchivedFilter ? 'active-filter' : '';
    };

    const handlePaginationChange = (page) => {
        if (hasPagination()) {
            let computedPage = Math.ceil(pagination.total / currentLimit);
            page = computedPage < page && !!computedPage ? computedPage : page;
        }
        setCurrentPage(page);
    };

    const handleInputSearchEvent = (width, status) => {
        setProjectSearchWidth(width);
        setSearchIsFocused(status);
    };

    const handleSearch = (value) => {
        setIsBusy(true);
        setCurrentPage(1);
        setSearchValue(value);
        searchDebounce(value);
    };

    const searchDebounce = useCallback(
        debounce((text) => setSearchDebounceText(text), 700),
        []
    );

    const handleCurrentLimit = (limit) => {
        setCurrentLimit(limit);
        let computedPage = Math.ceil(pagination.total / limit);
        let projectPage =
            computedPage < currentPage ? computedPage : currentPage;

        setCurrentPage(projectPage);
    };

    const handlePageCount = () => {
        return pagination ? Math.ceil(pagination.total / currentLimit) : 0;
    };

    const handleArchive = async (projectId) => {
        setIsBusy(true);

        archiveProject(projectId, true)
            .then(async (response) => {
                if (!response.status) {
                    showAlertBar(response.message, 'error');
                    return;
                }

                await handleGetProjects();
                showAlertBar(
                    'Your Project has been successfully archived.',
                    'success'
                );
            })
            .catch((err) =>
                showAlertBar('Failed to archive your project.', 'error')
            )
            .finally(() => setIsBusy(false));
        setArchivePrompt({ show: false });
    };

    const handleUnarchive = async (projectId) => {
        setIsBusy(true);

        archiveProject(projectId, false)
            .then(async (response) => {
                if (!response.status) {
                    showAlertBar(response.message, 'error');
                    return;
                }

                await handleGetProjects();
                showAlertBar(
                    'Your Project has been successfully unarchived.',
                    'success'
                );
            })
            .catch((err) =>
                showAlertBar('Failed to unarchive your project.', 'error')
            )
            .finally(() => setIsBusy(false));
        setUnarchivePrompt({ show: false });
    };

    // prompt
    const handleConfirmPrompt = (project, action) =>
        action({ id: project.projectId, name: project.name, show: true });

    const handleClosePrompt = (action) => action({ show: false });

    const hasPagination = () => {
        return !!Object.keys(pagination).length;
    };

    const handleOnSort = (table) => {
        setSortedColumn({
            name: table.name,
            desc: table.name === sortedColumn.name ? !sortedColumn.desc : true,
        });
        setCurrentPage(1);
    };

    const emptyPageCustomIcon = () => {
        return (
            <i
                className="fa fa-10x fa-folder"
                style={{ color: '#B7C3CF', opacity: '35%' }}
            />
        );
    };

    const handleSave = async () => {
        try {
            if (is2FADisabledNow && twoFAVerificationCode.length !== 6) {
                return openNotification('error', {
                    message: 'Please enter a valid 2FA code.',
                });
            }
            setIsCompanyNameUpdateInProgress(true);
            const updatedCompanyDetails = {
                name: companyName,
                enable2FA: isCompanyEnable2FA,
            };
            if (is2FADisabledNow) {
                updatedCompanyDetails.code = twoFAVerificationCode;
            }
            const is2FAEnabledNow =
                isCompanyEnable2FA && !companyDetails?.enable2FA;
            const resp = await updateCompanyDetails(
                updatedCompanyDetails,
                companyId
            );
            if (!resp?.status) throw resp;
            openNotification('success', {
                message: 'Organisation settings updated successfully',
            });
            if (is2FAEnabledNow && !user?.enable2FA) {
                ls('require2FASetup', true);
                dispatch(updateUser({ user: { require2FASetup: true } }));
            }
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong.',
            });
        } finally {
            setIsCompanyNameUpdateInProgress(false);
            setShowTwoFAOtpModal(false);
            setTwoFAVerificationCode('');
        }
    };

    const renderMenuPage = (menu) => {
        try {
            switch (menu.toLowerCase()) {
                case 'settings':
                    return (
                        <>
                            <div className="container">
                                <div className="row mt-5 mb-3">
                                    <div className="col-sm-8 d-flex align-items-center">
                                        <h1 className="mr-5 mb-0">
                                            Organization settings
                                        </h1>
                                    </div>
                                    <div className="col-sm-4 d-flex justify-content-end align-items-center">
                                        <Button
                                            className="myxp btn btn-primary"
                                            onClick={
                                                is2FADisabledNow
                                                    ? () =>
                                                          setShowTwoFAOtpModal(
                                                              true
                                                          )
                                                    : () => handleSave()
                                            }
                                            loading={
                                                isCompanyNameUpdateInProgress
                                            }
                                            disabled={
                                                isCompanyEnable2FA ===
                                                    !!companyDetails?.enable2FA &&
                                                (!companyName ||
                                                    companyName === '' ||
                                                    companyName ===
                                                        companyDetails?.name)
                                            }
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>

                                <div className="bg-white p-4 rounded">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="d-flex align-items-center">
                                                Organization Name
                                                <Tooltip overlay="Company Name">
                                                    <InfoCircleOutlined
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            marginLeft: '4px',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </label>
                                            <input
                                                className="form-control"
                                                name="company-name"
                                                required={true}
                                                value={companyName}
                                                onChange={(e) => {
                                                    setCompanyName(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="d-flex align-items-center">
                                                Country
                                            </label>
                                            <input
                                                className="form-control"
                                                name="country"
                                                disabled
                                                value={companyDetails?.country}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="d-flex align-items-center">
                                                Security
                                            </label>
                                            <div className="tw-w-full tw-px-3 tw-py-4 tw-flex tw-flex-row tw-items-center tw-justify-between tw-border tw-border-solid tw-border-gray-200 tw-rounded-md">
                                                <p className="tw-text-xs tw-text-black tw-font-semibold tw-m-0">
                                                    Enable Two-Factor
                                                    Authentication
                                                </p>
                                                <Switch
                                                    checked={isCompanyEnable2FA}
                                                    onChange={(checked) =>
                                                        user?.isVerified
                                                            ? setIsCompanyEnable2FA(
                                                                  checked
                                                              )
                                                            : handleRequestOTP()
                                                    }
                                                    disabled={
                                                        isCompanyNameUpdateInProgress
                                                    }
                                                    className={
                                                        isCompanyNameUpdateInProgress
                                                            ? 'tw-bg-gray-200 tw-cursor-not-allowed'
                                                            : isCompanyEnable2FA
                                                            ? 'tw-bg-blue-antd'
                                                            : 'tw-bg-gray-200'
                                                    }
                                                />
                                            </div>
                                            <TwoFAOtpModal
                                                verificationCode={
                                                    twoFAVerificationCode
                                                }
                                                setVerificationCode={
                                                    setTwoFAVerificationCode
                                                }
                                                visible={showTwoFAOtpModal}
                                                setVisible={
                                                    setShowTwoFAOtpModal
                                                }
                                                onCancel={() => {
                                                    setIsCompanyEnable2FA(
                                                        !!companyDetails?.enable2FA
                                                    );
                                                    setShowTwoFAOtpModal(false);
                                                }}
                                                onConfirm={handleSave}
                                                isLoading={
                                                    isCompanyNameUpdateInProgress
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                case 'users':
                    return <Users />;
                case 'usageandbilling': {
                    if (!isCompanyHavePlan) {
                        return setActiveMenu('project');
                    }
                    return <UsageAndBillings />;
                }
                default:
                    return (
                        <>
                            {
                                projectCheckLoader
                                    ? (
                                        <PageSpinner
                                            type="Oval"
                                            color="#ACBDC9"
                                            height={48}
                                            width={48}
                                        />
                                    ) : (
                                        <div className="container">
                                            <div className="dashboard dashboard__xpmgr project-list">
                                                <div className="dashboard__left">
                                                    {enabledVenue ? (
                                                        <>
                                                            <Link to="/">
                                                                <h1 className="active">
                                                                    Projects
                                                                </h1>
                                                            </Link>
                                                            <Link
                                                                to={`/venue-collections/${getUserProject?.projectId}`}
                                                            >
                                                                <h1>
                                                                    Venue Collection
                                                                </h1>
                                                            </Link>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h1 className="active">
                                                                Projects
                                                            </h1>
                                                            <span
                                                                className={
                                                                    'mr-3 font-weight-bold pointer project-filters ' +
                                                                    setActiveFilterClass()
                                                                }
                                                                onClick={() =>
                                                                    setArchiveFilter(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                        Active
                                                    </span>
                                                            <span
                                                                className={
                                                                    'mr-3 font-weight-bold pointer project-filters ' +
                                                                    setArchivedFilterClass()
                                                                }
                                                                onClick={() =>
                                                                    setArchiveFilter(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                        Archived
                                                    </span>
                                                        </>
                                                    )}
                                                </div>
                                                {!enabledVenue && (
                                                    <div className="d-flex align-items-center">
                                                        <div className="project-search">
                                                            <input
                                                                value={searchValue}
                                                                ref={projectSearchRef}
                                                                type="text"
                                                                className="form-control m-0"
                                                                style={{
                                                                    width: projectSearchWidth,
                                                                }}
                                                                onChange={(e) =>
                                                                    handleSearch(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                onFocus={() =>
                                                                    handleInputSearchEvent(
                                                                        '210px',
                                                                        true
                                                                    )
                                                                }
                                                                onBlur={() =>
                                                                    handleInputSearchEvent(
                                                                        '132px',
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                            <i
                                                                className={
                                                                    'fa ' +
                                                                    (searchValue ===
                                                                    '' &&
                                                                    !searchIsFocused
                                                                        ? 'fa-search'
                                                                        : 'fa-close')
                                                                }
                                                                onClick={() => {
                                                                    searchValue ===
                                                                    '' &&
                                                                    !searchIsFocused
                                                                        ? handleInputSearchEvent(
                                                                            '132px',
                                                                            false
                                                                        )
                                                                        : handleSearch(
                                                                            ''
                                                                        );
                                                                }}
                                                            >
                                                                {' '}
                                                            </i>
                                                        </div>
                                                        <Link
                                                            to={`/company/${companyId}/project/create`}
                                                            className="myxp btn btn-primary ml-3"
                                                        >
                                                            Create new project
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>

                                            {enabledVenue && (
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <div className="d-flex align-items-center">
                                                <span
                                                    className={
                                                        'mr-3 font-weight-bold pointer project-filters ' +
                                                        setActiveFilterClass()
                                                    }
                                                    onClick={() =>
                                                        setArchiveFilter(false)
                                                    }
                                                >
                                                    Active
                                                </span>
                                                        <span
                                                            className={
                                                                'mr-3 font-weight-bold pointer project-filters ' +
                                                                setArchivedFilterClass()
                                                            }
                                                            onClick={() =>
                                                                setArchiveFilter(true)
                                                            }
                                                        >
                                                    Archived
                                                </span>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <div className="project-search">
                                                            <input
                                                                value={searchValue}
                                                                ref={projectSearchRef}
                                                                type="text"
                                                                className="form-control m-0"
                                                                style={{
                                                                    width: projectSearchWidth,
                                                                }}
                                                                onChange={(e) =>
                                                                    handleSearch(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                onFocus={() =>
                                                                    handleInputSearchEvent(
                                                                        '210px',
                                                                        true
                                                                    )
                                                                }
                                                                onBlur={() =>
                                                                    handleInputSearchEvent(
                                                                        '132px',
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                            <i
                                                                className={
                                                                    'fa ' +
                                                                    (searchValue ===
                                                                    '' &&
                                                                    !searchIsFocused
                                                                        ? 'fa-search'
                                                                        : 'fa-close')
                                                                }
                                                                onClick={() => {
                                                                    searchValue ===
                                                                    '' &&
                                                                    !searchIsFocused
                                                                        ? handleInputSearchEvent(
                                                                            '132px',
                                                                            false
                                                                        )
                                                                        : handleSearch(
                                                                            ''
                                                                        );
                                                                }}
                                                            >
                                                                {' '}
                                                            </i>
                                                        </div>
                                                        <Link
                                                            to={`/company/${companyId}/project/create`}
                                                            className="myxp btn btn-primary ml-3"
                                                        >
                                                            Create new project
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}

                                    <div className="mt-5">
                                        {hasProject ? (
                                            <>
                                                <table className="table table-borderless project-list-table">
                                                    <thead>
                                                        <tr>
                                                            {tableHeaders.map(
                                                                (table) => (
                                                                    <th
                                                                        scope="col"
                                                                        key={
                                                                            table.name
                                                                        }
                                                                        onMouseEnter={() =>
                                                                            setHeaderHovered(
                                                                                table.name
                                                                            )
                                                                        }
                                                                        onMouseLeave={() =>
                                                                            setHeaderHovered(
                                                                                -1
                                                                            )
                                                                        }
                                                                        onClick={() =>
                                                                            handleOnSort(
                                                                                table
                                                                            )
                                                                        }
                                                                    >
                                                                        <div>
                                                                            {
                                                                                table.desc
                                                                            }
                                                                            {(sortedColumn.name ===
                                                                                table.name ||
                                                                                headerHovered ===
                                                                                    table.name) && (
                                                                                <i
                                                                                    className={
                                                                                        'fa ' +
                                                                                        (!sortedColumn.desc &&
                                                                                        sortedColumn.name ===
                                                                                            table.name
                                                                                            ? 'fa-chevron-up'
                                                                                            : 'fa-chevron-down')
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </th>
                                                                )
                                                            )}
                                                            <th scope="col" />
                                                        </tr>
                                                    </thead>
                                                    {!isBusy &&
                                                        (projects.length ? (
                                                            <tbody>
                                                                {projects.map(
                                                                    (
                                                                        project,
                                                                        i
                                                                    ) => {
                                                                        let createdByFullname =
                                                                                project.createdByUser
                                                                                    ? `${project.createdByUser.firstname} ${project.createdByUser.lastname}`
                                                                                    : '',
                                                                            updatedByFullname =
                                                                                project.updatedByUser
                                                                                    ? `${project.updatedByUser.firstname} ${project.updatedByUser.lastname}`
                                                                                    : '';
                                                                        return (
                                                                            <tr
                                                                                key={
                                                                                    'project-row' +
                                                                                    project.id
                                                                                }
                                                                            >
                                                                                <Tooltip
                                                                                    placement="right"
                                                                                    trigger="hover"
                                                                                    key={
                                                                                        'project-' +
                                                                                        project.id
                                                                                    }
                                                                                    overlay={
                                                                                        project
                                                                                            .name
                                                                                            ?.length >
                                                                                        150 ? (
                                                                                            <>
                                                                                                <p className="tw-mb-0 tw-text-xs">
                                                                                                    {project.name?.slice(
                                                                                                        0,
                                                                                                        150
                                                                                                    )}
                                                                                                </p>
                                                                                                <p className="tw-mb-0 tw-text-xs">
                                                                                                    {project.name?.slice(
                                                                                                        150
                                                                                                    )}
                                                                                                </p>
                                                                                            </>
                                                                                        ) : (
                                                                                            <span className="tw-text-xs">
                                                                                                {
                                                                                                    project.name
                                                                                                }
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        <Link
                                                                                            to={{
                                                                                                pathname: `/company/${companyId}/project/${project.projectId}`,
                                                                                                state: project,
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                ref={(
                                                                                                    el
                                                                                                ) =>
                                                                                                    (titleRef.current[
                                                                                                        i
                                                                                                    ] =
                                                                                                        el)
                                                                                                }
                                                                                                className="project-title"
                                                                                            >
                                                                                                {
                                                                                                    project.name
                                                                                                }
                                                                                                <span
                                                                                                    className="_p-id"
                                                                                                    hidden={
                                                                                                        true
                                                                                                    }
                                                                                                    style={{
                                                                                                        visibility:
                                                                                                            'hidden',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        project.projectId
                                                                                                    }
                                                                                                </span>
                                                                                            </span>
                                                                                        </Link>
                                                                                    </td>
                                                                                </Tooltip>
                                                                                <Tooltip
                                                                                    placement="right"
                                                                                    trigger="hover"
                                                                                    key={
                                                                                        'created-by-' +
                                                                                        project.id
                                                                                    }
                                                                                    overlay={
                                                                                        <span>
                                                                                            {
                                                                                                createdByFullname
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {
                                                                                            createdByFullname
                                                                                        }
                                                                                    </td>
                                                                                </Tooltip>
                                                                                <td>
                                                                                    <Moment format="D/MM/YYYY h:mm a">
                                                                                        {
                                                                                            project.createdAt
                                                                                        }
                                                                                    </Moment>
                                                                                </td>
                                                                                <Tooltip
                                                                                    placement="right"
                                                                                    trigger="hover"
                                                                                    key={
                                                                                        'updated-by-' +
                                                                                        project.id
                                                                                    }
                                                                                    overlay={
                                                                                        <span>
                                                                                            {
                                                                                                updatedByFullname
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {
                                                                                            updatedByFullname
                                                                                        }
                                                                                    </td>
                                                                                </Tooltip>

                                                                                            <td>
                                                                                                <Moment format="D/MM/YYYY h:mm a">
                                                                                                    {
                                                                                                        project.updatedAt
                                                                                                    }
                                                                                                </Moment>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="action-items">
                                                                                                    {project?.venueId ? (
                                                                                                        <button
                                                                                                            className="btn myxp btn-secondary"
                                                                                                            onClick={() => {
                                                                                                                handleGetSettings(
                                                                                                                    project.projectId
                                                                                                                );
                                                                                                                history.push(
                                                                                                                    `/company/${companyId}/project/${project.projectId}/venue-builder`,
                                                                                                                    project
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            Editor
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <button
                                                                                                            className="btn myxp btn-secondary disabled"
                                                                                                            disabled
                                                                                                        >
                                                                                                            Editor
                                                                                                        </button>
                                                                                                    )}

                                                                                                    <button
                                                                                                        className="btn myxp btn-secondary"
                                                                                                        onClick={() =>
                                                                                                            history.push(
                                                                                                                `/company/${companyId}/project/${project.projectId}/dashboard`,
                                                                                                                project
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Dashboard
                                                                                                    </button>
                                                                                                    <Dropdown>
                                                                                                        <Dropdown.Toggle
                                                                                                            variant="secondary"
                                                                                                            id={
                                                                                                                project.projectId
                                                                                                            }
                                                                                                            className="p-0"
                                                                                                        >
                                                                                                            <i className="fa fa-ellipsis-h" />
                                                                                                        </Dropdown.Toggle>

                                                                                                        <Dropdown.Menu
                                                                                                            alignRight={
                                                                                                                true
                                                                                                            }
                                                                                                        >
                                                                                                            {project.active && (
                                                                                                                <Dropdown.Item
                                                                                                                    href="#"
                                                                                                                    onClick={() =>
                                                                                                                        handleConfirmPrompt(
                                                                                                                            project,
                                                                                                                            setArchivePrompt
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    Archive
                                                                                                                </Dropdown.Item>
                                                                                                            )}

                                                                                                            {!project.active && (
                                                                                                                <Dropdown.Item
                                                                                                                    href="#"
                                                                                                                    onClick={() =>
                                                                                                                        handleConfirmPrompt(
                                                                                                                            project,
                                                                                                                            setUnarchivePrompt
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    Unarchive
                                                                                                                </Dropdown.Item>
                                                                                                            )}
                                                                                                        </Dropdown.Menu>
                                                                                                    </Dropdown>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                            </tbody>
                                                                        ) : (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan="5"
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}
                                                                                >
                                                                                    No results found
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    {isBusy && (
                                                                        <TablePreloader columns={6}>
                                                                            {' '}
                                                                        </TablePreloader>
                                                                    )}
                                                                </table>

                                                                {/* pagination */}
                                                                <div className="align-items-center d-flex justify-content-between">
                                                                    <Dropdown
                                                                        style={{ width: '100px' }}
                                                                    >
                                                                        <Dropdown.Toggle
                                                                            variant="secondary"
                                                                            id=""
                                                                            style={{
                                                                                padding: '8px 16px',
                                                                            }}
                                                                            className="m-0 myxp bg-white w-100 border rounded font-weight-bold justify-content-between"
                                                                        >
                                                                            <span>Limit</span>{' '}
                                                                            {currentLimit}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu
                                                                            alignRight={true}
                                                                        >
                                                                            {Array(3)
                                                                                .fill(5)
                                                                                .map((el, i) => (
                                                                                    <Dropdown.Item
                                                                                        onClick={() => {
                                                                                            handleCurrentLimit(
                                                                                                el *
                                                                                                (i +
                                                                                                    1)
                                                                                            );
                                                                                        }}
                                                                                        key={i}
                                                                                    >
                                                                                        {el * (i + 1)}
                                                                                    </Dropdown.Item>
                                                                                ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                    <Pagination
                                                        current={currentPage}
                                                        total={
                                                            pagination?.total ||
                                                            0
                                                        }
                                                        pageSize={currentLimit}
                                                        onChange={
                                                            handlePaginationChange
                                                        }
                                                        showSizeChanger={false}
                                                        showQuickJumper={false}
                                                    />
                                                                </div>

                                                                {/* alert prompts */}
                                                                <Prompt
                                                                    show={archivePrompt.show}
                                                                    message={`Are you sure you want to archive Project?`}
                                                                    description={
                                                                        'Attendees will no longer be able to access the livepages and livesite of this project'
                                                                    }
                                                                    buttons={[
                                                                        'Cancel',
                                                                        'Proceed to Archive',
                                                                    ]}
                                                                    onHide={() =>
                                                                        handleClosePrompt(
                                                                            setArchivePrompt
                                                                        )
                                                                    }
                                                                    callback={() =>
                                                                        handleArchive(archivePrompt.id)
                                                                    }
                                                                />
                                                                <Prompt
                                                                    show={unarchivePrompt.show}
                                                                    message={`Are you sure you want to archive Project?`}
                                                                    description={
                                                                        'Attendees will be able to access the livepages and livesite of this project'
                                                                    }
                                                                    buttons={[
                                                                        'Cancel',
                                                                        'Proceed to Unarchive',
                                                                    ]}
                                                                    onHide={() =>
                                                                        handleClosePrompt(
                                                                            setUnarchivePrompt
                                                                        )
                                                                    }
                                                                    callback={() =>
                                                                        handleUnarchive(
                                                                            unarchivePrompt.id
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <EmptyPage
                                                                title="You don’t have any Projects yet"
                                                                customIcon={emptyPageCustomIcon}
                                                                callToActionText="Create a Project"
                                                                route={`/company/${companyId}/project/create`}
                                                            />
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                            }
                        </>
                    );
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Layout
            isProjListing={true}
            menus={headerMenus}
            setActiveMenu={setActiveMenu}
            activeMenu={activeMenu}
            sendRequestOtp={sendRequestOtp}
            setSendRequestOtp={setSendRequestOtp}
        >
            {renderMenuPage(activeMenu)}
        </Layout>
    );
}

export default withRouter(List);
