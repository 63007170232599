/* eslint-disable max-len */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LivePageWrapper from 'components/app/hocs/livestream/livePageWrapper';
import * as uiActionsLive from 'redux/actions/live/uiActionsLive';
import * as sessionActions from 'redux/actions/pages/actionsPages';

import './agenda.scss';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import { debounce, isEmpty } from 'lodash';
import SpeakerLogsModal from 'ComponentsV2/ProjectDashboard/Components/Virtual/agenda/speakers/speakerLogsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserImport from 'ComponentsV2/ProjectDashboard/Components/ExhibitionAndSponsors/users/userImport';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Locations from './locations';
import Sessions from './sessions';
import Speaker from './speakers';
import SlidingButtonUI from './utility/SlidingButtonUI/SlidingButtonUI';
import MobileInput from '../../../../../../../ComponentsV2/CommonComponents/MobileView/MobileInput/MobileInput';

function Agenda({ user }) {
    const { projectId, companyId } = useParams();
    const inputRef = useRef(null);
    const history = useHistory();
    const location = useLocation();

    const [currentTabs, setCurrentTabs] = useState(
        location?.hash?.slice(1) || 'sessions'
    );
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebounceSearchValue] = useState('');
    const [customWidth, setCustomWidth] = useState(144);
    const [showForm, toggleForm] = useState(false);
    const [currentIcon, setCurrentIcon] = useState('fa-search');
    const [isViewLogsModalOpen, setIsViewLogsModalOpen] = useState(false);
    const [showImportSession, setShowImportSession] = useState(false);

    const handleInputFocus = (icon) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setCustomWidth(210);
        setCurrentIcon(icon);
    };

    const handleInputBlur = (icon) => {
        setCustomWidth(144);
        setCurrentIcon(icon);
    };

    const handleClickIconX = () => {
        setSearchValue('');
        searchDebounce('');
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleChangeTap = (value) => {
        setSearchValue('');
        searchDebounce('');
        // toggleTabs(value);
        history.push(`#${value}`);
        setCurrentTabs(value);
    };

    const searchDebounce = useCallback(
        debounce((searchTerm) => setDebounceSearchValue(searchTerm), 200),
        []
    );

    const handleSearch = (search) => {
        const tab = currentTabs || 'sessions';
        switch (tab) {
            case 'sessions':
                setSearchValue(search);
                searchDebounce(search);
                break;
            case 'locations':
                setSearchValue(search);
                break;
            case 'speakers':
                setSearchValue(search);
                break;
        }
    };

    const handleImportClick = () => {
        history.push(`#import-sessions`);
        setShowImportSession(true);
    };

    const renderMobileView = () => {
        return (
            <>
                {(currentTabs === 'speakers' || currentTabs === 'sessions') && (
                    <div className="tw-flex tw-flex-col tw-w-full tw-mb-3 tw-items-start md:tw-hidden tw-py-3 tw-px-5">
                        <div className="agendaTitle tw-w-full tw-flex tw-gap-2 tw-items-end">
                            {currentTabs === 'speakers' && (
                                <>
                                    <button
                                        onClick={() =>
                                            history.push(
                                                `/company/${companyId}/project/${projectId}/agenda/speaker-import`
                                            )
                                        }
                                        className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-gray-btn-bg tw-rounded-md tw-bg-gray-btn-bg tw-text-gray-500 tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                                    >
                                        Import Speakers
                                    </button>
                                    <div className="tw-flex tw-flex-col tw-gap-2 tw-flex-shrink-0">
                                        <span
                                            className="tw-text-base tw-font-semibold tw-text-blue-600 tw-text-right tw-cursor-pointer"
                                            onClick={() =>
                                                setIsViewLogsModalOpen(true)
                                            }
                                        >
                                            View logs
                                        </span>
                                        <button
                                            className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                                            onClick={() => toggleForm(true)}
                                        >
                                            Create Speakers
                                        </button>
                                    </div>
                                </>
                            )}
                            {currentTabs === 'sessions' && (
                                <div className="tw-w-full tw-flex tw-justify-start tw-gap-2">
                                    <button
                                        onClick={handleImportClick}
                                        className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-gray-btn-bg tw-rounded-md tw-bg-gray-btn-bg tw-text-gray-500 tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                                    >
                                        Import Sessions
                                    </button>
                                    <button
                                        onClick={() =>
                                            toggleForm((prev) => !prev)
                                        }
                                        className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                                    >
                                        Create Sessions
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {currentTabs === 'locations' && (
                    <div className="sm:tw-hidden tw-block tw-w-full tw-mt-8" />
                )}
                <div className="tw-bg-white tw-p-4 tw-gap-4 tw-flex tw-flex-col tw-w-full md:tw-hidden">
                    <div className="tw-w-full">
                        <SlidingButtonUI
                            options={['Sessions', 'Speakers', 'Locations']}
                            action={handleChangeTap}
                            currentTab={currentTabs}
                        />
                    </div>
                    <div className="agenda-search-container tw-w-full">
                        <MobileInput
                            value={searchValue}
                            setValue={handleSearch}
                            placeholder={`Search ${currentTabs}`}
                        />
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            {showImportSession ? (
                <div className="tw-relative tw-w-full tw-flex tw-flex-col tw-gap-2 tw-h-[calc(100vh-14rem)] md:tw-h-[calc(100vh-9rem)]">
                    <h1 className="tw-m-0 tw-hidden md:tw-block">Agenda</h1>
                    <UserImport
                        isExhibitor
                        path={location.pathname}
                        cancelAction={() => setShowImportSession(false)}
                    />
                </div>
            ) : (
                <>
                    <div className="tw-w-full tw-mb-3 tw-justify-between tw-items-end tw-hidden md:tw-flex">
                        <h1 className="tw-m-0 ">Agenda</h1>
                        <div
                            className="agendaTitle tw-w-auto"
                            style={{ marginRight: 2 }}
                        >
                            {currentTabs === 'speakers' && (
                                <div className="tw-flex tw-gap-2 tw-items-center">
                                    <div className="agenda-search-container">
                                        <div className="agenda-search input-group">
                                            <input
                                                ref={inputRef}
                                                placeholder="Search speakers"
                                                style={{
                                                    width: `${customWidth}px`,
                                                }}
                                                value={searchValue}
                                                type="text"
                                                className="form-control m-0"
                                                onChange={(e) =>
                                                    handleSearch(e.target.value)
                                                }
                                                onClick={(e) =>
                                                    handleInputFocus('fa-close')
                                                }
                                                onFocus={(e) =>
                                                    handleInputFocus('fa-close')
                                                }
                                                onBlur={(e) =>
                                                    handleInputBlur('fa-search')
                                                }
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i
                                                        className={`fa ${currentIcon}`}
                                                        onClick={
                                                            handleClickIconX
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        className="tw-text-sm tw-font-semibold tw-text-blue-600 tw-pl-3 tw-cursor-pointer"
                                        onClick={() =>
                                            setIsViewLogsModalOpen(true)
                                        }
                                    >
                                        View logs
                                    </span>
                                    <button
                                        className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-none tw-border-b-gray-btn-bg tw-rounded-md tw-bg-gray-btn-bg tw-text-black tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer"
                                        onClick={() =>
                                            history.push(
                                                `/company/${companyId}/project/${projectId}/agenda/speaker-import`
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon="download"
                                            className="mr-2"
                                        />{' '}
                                        Import
                                    </button>
                                    <button
                                        className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer"
                                        onClick={() => toggleForm(true)}
                                    >
                                        Create Speaker
                                    </button>
                                </div>
                            )}
                            {currentTabs === 'sessions' && (
                                <div className="agenda-search-container tw-relative tw-top-[65px] tw-flex tw-gap-2">
                                    <Button
                                        icon={<DownloadOutlined />}
                                        className="tw-bg-[#efefef] tw-font-medium"
                                        onClick={handleImportClick}
                                    >
                                        Import session
                                    </Button>
                                    <div className="agenda-search input-group">
                                        <input
                                            ref={inputRef}
                                            placeholder="Search sessions"
                                            style={{
                                                width: `${customWidth}px`,
                                            }}
                                            value={searchValue}
                                            type="text"
                                            className="form-control m-0"
                                            onChange={(e) =>
                                                handleSearch(e.target.value)
                                            }
                                            onClick={(e) =>
                                                handleInputFocus('fa-close')
                                            }
                                            onFocus={(e) =>
                                                handleInputFocus('fa-close')
                                            }
                                            onBlur={(e) =>
                                                handleInputBlur('fa-search')
                                            }
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i
                                                    className={`fa ${currentIcon}`}
                                                    onClick={handleClickIconX}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        style={{ width: '180px' }}
                        className="tw-hidden md:tw-flex tw-mb-5"
                    >
                        <div
                            style={
                                currentTabs === 'sessions'
                                    ? null
                                    : { color: '#B4BFD2' }
                            }
                            className="sideTabs"
                            onClick={() => handleChangeTap('sessions')}
                        >
                            Sessions
                        </div>
                        <div
                            style={
                                currentTabs === 'speakers'
                                    ? null
                                    : { color: '#B4BFD2' }
                            }
                            onClick={() => handleChangeTap('speakers')}
                            className="sideTabs"
                        >
                            Speakers
                        </div>
                        {isEmpty(user?.exhibitorCompanyProfileId) && (
                            <div
                                style={
                                    currentTabs === 'locations'
                                        ? null
                                        : { color: '#B4BFD2' }
                                }
                                onClick={() => handleChangeTap('locations')}
                                className="sideTabs"
                            >
                                Locations
                            </div>
                        )}
                    </div>

                    {renderMobileView()}

                    {currentTabs === 'sessions' && (
                        <Sessions
                            search={debouncedSearchValue}
                            showForm={showForm}
                            toggleForm={toggleForm}
                            currentTabs={currentTabs}
                        />
                    )}
                    {currentTabs === 'speakers' && (
                        <Speaker
                            search={searchValue}
                            setSearch={setSearchValue}
                            showForm={showForm}
                            toggleForm={toggleForm}
                            currentTabs={currentTabs}
                        />
                    )}
                    {currentTabs === 'locations' && (
                        <Locations search={searchValue} />
                    )}

                    {isViewLogsModalOpen && (
                        <SpeakerLogsModal
                            open={isViewLogsModalOpen}
                            handleClose={() =>
                                setIsViewLogsModalOpen((prev) => !prev)
                            }
                        />
                    )}
                </>
            )}
        </>
    );
}

const AgendaWrapper = ({ user }) => {
    const { showcaseId } = useParams();
    const dispatch = useDispatch();

    //clear all session states
    dispatch(sessionActions.clearAllAgendaStates());
    if (showcaseId) {
        const WrappedComponent = LivePageWrapper(Agenda);
        return <WrappedComponent />;
    }
    //close submenu
    dispatch(uiActionsLive.updateSubMenuVisibility(false));

    return (
        <Layout background="#F7F9FA" className="tw-p-0">
            <Agenda user={user} />
            <SidebarContainer>
                <MainMenu priActive="agenda" all />
            </SidebarContainer>
        </Layout>
    );
};

export default AgendaWrapper;
