/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import Layout from 'components/app/common/layout';
import SponsorEditInputs from 'components/app/views/apps/sponsorship/sponsors/sponsors/sponsorEdit/inputs';
import SocialMediaLinks from 'components/app/views/apps/sponsorship/sponsors/sponsors/sponsorEdit/socialMediaLinks';

import { isString } from 'lodash';
import { UpdateCompany } from 'apis/rest/sponsorship/UpdateCompany';

import { GetCompany } from 'apis/rest/sponsorship/GetCompany';

import './sponsorDashboardEdit.scss';

function SponsorDashboardEdit() {
    const sponsorEditInputsRef = useRef();

    const { companyId, projectId } = useParams();

    const { showAlertBar } = useAlertBar();

    const [companyData, setCompanyData] = useState(null);
    const [companyInitialData, setCompanyInitialData] = useState(null);
    const [isRenderSocialMedia, setIsRenderSocialMedia] = useState(true);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        handleGetCompany();
    }, []);

    useEffect(() => {
        if (!companyData || !companyInitialData) return;

        if (initialLoad) {
            setInitialLoad(false);
            return;
        }

        const isDataChanged = Object.keys(companyData).some((key) => {
            if (key === 'description') {
                // Compare descriptions without considering basic HTML formatting
                const stripHtml = (str) =>
                    str.replace(/<\/?p>|<\/?br>/g, '').trim();
                return (
                    stripHtml(companyData[key]) !==
                    stripHtml(companyInitialData[key])
                );
            }
            return (
                JSON.stringify(companyData[key]) !==
                JSON.stringify(companyInitialData[key])
            );
        });

        setIsSaveEnabled(isDataChanged);
    }, [companyData, companyInitialData, initialLoad]);

    const handleGetCompany = async () => {
        const response = await GetCompany(companyId);
        if (response.status) {
            const resultData = response.sponsor;

            if (isString(resultData.socialMediaLinks)) {
                resultData.socialMediaLinks = JSON.parse(
                    resultData.socialMediaLinks
                );
            }
            setCompanyData(resultData);
            setCompanyInitialData(resultData);
            if (resultData.projectId === '6124c0c3ce2e89003ed19806') {
                setIsRenderSocialMedia(false);
            }
        }
    };

    const handleUpdateCompany = async () => {
        sponsorEditInputsRef.current
            .handleSubmitForm()
            .then((validationResult) => {
                if (validationResult) {
                    if (projectId === 'undefined') {
                        showAlertBar(
                            'Please re-login with your project id',
                            'error'
                        );
                        return;
                    }
                    onUpdateCompany();
                }
            })
            .catch((error) => {
                console.error('ERROR sponsorEditInputsRef', error);
            });
    };

    const onUpdateCompany = async () => {
        const inputs = {
            projectId,
            company: companyData.name,
            code: companyData.code,
            tagline: companyData.tagline,
            description: companyData.description,
            logo: companyData.logo,
            custom1: companyData.custom1,
            custom2: companyData.custom2,
            custom3: companyData.custom3,
            custom4: companyData.custom4,
            custom5: companyData.custom5,
            socialMediaLinks: JSON.stringify(companyData.socialMediaLinks),
        };
        const response = await UpdateCompany(companyId, inputs);

        if (response.status) {
            handleGetCompany();
            showAlertBar('Company updated!', 'success');
        } else {
            showAlertBar(response.message, 'error');
        }
    };

    return (
        <Layout background="rgb(243, 245, 250)" className="tw-p-0">
            <div className="container tw-p-0" style={{ maxWidth: '650px' }}>
                <div className="tw-hidden sm:tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between md:tw-gap-2 tw-w-full tw-mb-3 tw-items-center tw-py-3 tw-px-7 md:tw-p-7">
                    <div className="d-flex align-items-center md:tw-p-0">
                        <h1 className="mr-5 mb-0 tw-hidden md:tw-block">
                            {companyData?.name
                                ? companyData?.name
                                : 'Company Name'}
                        </h1>
                    </div>
                    <div className="tw-w-full md:tw-w-auto d-flex justify-content-start md:tw-justify-content-end align-items-center tw-p-0">
                        <button
                            className={`tw-w-full md:tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white sm:tw-text-sm tw-text-base sm:tw-font-medium tw-font-semibold tw-text-center tw-cursor-pointer ${
                                isSaveEnabled ? '' : 'tw-opacity-50'
                            }`}
                            onClick={() => handleUpdateCompany()}
                            disabled={!isSaveEnabled}
                        >
                            Save
                        </button>
                    </div>
                </div>
                {companyData && (
                    <div className="tw-p-7 sm:tw-pt-0 tw-pt-5">
                        <SponsorEditInputs
                            data={companyData}
                            onChangeData={(data) => setCompanyData(data)}
                            ref={sponsorEditInputsRef}
                        />
                        {isRenderSocialMedia && (
                            <SocialMediaLinks
                                data={companyData}
                                onChangeData={(data) => setCompanyData(data)}
                            />
                        )}
                        <button
                            className={`tw-w-full tw-mt-6 md:tw-w-auto md:tw-hidden tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white sm:tw-text-sm tw-text-base sm:tw-font-medium tw-font-semibold tw-text-center tw-cursor-pointer ${
                                isSaveEnabled ? '' : 'tw-opacity-50'
                            }`}
                            onClick={() => handleUpdateCompany()}
                            disabled={!isSaveEnabled}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
            <SidebarContainer>
                <MainMenu />
            </SidebarContainer>
        </Layout>
    );
}

SponsorDashboardEdit.propTypes = {};

export default SponsorDashboardEdit;
