/* eslint-disable max-len */
import React from 'react';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';
import { Col, Collapse, Row } from 'antd';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import { useDispatch, useSelector } from 'react-redux';
import FrontArrow from './FrontArrow';
import GevmeLogoLandscape from '../../../../../ComponentsV2/Icons/GevmeLogoLandscape';
import { DownOutlined } from '@ant-design/icons';

import {
    AnalyticsData,
    EngagementData,
    LeadsData,
    SetupData,
} from './constants/desktopCardData';

import styles from './index.module.scss';
import Links from './Links';

const { Panel } = Collapse;

const SponsorHomePage = () => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    const { sponsorMenuData, sponsorMenuOpenKeys } = useSelector(
        (state) => state?.sponsorMenu
    );

    const allowedSubLinks = [];

    for (let i = 0; i < sponsorMenuData.length; i++) {
        const ele = sponsorMenuData[i];
        if (ele?.link) allowedSubLinks.push(ele.link);

        if (ele?.sublinks && ele.sublinks.length > 0) {
            for (let j = 0; j < ele.sublinks.length; j++) {
                const slinkData = ele.sublinks[j];
                allowedSubLinks.push(slinkData.link);
            }
        }
    }

    const handlePanelChange = (panel = '') => {
        dispatch({
            type: 'UPDATE_SPONSOR_MENU',
            payload: {
                sponsorMenuOpenKeys: panel,
            },
        });
    };

    const Card = ({ data, style = {} }) => {
        const subLinkUI = [];
        if (data?.sublinks) {
            for (let i = 0; i < data.sublinks.length; i++) {
                const sublink = data.sublinks[i];
                if (allowedSubLinks.includes(sublink.link)) {
                    subLinkUI.push(
                        <div
                            className={styles.sponsorHomeCardLinkContainer}
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                        >
                            <Link
                                className={styles.sponsorHomeCardLink}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                to={`/company/${companyId}/project/${projectId}/${sublink.link}?sponsor=1`}
                            >
                                <FrontArrow />
                                <p>{sublink.name}</p>
                            </Link>
                            {sublink.description && (
                                <span
                                    className={
                                        styles.sponsorHomeCardLinkDescription
                                    }
                                >
                                    {sublink.description}
                                </span>
                            )}
                        </div>
                    );
                }
            }
        }
        if (subLinkUI.length === 0) return null;

        return (
            <div className={styles.sponsorHomeCard} style={style}>
                <h2 clasname={styles.sponsorHomeCardTitle}>{data.name}</h2>
                {data?.description && (
                    <span className={styles.sponsorHomeCardDescription}>
                        {data?.description}
                    </span>
                )}
                {subLinkUI}
            </div>
        );
    };

    const getCardData = (name) => {
        switch (name) {
            case 'Analytics':
                return AnalyticsData;
            case 'Engagement':
                return EngagementData;
            case 'Leads Generation':
                return LeadsData;
            case 'Setup':
                return SetupData;
            default:
                return null;
        }
    };
    const CustomExpandIcon = ({ isActive }) => (
        <DownOutlined
            rotate={isActive ? 180 : 0}
            style={{ fontSize: '12px', transition: 'transform 0.3s' }}
            className="sponsorCollapseIcon"
        />
    );

    const mobileCard = (cardName) => {
        const data = getCardData(cardName);
        if (!data) return;
        const subLinkUI = [];
        if (data?.sublinks) {
            for (let i = 0; i < data.sublinks.length; i++) {
                const sublink = data.sublinks[i];
                if (allowedSubLinks.includes(sublink.link)) {
                    subLinkUI.push(
                        <div
                            className={
                                styles.sponsorHomeCardLinkContainer + ' tw-mb-2'
                            }
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                        >
                            <Link
                                className={styles.sponsorHomeCardLink}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                                to={`/company/${companyId}/project/${projectId}/${sublink.link}?sponsor=1`}
                            >
                                <FrontArrow />
                                <span className="tw-text-base tw-text-bold">
                                    {sublink.name}
                                </span>
                            </Link>
                            {sublink.description && (
                                <span
                                    className={
                                        styles.sponsorHomeCardLinkDescription +
                                        ' tw-text-sm tw-text-[#475467] tw-leading-5'
                                    }
                                >
                                    {sublink.description}
                                </span>
                            )}
                        </div>
                    );
                }
            }
        }
        if (subLinkUI.length === 0) return null;

        return (
            <Collapse
                defaultActiveKey={sponsorMenuOpenKeys}
                ghost
                className="tw-flex tw-flex-col md:tw-hidden tw-gap-4 tw-mt-4"
                expandIconPosition="right"
                onChange={handlePanelChange}
            >
                <Panel
                    // eslint-disable-next-line react/no-array-index-key
                    key={data?.name}
                    header={
                        <>
                            <h2
                                clasname={
                                    styles.sponsorHomeCardTitle +
                                    ' tw-text-base tw-font-bold'
                                }
                            >
                                {data.name}
                            </h2>
                            {data?.description && (
                                <span
                                    className={
                                        styles.sponsorHomeCardDescription +
                                        ' tw-text-sm tw-text-[#475467] tw-leading-5 '
                                    }
                                >
                                    {data?.description}
                                </span>
                            )}
                        </>
                    }
                    className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-gap-2 tw-p-4 tw-pt-0 tw-pb-0 tw-border tw-border-[#EAECF0]"
                >
                    {subLinkUI}
                </Panel>
            </Collapse>
        );
    };

    const SetupCard = Card({ data: SetupData, style: { marginTop: '10px' } });

    const EngagementCard = Card({
        data: EngagementData,
        style: { marginTop: '10px' },
    });

    const LeadsCard = Card({ data: LeadsData, style: { marginTop: '10px' } });

    const AnalyticsCard = Card({
        data: AnalyticsData,
        style: { marginTop: '10px' },
    });

    const BoothCard = allowedSubLinks.includes('venue-builder') ? (
        <div
            className={`${styles.sponsorHomeCard} ${styles.sponsorHomeCustomCard} tw-hidden md:tw-block`}
            style={{ background: 'white', padding: '24px', marginTop: '10px' }}
        >
            <h2 className={styles.sponsorHomeCardTitle}>Access the Event</h2>
            <span style={{ display: 'block' }}>
                Connect with your booth audience and network with them.
            </span>
            <span
                style={{
                    display: 'block',
                    fontWeight: 600,
                    marginTop: '24px',
                }}
            >
                • Discover people
            </span>
            <span
                style={{
                    display: 'block',
                    fontWeight: 600,
                    marginTop: '16px',
                }}
            >
                • Get personalised recommendations
            </span>
            <button
                onClick={() => {
                    history.push(
                        `/company/${companyId}/project/${projectId}/venue-builder`
                    );
                }}
            >
                <p>Access your booth</p>
                <FrontArrow style={{ color: 'black' }} />
            </button>
        </div>
    ) : null;

    const MobileBoothCard = allowedSubLinks.includes('venue-builder') ? (
        <>
            <Collapse
                defaultActiveKey={sponsorMenuOpenKeys}
                ghost
                className="tw-flex tw-flex-col md:tw-hidden tw-gap-4 tw-mt-4"
                expandIconPosition="right"
                onChange={handlePanelChange}
            >
                <Panel
                    // eslint-disable-next-line react/no-array-index-key
                    key={'MobileBoothCard'}
                    header={
                        <>
                            <h2
                                clasname={
                                    styles.sponsorHomeCardTitle +
                                    ' tw-text-base tw-font-bold'
                                }
                            >
                                Access the Event
                            </h2>
                            <span
                                className={
                                    styles.sponsorHomeCardDescription +
                                    ' tw-text-sm tw-text-[#475467] tw-leading-5 '
                                }
                            >
                                Connect with your booth audience and network
                                with them.
                            </span>
                        </>
                    }
                    className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-gap-2 tw-p-4 tw-pt-0 tw-pb-0 tw-border tw-border-[#EAECF0]"
                >
                    <div className={`tw-flex tw-flex-col`}>
                        <span>• Discover people</span>
                        <span>• Get personalised recommendations</span>
                        <button
                            className={styles.MobileBoothCardButton}
                            onClick={() => {
                                history.push(
                                    `/company/${companyId}/project/${projectId}/venue-builder`
                                );
                            }}
                        >
                            <span>Access your booth</span>
                            <FrontArrow style={{ color: 'black' }} />
                        </button>
                    </div>
                </Panel>
            </Collapse>
        </>
    ) : null;

    const totalCards =
        3 -
        (SetupCard ? 0 : 1) -
        (EngagementCard ? 0 : 1) -
        (AnalyticsCard || BoothCard ? 0 : 1);

    return (
        <Layout allowFixed={false} background="#F7F9FA">
            <SidebarContainer>
                <MainMenu priActive="sponsorHome" all />
            </SidebarContainer>

            <div
                className={`${styles.sponsorHome} tw-flex tw-text-mobileTextBlack tw-flex-col tw-gap-1 tw-mt-5 md:tw-mt-[50px]`}
            >
                <div className="tw-flex tw-text-[17px] tw-mb-4 md:tw-gap-1 tw-gap-4 tw-items-center tw-font-semibold">
                    <GevmeLogoLandscape /> Exhibitor Dashboard
                </div>
                <h1
                    className={`${styles.sponsorHomeTitle} tw-flex tw-font-semibold tw-text-mobileTextBlack tw-font-openSans md:tw-font-bold tw-flex-col tw-text-[22px]`}
                >
                    {`Welcome, ${user?.firstname || 'User'}!`}
                    <span>Here are your action items</span>
                </h1>
                <Row
                    className={`${styles.sponsorHomeCardContainer} tw-hidden md:tw-flex`}
                    gutter={24}
                >
                    {SetupCard && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {SetupCard}
                        </Col>
                    )}
                    {(EngagementCard || LeadsCard) && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {EngagementCard}
                            {LeadsCard}
                        </Col>
                    )}
                    {(AnalyticsCard || BoothCard) && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {BoothCard}
                            {AnalyticsCard}
                        </Col>
                    )}
                </Row>
                <Collapse
                    defaultActiveKey={sponsorMenuOpenKeys}
                    ghost
                    className="tw-flex tw-flex-col md:tw-hidden tw-gap-4 tw-mt-4 sponsorCollapse"
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                        <CustomExpandIcon isActive={isActive} />
                    )}
                    onChange={handlePanelChange}
                >
                    {sponsorMenuData?.map((card, index) =>
                        card?.link !== 'sponsor-home' ? (
                            <Panel
                                // eslint-disable-next-line react/no-array-index-key
                                key={card?.name}
                                header={
                                    <span className="mb-0 tw-flex tw-text-lg tw-font-openSans tw-text-mobileTextBlack tw-font-semibold tw-flex-1 tw-items-center tw-gap-2">
                                        <img
                                            src={`/assets/sponsor-menu/outlined/${card.icon}`}
                                            height={21}
                                            width={21}
                                            alt={card?.name || 'Icon'}
                                        />
                                        {card?.name}
                                    </span>
                                }
                                className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-gap-2 tw-p-4 tw-pt-0 tw-pb-0 tw-border tw-border-[#EAECF0]"
                            >
                                <Links card={card} />
                            </Panel>
                        ) : (
                            ''
                        )
                    )}
                    <style>
                        {`
                    .sponsorCollapseIcon{
                        position: absolute;
                        right: 0;
                        top: 20px;
                    }
                    `}
                    </style>
                </Collapse>
            </div>
        </Layout>
    );
};
export default withRouter(SponsorHomePage);