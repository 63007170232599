import * as ls from 'local-storage';
import { errorHandler } from 'Apps/VenueBuilder/redux/globalActions';
import sanitizeContent from 'utils/InputSanitization';

import {
    UPDATE_SIDEBAR_MENU,
    CATCH_ERROR,
    UPDATE_VERSION,
    ADD_CONTENT_BLOCK_SUCCESS,
    EDIT_CONTENT_BLOCK_SUCCESS,
    UPDATE_BLOCK_ORDER,
    UPDATE_BLOCK_VISIBILITY,
    UPLOAD_IMAGE_SUCCESS,
    DELETE_BLOCK_SUCCESS,
    UPDATE_LIVEBAR_SETTINGS,
    DELETE_IMAGE,
    REMOVE_IMAGE_URL,
    SET_ACTIVE_CONTENT,
    SET_PREVIEW_LOADING,
    SET_SUBMIT_LOADING,
    GEVME_LIVE_CHANNELS,
    GEME_LIVE_PEOPLE,
} from '../../../redux/actionTypes';
import { openNotification } from '../../../helpers/openNotification';
import { store } from 'Apps/VenueBuilder/redux/store';
import { updateVersionTimeStamp } from '../../../redux/globalActions';
let authBearer = null,
    projectId = null;
//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
        projectId = store.getState()?.header?.selection.projectId;
    } catch (error) {
        console.error(error);
    }
};

export const updateSidebarMenu = (menu, item) => (dispatch) => {
    dispatch({
        type: UPDATE_SIDEBAR_MENU,
        payload: {
            menu,
            item,
        },
    });
};

export const updateLiveBarSettings = (data) => async (dispatch, getState) => {
    const { sidebar, header } = getState();
    const { widgets } = sidebar;
    const { livepageId } = header.selection;

    try {
        dispatch({ type: SET_PREVIEW_LOADING, payload: true });

        setAuthBearer();

        const widget =
            data.widget === 'audio'
                ? {
                      ...data,
                      features: {
                          ...data.features,
                          audio:
                              data?.features?.audio?.id ||
                              data?.features?.audio,
                      },
                  }
                : data;

        const theWidgets = widgets.map((widget) =>
            widget.widget === data.widget ? data : widget
        );

        dispatch({
            type: UPDATE_LIVEBAR_SETTINGS,
            payload: theWidgets,
        });

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${livepageId}/settings?projectId=${projectId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body: JSON.stringify(widget),
            }
        );

        const res = await response.json();

        if (res.status) {
            dispatch({
                type: UPDATE_VERSION,
                payload: 'tosave',
            });

            dispatch({ type: SET_PREVIEW_LOADING, payload: false });

            return res;
        } else throw res;
    } catch (error) {
        dispatch({
            type: UPDATE_LIVEBAR_SETTINGS,
            payload: widgets,
        });
        dispatch({ type: SET_PREVIEW_LOADING, payload: false });

        openNotification('error', { message: error.message });
        return error;
    }
};

// Create a Block
export const createContentBlock = (block, showcaseId, blockId = null, isDuplicate = false) => async (dispatch) => {
    try {
        dispatch({ type: SET_PREVIEW_LOADING, payload: true });
        dispatch({ type: SET_SUBMIT_LOADING, payload: { status: true } });

        setAuthBearer();

        if (block?.heading)
            block.heading = sanitizeContent({
                str: block.heading,
                type: 'heading',
            });
        if (block?.title)
            block.title = sanitizeContent({
                str: block.title,
                type: 'heading',
            });
        if (block?.textContent)
            block.textContent = sanitizeContent({
                str: block.textContent,
                type: 'richtext',
            });

        const body = isDuplicate ? JSON.stringify({
                contentBlockId: blockId,
                }) : JSON.stringify(block);

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${showcaseId}/content-block${isDuplicate ? '/duplicate' : ''}?projectId=${projectId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body,
            }
        );

        const res = await response.json();
        if(isDuplicate){
            const { status, message, ...rest } = res;
            block = rest;
        }
        if (res.status) {
            // return dispatch event if successful
            dispatch({
                type: UPDATE_VERSION,
                payload: 'tosave',
            });

            let blockData = block;
            if (['Image', 'ImageList', 'ImageSlideshow'].includes(block.type)) {
                blockData = {
                    ...block,
                    title: '',
                    imageBlock: {
                        images: block.images,
                    },
                };
                if (block.type === 'ImageSlideshow') {
                    blockData.imageBlock.autoScrollEnabled =
                        block.autoScrollEnabled;
                    blockData.imageBlock.autoScrollTimeInterval =
                        block.autoScrollTimeInterval;
                }
            } else if (block.type === 'RichText') {
                blockData = {
                    ...block,
                    title: '',
                };
            } else if (block.type === 'HeroContent') {
                blockData = {
                    ...block,
                    heroContentBlock: {
                        link: block.link,
                        coverImage: block.coverImage,
                        coverLivestreamOrVideo: block.coverLivestreamOrVideo,
                    },
                };
            } else if (block.type === 'LiveStream') {
                blockData = {
                    ...block,
                    title: '',
                    updateBlockType: null,
                    livestreamBlock: {
                        streamingService: block.streamingService,
                        livestreamUrl: block.livestreamUrl,
                        displaySomethingWhenNotLive:
                            block.displaySomethingWhenNotLive,
                        displayImageWhenNotLive: block.displayImageWhenNotLive,
                        displayVideoWhenNotLive: block.displayVideoWhenNotLive,
                        disableVideoQualityOptions:
                            block.disableVideoQualityOptions,
                        disableAutoplay: block.disableAutoplay,
                        channelId: block.channelId,
                    },
                };
            } else if (block.type === 'Resources' || block.type === 'Pdf') {
                blockData = {
                    ...block,
                    title: '',
                    resourcesBlock: {
                        resources: res.resources,
                        enableItemCTA: block.enableItemCTA,
                        enableItemIcon: block.enableItemIcon,
                        enableBookmark: block.enableBookmark,
                        ctaColor: block.ctaColor,
                    },
                };
            } else if (block.type === 'FeaturedContent') {
                blockData = {
                    ...block,
                    featuredContentBlock: {
                        featuredcontent: res.featuredcontent,
                        cardWidth: block.cardWidth,
                        cardHeight: block.cardHeight,
                        cardTitleVisibility: block.cardTitleVisibility,
                        cardDescriptionVisibility:
                            block.cardDescriptionVisibility,
                        cardBoundingBox: block.cardBoundingBox,
                        cardPadding: block.cardPadding,
                    },
                };
            } else if (block.type === 'Html') {
                blockData = {
                    ...block,
                    htmlBlock: {
                        html: block.html,
                    },
                };
            } else if (block.type === 'Link') {
                blockData = {
                    ...block,
                    linkBlock: {
                        enableLink: block.enableLink,
                        link: block.link,
                        openInNewWindow: block.openInNewWindow,
                    },
                };
            }
                dispatch({
                    type: ADD_CONTENT_BLOCK_SUCCESS,
                    payload: {
                        ...blockData,
                        id: res.id,
                        name: res.name,
                    },
                });

                if(isDuplicate){
                    dispatch({
                        type: UPDATE_SIDEBAR_MENU,
                        payload: {
                            menu: "default",
                            item: undefined,
                        },
                    });

                    dispatch({
                        type: UPDATE_SIDEBAR_MENU,
                        payload: {
                            menu: blockData?.type,
                            item: blockData,
                        },
                    });
                }

            dispatch({ type: SET_PREVIEW_LOADING, payload: false });
            dispatch({ type: SET_SUBMIT_LOADING, payload: { status: false } });
            return res;
        } else {
            throw res.message;
        }

        // Return error and no dispatch event to trigger
        // Will Implement error reducer that'll be globally distributed to all components.
    } catch (error) {
        dispatch({ type: SET_PREVIEW_LOADING, payload: false });
        dispatch({ type: SET_SUBMIT_LOADING, payload: { status: false } });
        openNotification('error', {
            message: error,
        });
    }
};

// Update specific block
export const updateSpecificBlock =
    (block, showcaseId, showMessage = true) =>
    async (dispatch, getStore) => {
        try {
            setAuthBearer();

            const type = getStore()?.header?.selection?.type;

            dispatch({ type: SET_PREVIEW_LOADING, payload: true });
            dispatch({ type: SET_SUBMIT_LOADING, payload: { status: true } });

            if (block?.title)
                block.title = sanitizeContent({
                    str: block.title,
                    type: 'heading',
                });
            if (block?.heading)
                block.heading = sanitizeContent({
                    str: block.heading,
                    type: 'heading',
                });
            if (block?.textContent)
                block.textContent = sanitizeContent({
                    str: block.textContent,
                    type: 'richtext',
                });

            if (block?.blockVisibility) delete block.blockVisibility;

            let response;

            if (type && type === 'widget') {
                response = await fetch(
                    `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/update/${showcaseId}/content-block/${block.id}?projectId=${projectId}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            // 'x-api-key': process.env.REACT_APP_X_API_KEY,
                            Authorization: authBearer,
                        },
                        body: JSON.stringify(block),
                    }
                );
            } else {
                response = await fetch(
                    `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${showcaseId}/content-block/${block.id}?projectId=${projectId}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            // 'x-api-key': process.env.REACT_APP_X_API_KEY,
                            Authorization: authBearer,
                        },
                        body: JSON.stringify(block),
                    }
                );
            }

            const res = await response.json();
            const existingBlocks = getStore().sidebar.blocks;
            const prevStateLiveStreamBlock = existingBlocks.find(
                (existingBlock) => existingBlock?.id === block?.id
            );

            if (res.status) {
                // return dispatch event if successful
                dispatch({
                    type: UPDATE_VERSION,
                    payload: 'tosave',
                });

                if (type && type === 'widget') {
                    dispatch(
                        updateVersionTimeStamp('save', showcaseId, projectId)
                    );
                }

                let blockData = block;
                if (
                    ['Image', 'ImageList', 'ImageSlideshow'].includes(
                        block.type
                    )
                ) {
                    blockData = {
                        ...block,
                        imageBlock: {
                            images: block.images,
                        },
                    };
                    if (block.type === 'ImageSlideshow') {
                        blockData.imageBlock.autoScrollEnabled =
                            block.autoScrollEnabled;
                        blockData.imageBlock.autoScrollTimeInterval =
                            block.autoScrollTimeInterval;
                    }
                } else if (block.type === 'HeroContent') {
                    blockData = {
                        ...block,
                        heroContentBlock: {
                            link: block.link,
                            coverImage: block.coverImage,
                            coverLivestreamOrVideo:
                                block.coverLivestreamOrVideo,
                        },
                    };
                } else if (block.type === 'LiveStream') {
                    if (block?.livestreamUrl) {
                        blockData = {
                            ...block,
                            livestreamBlock: {
                                streamingService: block.streamingService,
                                livestreamUrl: block.livestreamUrl,
                                displaySomethingWhenNotLive:
                                    block.displaySomethingWhenNotLive,
                                displayImageWhenNotLive:
                                    block.displayImageWhenNotLive,
                                displayVideoWhenNotLive:
                                    block.displayVideoWhenNotLive,
                                disableVideoQualityOptions:
                                    block.disableVideoQualityOptions,
                                disableAutoplay: block.disableAutoplay,
                                channelId: block.channelId,
                                captionHtml:
                                    prevStateLiveStreamBlock?.livestreamBlock
                                        ?.captionHtml || '',
                            },
                        };
                    } else {
                        blockData = {
                            ...prevStateLiveStreamBlock,
                            id: block?.id,
                            livestreamBlock: {
                                ...prevStateLiveStreamBlock?.livestreamBlock,
                                captionHtml: block?.captionHtml,
                                id: res?.livestreamBlock,
                            },
                        };
                    }
                } else if (block.type === 'Resources' || block.type === 'Pdf') {
                    blockData = {
                        ...block,
                        resourcesBlock: {
                            resources: res.resources,
                            enableItemCTA: block.enableItemCTA,
                            enableItemIcon: block.enableItemIcon,
                            enableBookmark: block.enableBookmark,
                            ctaColor: block.ctaColor,
                        },
                    };
                } else if (block.type === 'FeaturedContent') {
                    blockData = {
                        ...block,
                        featuredContentBlock: {
                            featuredcontent: res?.featuredcontent,
                            cardWidth: block.cardWidth,
                            cardHeight: block.cardHeight,
                            cardTitleVisibility: block.cardTitleVisibility,
                            cardDescriptionVisibility:
                                block.cardDescriptionVisibility,
                            cardBoundingBox: block.cardBoundingBox,
                            cardPadding: block.cardPadding,
                        },
                    };
                } else if (block.type === 'Html') {
                    blockData = {
                        ...block,
                        htmlBlock: {
                            html: block.html,
                        },
                    };
                } else if (block.type === 'Link') {
                    blockData = {
                        ...block,
                        linkBlock: {
                            enableLink: block.enableLink,
                            link: block.link,
                            openInNewWindow: block.openInNewWindow,
                        },
                    };
                }

                dispatch({
                    type: EDIT_CONTENT_BLOCK_SUCCESS,
                    payload: {
                        id: res.id,
                        ...blockData,
                    },
                });
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });
                dispatch({
                    type: SET_SUBMIT_LOADING,
                    payload: { status: false },
                });
                // on success redirect to default sidebar
                if (showMessage) {
                    dispatch({
                        type: SET_ACTIVE_CONTENT,
                        payload: 'content',
                    });
                }

                return res;
            } else {
                throw res.message;
            }

            // return error and no dispatch event to trigger
            // Will Implement error reducer that'll be globally distributed to all components.
        } catch (error) {
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });
            dispatch({ type: SET_SUBMIT_LOADING, payload: { status: false } });
            openNotification('error', {
                message: error,
            });
        }
    };

// Update all blocks
export const updateAllBlocks =
    (blocks, showcaseId) => async (dispatch, getStore) => {
        dispatch({ type: SET_PREVIEW_LOADING, payload: true });

        const existingBlocks = getStore().sidebar.blocks;
        try {
            dispatch({ type: UPDATE_BLOCK_ORDER, payload: blocks }); //updates the order

            let newOrderedBlocks = blocks.map(({ id }, index) => ({
                id,
                order: index,
            }));

            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${showcaseId}/content-block/bulk-update?projectId=${projectId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                    body: JSON.stringify({ blocks: newOrderedBlocks }),
                }
            );

            const res = await response.json();

            if (res.status) {
                // return dispatch event if successful
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });

                dispatch({
                    type: UPDATE_VERSION,
                    payload: 'tosave',
                });

                return;
            } else {
                throw 'Update failed!';
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });

            dispatch({ type: UPDATE_BLOCK_ORDER, payload: existingBlocks }); //revert back the blocks order
            openNotification('error', {
                message: error,
            });
        }
    };

// Delete block
export const deleteBlock =
    (blockId, showcaseId, showMessage = true) =>
    async (dispatch) => {
        try {
            dispatch({ type: SET_PREVIEW_LOADING, payload: true });
            dispatch({
                type: SET_SUBMIT_LOADING,
                payload: { status: true, type: 'delete' },
            });
            setAuthBearer();
            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${showcaseId}/content-block/${blockId}?projectId=${projectId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                }
            );

            const res = await response.json();

            if (res.status) {
                dispatch({
                    type: DELETE_BLOCK_SUCCESS,
                    payload: blockId,
                });
                // showMessage by default is true, it goes to false on swapping blocks
                if (showMessage) {
                    dispatch({
                        type: UPDATE_SIDEBAR_MENU,
                        payload: {
                            menu: 'default',
                        },
                    });
                    dispatch({ type: SET_PREVIEW_LOADING, payload: false });

                    dispatch({ type: UPDATE_VERSION, payload: 'tosave' });
                    openNotification('success', {
                        message: res.message,
                    });
                }
                dispatch({
                    type: SET_SUBMIT_LOADING,
                    payload: { status: false, type: 'delete' },
                });
                return res;
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });
            dispatch({
                type: SET_SUBMIT_LOADING,
                payload: { status: true, type: 'delete' },
            });

            openNotification('error', {
                message: error,
            });
        }

        // return error and no dispatch event to trigger
        // Will Implement error reducer that'll be globally distributed to all components.

        // return errorHandler(res); // Global handler of error
    };


//Update Content block visibility
export const updateBlockVisibility =
    (blockId, livepageId, reqVisibility) => async (dispatch, getStore) => {
        dispatch({ type: SET_PREVIEW_LOADING, payload: true });

        const currentBlocks = getStore().sidebar.blocks;
        const blockIndex = currentBlocks.findIndex(({ id }) => id === blockId);
        try {
            currentBlocks[blockIndex]['blockVisibility'] = reqVisibility;
        } catch (error) {
            console.error(error);
        }

        try {
            dispatch({ type: UPDATE_BLOCK_VISIBILITY, payload: currentBlocks });
            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${livepageId}/content-block/${blockId}?projectId=${projectId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                    body: JSON.stringify({
                        blockVisibility: reqVisibility,
                    }),
                }
            );

            const res = await response.json();

            if (res.status) {
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });

                dispatch({ type: UPDATE_VERSION, payload: 'tosave' });
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            console.error(error);
            currentBlocks[blockIndex]['blockVisibility'] = !reqVisibility;
            dispatch({ type: UPDATE_BLOCK_VISIBILITY, payload: currentBlocks });
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });

            openNotification('error', { message: error.message });
        }
    };

// Upload image
export const uploadImage = (file) => async (dispatch) => {
    try {
        setAuthBearer();

        dispatch({
            type: SET_SUBMIT_LOADING,
            payload: { status: true, type: 'upload' },
        });

        let formData = new FormData();

        formData.append('projectId', file.projectId);
        formData.append('showcaseId', file.showcaseId);

        for (let i = 0; i < file.files.length; i++) {
            formData.append(`files`, file.files[i]);
        }

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/upload${file?.isPdf ? '/pdf' : ''}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: formData,
            }
        );

        const res = await response.json();
        dispatch({
            type: SET_SUBMIT_LOADING,
            payload: { status: false, type: '' },
        });
        if (res.success) {
            // return dispatch event if successful
            dispatch({
                type: UPLOAD_IMAGE_SUCCESS,
                payload: {
                    url: file?.isPdf ? res.file : res.files,
                },
            });

            if (file?.isPdf) {
                return res.file;
            }

            return file?.files?.length > 1 ? res.files : res.files[0];
        }else throw res;
    } catch (error) {
        openNotification('error', {
            message: error?.message || `Image upload failed: ${error}`,
        });
    }

    // return error and no dispatch event to trigger
    // Will Implement error reducer that'll be globally distributed to all components.

};

export const removeImageUrl = (item) => (dispatch) => {
    dispatch({
        type: REMOVE_IMAGE_URL,
        payload: item,
    });
};

export const deleteImage = (item) => (dispatch) => {
    dispatch({
        type: DELETE_IMAGE,
        payload: item,
    });
};

// Get All Buttons Styles
export const getAllButtonsStyles = (projectId) => async (dispatch) => {
    try {
        setAuthBearer();

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/button-styles?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        const res = await response.json();

        if (res.status) {
            return res?.buttonStyles || [];
        }
    } catch (error) {
        openNotification('error', {
            message: error,
        });
    }
};

// Update single button style
export const updateSingleButtonStyle =
    (projectId, styleId, params) => async (dispatch) => {
        try {
            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/button-styles/${styleId}?projectId=${projectId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                    body: JSON.stringify(params),
                }
            );

            const res = await response.json();

            if (!res.status) {
                openNotification('error', {
                    message: 'Request failed',
                });
            }
        } catch (error) {
            openNotification('error', {
                message: error,
            });
        }
    };

// Get criteria for showcase block visibility
export const getCriteriaShowcaseBlock =
    (projectId, blockId) => async (dispatch) => {
        try {
            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API}/manager/livestream/block-criteria/${projectId}/showcase-blocks/${blockId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                }
            );

            const res = await response.json();

            if (res.status) {
                return res.criteria;
            } else {
                throw 'Update failed!';
            }
        } catch (error) {
            openNotification('error', {
                message: error,
            });
        }
    };

// Get project fields
export const getProjectFields = (projectId) => async (dispatch) => {
    try {
        setAuthBearer();

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/projects/${projectId}/getFieldsOfProject?environment=${process.env.REACT_APP_ENVIRONMENT}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        const res = await response.json();

        if (res.status) {
            return res;
        }
    } catch (error) {
        openNotification('error', {
            message: error,
        });
    }
};

// Create/Update criteria for showcase block visibility
export const createUpdateCriteriaShowcaseBlock =
    (criteria, projectId, blockId) => async (dispatch) => {
        try {
            dispatch({ type: SET_PREVIEW_LOADING, payload: true });
            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API}/manager/livestream/block-criteria/${projectId}/showcase-blocks/${blockId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                    body: JSON.stringify(criteria),
                }
            );

            const res = await response.json();

            if (res.status) {
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });
            } else {
                throw 'Failed to save or edit data!';
            }
        } catch (error) {
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });

            openNotification('error', {
                message: error,
            });
        }
    };

// Delete criteria for showcase block visibility
export const deleteCriteriaShowcaseBlock =
    (projectId, blockId) => async (dispatch) => {
        try {
            dispatch({ type: SET_PREVIEW_LOADING, payload: true });

            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API}/manager/livestream/block-criteria/${projectId}/showcase-blocks/${blockId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                }
            );

            const res = await response.json();

            if (res.status) {
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });
            } else {
                throw 'Update failed!';
            }
        } catch (error) {
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });

            openNotification('error', {
                message: error,
            });
        }
    };

// Set active content
export const setActiveContent = (content) => async (dispatch) => {
    dispatch({
        type: SET_ACTIVE_CONTENT,
        payload: content,
    });
};

// Get lists of channels
export const getLivePageChannels =
    (projectId, search = '') =>
    async (dispatch) => {
        try {
            setAuthBearer();

            const response = await fetch(
                `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/channels/allChannels?projectId=${projectId}&search=${search}&status=live`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authBearer,
                    },
                }
            );

            const res = await response.json();

            if (res.status) {
                // return res;
                dispatch({ type: GEVME_LIVE_CHANNELS, payload: res });
            }
        } catch (error) {
            openNotification('error', {
                message: error,
            });
        }
    };

// Get lists
export const getLivePageSponsors = (projectId) => async (dispatch) => {
    try {
        const auth = ls.get('auth');
        const authBearer = `Bearer ${auth.access_token}`;
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/company-profiles?limit=50&projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        const res = await response.json();

        if (res.status) {
            // return res;
            dispatch({ type: GEVME_LIVE_CHANNELS, payload: res });
        }
    } catch (error) {
        openNotification('error', {
            message: error,
        });
    }
};

export const getPeopleDetails = (projectId, srchVal) => async (dispatch) => {
    try {
        const auth = ls.get('auth');
        const authBearer = `Bearer ${auth.access_token}`;
        const response = await fetch(
            `${
                process.env.REACT_APP_MYXP_API
            }/manager/livestream/projects/${projectId}/getPeople?environment=${
                process.env.REACT_APP_ENVIRONMENT
            }&search=${srchVal ? srchVal : ''}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        const res = await response.json();

        if (response.status) {
            dispatch({ type: GEME_LIVE_PEOPLE, payload: res });
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};
