import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OTPInput from 'otp-input-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Tooltip } from 'antd';

import { requestOTP } from 'ComponentsV2/ProjectDashboard/API';
import { updateProfile } from 'apis/rest/UpdateProfile';
import { updateUser } from 'redux/actions/common/userActions';

import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Plans from '../../../../../ComponentsV2/CommonUtils/IdentifyPlan';
import { getFullname } from '../../../utils/functions/common';
import Modal from '../../modal';
import { EditIcon, InfoIcon } from './icons';

const inputStyles = {
    width: '45px',
    height: '40px',
    borderWidth: '1px',
    borderRadius: '0.375rem',
};

export default function UserProfileEdit({
    user,
    showUserProfileEdit,
    setShowUserProfileEdit,
}) {
    const { STARTER_PLAN } = Plans;
    const { companyId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const companyDetails = useSelector((state) => state?.projects?.company);

    const [userFirstName, setUserFirstName] = useState(user?.firstname || '');
    const [userLastName, setUserLastName] = useState(user?.lastname || '');
    const [newEmail, setNewEmail] = useState('');
    const [inputError, setInputError] = useState(false);
    const [isEmailChangeLocked, setIsEmailChangeLocked] = useState(true);
    const [oldEmailOtp, setOldEmailOtp] = useState('');
    const [newEmailOtp, setNewEmailOtp] = useState('');
    const [newEmailOtpSent, setNewEmailOtpSent] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const fullName = getFullname(user);
    const maxNameLength = 15;

    const debouncedUpdateUserField = debounce((fieldName, value) => {
        switch (fieldName) {
            case 'firstname':
                setUserFirstName(value);
                break;
            case 'lastname':
                setUserLastName(value);
                break;
            case 'email':
                setNewEmail(value);
                break;
            default:
                break;
        }
    }, 250);

    async function requestOtpHandler(isNewEmail = false) {
        try {
            if (loading) return;
            setLoading(true);
            setError('');
            setOldEmailOtp('');
            setNewEmailOtp('');
            const response = await requestOTP(
                isNewEmail ? newEmail : user?.email
            );
            if (!response.status) {
                throw response;
            }
            setIsEmailChangeLocked(false);
            if (isNewEmail) {
                setNewEmailOtpSent(true);
            }
        } catch (error) {
            console.error(error);
            setError(error?.message || 'Something went wrong');
        } finally {
            setLoading(false);
            setSuccess('');
        }
    }

    async function updateUserProfileHandler() {
        try {
            setLoading(true);
            if (inputError) throw new Error('Enter valid name!');
            const payload = {
                firstname: userFirstName,
                lastname: userLastName,
            };

            if (!isEmailChangeLocked) {
                payload.email = newEmail;
                payload.code1 = oldEmailOtp;
                payload.code2 = newEmailOtp;
            }

            const res = await updateProfile(payload);
            if (res && res.status) {
                delete payload.code1;
                delete payload.code2;
                dispatch(
                    updateUser({
                        user: {
                            ...user,
                            ...payload,
                        },
                    })
                );
                openNotification('success', {
                    message: 'Profile updated!',
                });
                if (isEmailChangeLocked) {
                    setShowUserProfileEdit(false);
                } else {
                    setIsEmailChangeLocked(true);
                    setNewEmail('');
                    setSuccess('Your email has been changed successfully');
                    setError('');
                }
            } else throw res;
        } catch (error) {
            console.error(error);
            setError(error?.message || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    function resetOtpHandler() {
        setOldEmailOtp('');
        setNewEmailOtp('');
    }

    function isValidInput(inputValue) {
        const disallowedCharsRegex = /[|;$%@"<>.()/:{}\[\]\\^~`*!#=?_&,+]/;
        return disallowedCharsRegex.test(inputValue);
    }

    useEffect(() => {
        setNewEmailOtpSent(false);
        setError(
            newEmail === user?.email
                ? 'Your new email is same as your current email, please enter a different email'
                : ''
        );
        setSuccess('');
        resetOtpHandler();
    }, [newEmail]);
    useEffect(() => {
        setInputError(
            isValidInput(userFirstName) || isValidInput(userLastName)
        );
    }, [userFirstName, userLastName]);

    const getOtpInput = (otp, setOtp, text) => (
        <div className="md:tw-col-span-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-transition-all tw-duration-1000 tw-ease-in-out">
            <p className="tw-m-0 tw-p-0 tw-mb-4 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-4/5">
                {text}
            </p>
            <OTPInput
                inputStyles={inputStyles}
                className="tw-font-bold tw-text-lg"
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={loading}
            />
            <p
                className="tw-w-auto tw-m-0 tw-p-0 tw-mt-2 tw-font-openSans tw-text-sm tw-font-semibold tw-text-center tw-text-blue-500 tw-cursor-pointer"
                onClick={resetOtpHandler}
            >
                Reset OTP
            </p>
        </div>
    );

    return (
        <Modal
            visible={showUserProfileEdit}
            onClose={() => setShowUserProfileEdit(false)}
            showCloseIcon
            preventCloseOnOutsideClick
        >
            <div className="md:tw-flex md:tw-items-center md:tw-justify-between tw-mt-5 tw-pb-8">
                <div className="tw-text-gray-400 tw-font-semibold">
                    <span className="tw-text-base tw-text-gray-400">
                        {fullName?.length > maxNameLength ? (
                            <Tooltip
                                placement="top"
                                title={fullName}
                                zIndex={9999}
                            >
                                <span className="tw-p-0 tw-m-0 tw-break-words">
                                    {fullName.slice(0, maxNameLength - 1) +
                                        ' ...'}
                                </span>
                            </Tooltip>
                        ) : (
                            fullName
                        )}
                    </span>{' '}
                    /
                    <span className="tw-text-base tw-text-black tw-font-semibold">
                        {' '}
                        Edit Profile
                    </span>
                </div>
                {companyDetails?.package?.id === STARTER_PLAN && (
                    <div className="tw-text-black tw-font-semibold tw-flex tw-flex-row tw-items-center">
                        <span className="tw-font-openSans tw-text-xs tw-text-black tw-font-bold tw-pr-1">
                            Starter plan
                        </span>
                        <button
                            type="button"
                            className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-font-openSans tw-shadow-sm tw-px-4 tw-py-2 tw-bg-green-700 tw-text-xssm tw-font-bold tw-text-white hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-green-500 md:tw-mt-0 md:tw-mr-5 md:tw-ml-3 md:tw-w-auto md:tw-text-sm"
                            onClick={() =>
                                history.push(`/v2/company/${companyId}/upgrade`)
                            }
                        >
                            Upgrade
                        </button>
                    </div>
                )}
            </div>

            <div className="tw-mt-3 tw-font-openSans">
                <div className="tw-grid tw-grid-cols-1 tw-gap-y-6 md:tw-grid-cols-2 md:tw-gap-x-8">
                    <div>
                        <label
                            htmlFor="first-name"
                            className="tw-block tw-text-xssm tw-font-openSans tw-font-semibold tw-capitalize tw-text-gray-700"
                        >
                            First Name
                        </label>
                        <div className="tw-mt-1">
                            <input
                                type="text"
                                name="first-name"
                                placeholder={user?.firstname || 'First name'}
                                defaultValue={userFirstName}
                                onChange={(e) =>
                                    debouncedUpdateUserField(
                                        'firstname',
                                        e.target.value
                                    )
                                }
                                className="tw-py-3 tw-px-4 tw-block tw-w-full tw-font-openSans tw-text-sm tw-font-semibold tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-border-gray-300 tw-border-solid tw-border tw-rounded-md"
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="last-name"
                            className="tw-block tw-text-xssm tw-font-openSans tw-font-semibold tw-capitalize tw-text-gray-700"
                        >
                            Last name
                        </label>
                        <div className="tw-mt-1">
                            <input
                                type="text"
                                name="last-name"
                                placeholder={user?.lastname || 'Last name'}
                                defaultValue={userLastName}
                                onChange={(e) =>
                                    debouncedUpdateUserField(
                                        'lastname',
                                        e.target.value
                                    )
                                }
                                className="tw-py-3 tw-px-4 tw-block tw-w-full tw-font-openSans tw-text-sm tw-font-semibold tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-border-gray-300 tw-border-solid tw-border tw-rounded-md"
                            />
                        </div>
                    </div>
                    <div className="md:tw-col-span-2">
                        <label
                            htmlFor="email"
                            className="tw-block tw-text-xssm tw-font-openSans tw-font-semibold tw-capitalize tw-text-gray-700"
                        >
                            {isEmailChangeLocked ? 'Email' : 'Current Email'}
                        </label>
                        <div className="tw-relative tw-mt-1">
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your new Email"
                                value={user?.email}
                                disabled={true}
                                className="tw-py-3 tw-px-4 tw-block tw-w-full tw-font-openSans tw-text-sm tw-font-semibold tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-border-gray-300 tw-border-solid tw-border tw-rounded-md"
                            />
                            {user?.isVerified && isEmailChangeLocked && (
                                <div
                                    className="tw-absolute tw-top-3 tw-right-3 tw-cursor-pointer"
                                    onClick={() => requestOtpHandler()}
                                >
                                    <EditIcon />
                                </div>
                            )}
                        </div>
                    </div>
                    {isEmailChangeLocked ? null : (
                        <>
                            <div className="md:tw-col-span-2">
                                <label
                                    htmlFor="new-email"
                                    className="tw-block tw-text-xssm tw-font-openSans tw-font-semibold tw-capitalize tw-text-gray-700"
                                >
                                    New Email
                                </label>
                                <div className="tw-relative tw-mt-1">
                                    <input
                                        type="email"
                                        name="new-email"
                                        placeholder="Enter your new Email"
                                        defaultValue={newEmail}
                                        disabled={loading}
                                        onChange={(e) =>
                                            debouncedUpdateUserField(
                                                'email',
                                                e.target.value
                                            )
                                        }
                                        className="tw-py-3 tw-px-4 tw-block tw-w-full tw-font-openSans tw-text-sm tw-font-semibold tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-border-gray-300 tw-border-solid tw-border tw-rounded-md"
                                    />
                                </div>
                            </div>
                            {newEmail && newEmailOtpSent
                                ? oldEmailOtp.length !== 6
                                    ? getOtpInput(
                                          oldEmailOtp,
                                          setOldEmailOtp,
                                          <>
                                              Before changing your verified
                                              email please verify your
                                              authenticity by providing OTP sent
                                              on your current email &nbsp;(
                                              <span className="tw-font-semibold">
                                                  {user?.email}
                                              </span>
                                              )
                                          </>
                                      )
                                    : getOtpInput(
                                          newEmailOtp,
                                          setNewEmailOtp,
                                          <>
                                              Please verify your authenticity by
                                              providing OTP sent on your new
                                              email &nbsp;(
                                              <span className="tw-font-semibold">
                                                  {newEmail}
                                              </span>
                                              )
                                          </>
                                      )
                                : null}
                        </>
                    )}
                    {error && (
                        <div className="md:tw-col-span-2 tw-flex tw-flex-col tw-items-center tw-justify-center">
                            <p className="tw-m-0 tw-p-0 tw-mt-1 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-full tw-text-rose-700">
                                {error}
                            </p>
                        </div>
                    )}
                    {success && (
                        <div className="md:tw-col-span-2 tw-flex tw-flex-col tw-items-center tw-justify-center">
                            <p className="tw-m-0 tw-p-0 tw-mt-1 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-full tw-text-[#006048]">
                                {success}
                            </p>
                        </div>
                    )}
                    <div className="md:tw-col-span-2 md:tw-flex md:tw-flex-row-reverse md:tw-content-around md:tw-justify-center">
                        <button
                            type="button"
                            className={`${
                                isEmailChangeLocked
                                    ? loading
                                        ? 'tw-bg-blue-300 hover:tw-bg-blue-300'
                                        : 'tw-bg-blue-600 hover:tw-bg-blue-700'
                                    : newEmailOtpSent
                                    ? loading ||
                                      user?.email === newEmail ||
                                      oldEmailOtp.length !== 6 ||
                                      newEmailOtp.length !== 6
                                        ? 'tw-bg-blue-300 hover:tw-bg-blue-300'
                                        : 'tw-bg-blue-600 hover:tw-bg-blue-700'
                                    : user?.email === newEmail
                                    ? 'tw-bg-blue-300 hover:tw-bg-blue-300'
                                    : 'tw-bg-blue-600 hover:tw-bg-blue-700'
                            } tw-w-full tw-font-openSans tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-transparent tw-shadow-sm tw-px-5 tw-py-2 tw-text-base tw-font-semibold tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-bg-blue-500 md:tw-ml-3 md:tw-w-auto md:tw-text-lg`}
                            onClick={
                                isEmailChangeLocked
                                    ? updateUserProfileHandler
                                    : newEmailOtpSent
                                    ? updateUserProfileHandler
                                    : () => requestOtpHandler(true)
                            }
                            disabled={
                                isEmailChangeLocked
                                    ? loading
                                    : newEmailOtpSent
                                    ? loading ||
                                      user?.email === newEmail ||
                                      oldEmailOtp.length !== 6 ||
                                      newEmailOtp.length !== 6
                                    : user?.email === newEmail
                            }
                        >
                            {isEmailChangeLocked
                                ? 'Save Changes'
                                : newEmailOtpSent
                                ? 'Save Changes'
                                : 'Request OTP'}
                        </button>
                        <button
                            type="button"
                            className="tw-mt-3 tw-w-full tw-font-openSans tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-shadow-sm tw-px-6 tw-py-2 tw-bg-white tw-text-base tw-font-semibold tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 md:tw-mt-0 md:tw-mr-5 md:tw-w-auto md:tw-text-xssm"
                            onClick={() => setShowUserProfileEdit(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

UserProfileEdit.propTypes = {
    user: PropTypes.object.isRequired,
    showUserProfileEdit: PropTypes.bool,
    setShowUserProfileEdit: PropTypes.func,
};

UserProfileEdit.defaultProps = {
    showUserProfileEdit: false,
    setShowUserProfileEdit: () => {},
};
