import * as ls from 'local-storage';

/**
 * gets the current user based from authorisation token
 */
export async function getSponsorCompanies(
    page = 1,
    limit = 10,
    searchQuery = ''
) {
    const auth = ls.get('sponsorTempAuth');
    const authBearer = `Bearer ${auth?.access_token}`;

    const response = await fetch(
        `${
            process.env.REACT_APP_MYXP_API_V2
        }/sponsor/companies?limit=${limit}&page=${page}${
            searchQuery.length ? `&search=${searchQuery}` : ''
        }`,
        {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
}
