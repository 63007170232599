/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { FILE_TYPES } from 'components/app/utils/fileTypes';
import { Radio } from 'antd';

import { ImportUser } from 'apis/rest/sponsorship/ImportUser';
import { ImportSession } from 'apis/rest/agenda/importSession';

import PageSpinner from 'components/app/common/pageSpinner';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import PopUp from 'ComponentsV2/ProjectDashboard/Components/Common/UpgradeMessage/Popup';
import WizardLayout from 'components/app/common/wizardLayout';
import FilePondUpload from 'components/app/common/filePondUpload';

import './userImport.scss';

const starterPlanLimit = 0;

const UserImport = ({
    starterPlan,
    isExhibitor = false,
    cancelAction = () => null,
    path = '',
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const location = useLocation();

    const isAgendaTab = location.pathname.includes('agenda');

    const pathname = isExhibitor
        ? path
        : isAgendaTab
        ? `/company/${companyId}/project/${projectId}/dashboard/content/programme/agenda`
        : `/company/${companyId}/project/${projectId}/dashboard/`;

    const companyDetails = useSelector((state) => state?.projects?.company);

    const applicableLimit = starterPlanLimit;
    const isUserOnFreePlan = starterPlan === companyDetails?.package?.id;

    const [uploadedFile, setUploadedFile] = useState(null);
    const [nextEnabledCondition, setNextEnabledCondition] = useState(false);
    const [isUploadingFile, setUploadingStats] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [sendEmailInvite, setSendEmailInvite] = useState(true);

    const handleUploadFile = async () => {
        try {
            setUploadingStats(true);
            const inputs = {
                projectId,
                sendEmailInvite,
                file: uploadedFile,
            };
            const response = await ImportUser(inputs);

            if (response.status) {
                openNotification('success', { message: 'Users Imported!' });
                history.push(
                    `/company/${companyId}/project/${projectId}/dashboard/exhibitors/users`
                );
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            openNotification('error', {
                message: error?.message || 'Something went wrong',
            });
        } finally {
            setUploadingStats(false);
        }
    };

    const handleImportAgenda = async () => {
        try {
            setUploadingStats(true);
            const res = await ImportSession(uploadedFile, projectId);

            if (res.status) {
                openNotification('success', {
                    message: res?.message || 'Sessions Imported!',
                });
                history.push(pathname);
                if (isExhibitor) cancelAction();
            } else {
                openNotification('error', {
                    message: res.message || 'Failed to import sessions',
                });
            }
        } catch (error) {
            openNotification('error', {
                message: error?.message || 'Something went wrong',
            });
        } finally {
            setUploadingStats(false);
        }
    };

    const handleInputChange = (e) => {
        setSendEmailInvite(e.target.value);
    };

    useEffect(() => {
        if (!isNull(uploadedFile)) {
            setTimeout(() => {
                setNextEnabledCondition(true);
            }, 2000);
        } else {
            setNextEnabledCondition(false);
        }
    }, [uploadedFile]);

    useEffect(() => {
        if (isUserOnFreePlan && !isExhibitor) {
            setShowUpgradeModal(true);
        }
    }, [isExhibitor, isUserOnFreePlan]);

    return (
        <div className="container2" style={{ position: 'static' }}>
            <WizardLayout
                name={
                    isAgendaTab
                        ? 'Upload sessions data through csv file'
                        : 'Import User(s)'
                }
                cancelAction={() =>
                    isExhibitor ? cancelAction() : history.goBack()
                }
                nextAction={() =>
                    isAgendaTab ? handleImportAgenda() : handleUploadFile()
                }
                nextEnabledCondition={nextEnabledCondition && !isUploadingFile}
                cancelEnabledCondition={isUploadingFile}
            >
                {isUploadingFile && (
                    <PageSpinner
                        className="z1"
                        type="Oval"
                        color="#ACBDC9"
                        height={40}
                        width={40}
                    />
                )}
                <div className="upload-people-file tw-mx-auto tw-text-center">
                    <div className="upload-people-file tw-mx-auto __wrap">
                        <h1>Upload Your File</h1>
                        <p className="upload-people-file __intro">
                            You can import CSV files.
                            <br />
                            <a
                                href={`/assets/apps/csv-demo-files/${
                                    isAgendaTab
                                        ? 'import-agenda-session-example'
                                        : 'import-sponsorship-user-example'
                                }.csv`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/assets/icon_download_black.svg"
                                    alt=""
                                />{' '}
                                Download a sample file{' '}
                            </a>
                            <br />
                            <br />
                            {isExhibitor && isAgendaTab && '(Info : A session page slug is mandatory to add a session.)'}
                        </p>
                        <FilePondUpload
                            currentFile={uploadedFile || null}
                            uploadFile={(file) => setUploadedFile(file)}
                            fileType={FILE_TYPES.CSV}
                            localServer
                        />
                    </div>
                </div>

                {uploadedFile && !isAgendaTab && (
                    <div className="send-email-invite tw-max-w-xl tw-w-full tw-flex tw-flex-col tw-m-auto">
                        <p className="tw-text-center">
                            Do you want to send the users email invitation?{' '}
                        </p>
                        <Radio.Group
                            className="tw-flex tw-flex-col tw-gap-2 tw-border tw-p-4"
                            onChange={handleInputChange}
                            name="sendEmailInvite"
                            defaultValue={sendEmailInvite}
                        >
                            <Radio
                                className="tw-flex tw-shadow-md tw-rounded-md tw-m-0 tw-p-4 tw-border-solid tw-border-[#dadeeb]"
                                value
                            >
                                Yes
                            </Radio>
                            <Radio
                                className="tw-flex tw-shadow-md tw-rounded-md tw-m-0 tw-p-4 tw-border-solid tw-border-[#dadeeb]"
                                value={false}
                            >
                                No
                            </Radio>
                        </Radio.Group>
                    </div>
                )}
            </WizardLayout>
            <PopUp
                modalVisibilty={showUpgradeModal}
                setModalVisibility={setShowUpgradeModal}
                callback={() =>
                    history.replace(`${pathname}exhibitors/users/import`)
                }
            >
                <p>
                    {applicableLimit
                        ? `Upgrade your plan in order to be able to have more than ${applicableLimit}
                    sponsor users under this project or upload less
                    sponsor users.`
                        : `Upgrade your plan in order to be able to have 
                    sponsor users under this project.`}
                </p>
            </PopUp>
        </div>
    );
};

UserImport.propTypes = {};

UserImport.defaultProps = {};

export default UserImport;
