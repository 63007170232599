import * as ls from 'local-storage';

/**
 * gets the current user based from authorisation token
 */
export async function getSponsor(auth) {
    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/users/profile`,
        {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
}

export async function refreshSponsorTempToken(token) {
    const sponsorAuth = ls.get('sponsorAuth') || null;
    if (!sponsorAuth) throw new Error('invalid auth');

    const authBearer = `Bearer ${sponsorAuth?.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/refresh`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        }
    );

    return await response.json();
}
